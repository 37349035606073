import React, { ReactNode } from "react";
import { Icon, IconType } from "@foris/avocado-ui";
import styles from "./bookingModalDetails.module.scss";

interface BookingModalDetailsRowProps {
  icon?: IconType;
  detailValues: {
    title: string;
    value: ReactNode;
  }[];
}

const BookingModalDetailsRow = ({ icon, detailValues = [] }: BookingModalDetailsRowProps) => {
  return (
    <div className={styles.itemRow}>
      {icon && <Icon icon={icon} size={16} className={styles.itemRow_icon} />}

      <div className={styles.itemRow_details}>
        {detailValues?.map((detail, index) => (
          <div key={index} className={styles.detailsWrapper}>
            <span className={styles.detailsWrapper_title}>{detail?.title ?? "-"}</span>

            <p className={styles.detailsWrapper_value}>{detail?.value ?? "-"}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookingModalDetailsRow;
