import gql from "graphql-tag";

export const packageLinkAssignment = gql`
  mutation($originId: ID!, $scenarioId: ID!, $input: PackageLinkAssignmentInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      packageLinkAssignment(input: $input) {
        skippedValidations
        commited
        userCanSkipValidations
        payload {
          creates {
            package {
              id
              code
              vacancies
              index
              isReplicated
              sourcePopulationCode
              population {
                id
                code
                level
                curriculum
                term {
                  id
                  name
                }
                campus {
                  id
                  code
                }
                modality {
                  id
                  code
                }
                shift {
                  id
                  code
                }
                program {
                  id
                  code
                }
                courses {
                  id
                  curriculum {
                    id
                    code
                    program {
                      id
                      code
                      campus {
                        id
                        code
                      }
                      modality {
                        id
                        code
                      }
                      shift {
                        id
                        code
                      }
                    }
                  }
                }
              }
              __typename
            }
            link {
              id
              code
              vacancies
              groups {
                id
                type
              }
              packages {
                id
                code
                index
                vacancies
                complete
                hasPackageRules
                type
                population {
                  id
                  code
                  size
                  level
                  packageCount
                  campus {
                    id
                    code
                    name
                  }
                  modality {
                    id
                    code
                    name
                  }
                  shift {
                    id
                    code
                    name
                  }
                  program {
                    id
                    code
                    name
                    label
                  }
                  curriculum
                }
              }
              bundle {
                id
                code
                label
                name
              }
              course {
                id
                code
                label
                name
                level
                courseComponents {
                  id
                  component {
                    id
                    code
                    name
                  }
                }
                curriculum {
                  id
                  code
                  name
                  program {
                    id
                    code
                    name
                    modality {
                      id
                      code
                    }
                    campus {
                      id
                      code
                    }
                    department {
                      id
                      code
                    }
                    shift {
                      id
                      code
                    }
                  }
                }
              }
              totalBlocks
            }
            validationErrors {
              ... on InvalidLinksCourse {
                __typename
                reason
              }
              ... on InvalidLinksPackage {
                __typename
                reason
              }
              ... on ClashesBetweenPackages {
                __typename
                allowed
                reason
              }
            }
          }
          deletes {
            package {
              id
              code
              vacancies
              index
              population {
                id
                code
                level
                curriculum
                campus {
                  id
                  code
                }
                modality {
                  id
                  code
                }
                shift {
                  id
                  code
                }
                program {
                  id
                  code
                }
                courses {
                  id
                  curriculum {
                    id
                    code
                    program {
                      id
                      code
                      campus {
                        id
                        code
                      }
                      modality {
                        id
                        code
                      }
                      shift {
                        id
                        code
                      }
                    }
                  }
                }
              }
              __typename
            }
            link {
              id
              code
              vacancies
              groups {
                id
                type
              }
              packages {
                id
                code
                index
                vacancies
                complete
                hasPackageRules
                type
                population {
                  id
                  code
                  size
                  level
                  packageCount
                  campus {
                    id
                    code
                    name
                  }
                  modality {
                    id
                    code
                    name
                  }
                  shift {
                    id
                    code
                    name
                  }
                  curriculum
                }
              }
              bundle {
                id
                code
                label
                name
              }
              course {
                id
                code
                label
                name
                level
                courseComponents {
                  id
                  component {
                    id
                    code
                    name
                  }
                }
                curriculum {
                  id
                  code
                  name
                  program {
                    id
                    code
                    name
                    modality {
                      id
                      code
                    }
                    campus {
                      id
                      code
                    }
                    department {
                      id
                      code
                    }
                    shift {
                      id
                      code
                    }
                  }
                }
              }
              totalBlocks
            }
            validationErrors {
              ... on InvalidLinksCourse {
                __typename
                reason
              }
              ... on InvalidLinksPackage {
                __typename
                reason
              }
              ... on ClashesBetweenPackages {
                __typename
                allowed
                reason
              }
            }
          }
        }
      }
    }
  }
`;
