import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-apollo";
import cx from "classnames";
import { Input, Button, Checkbox, Loading } from "@foris/avocado-ui";
import { Course, CreateGroupInput } from "../../../models/ISchema";
import { IParams } from "../../../models/IParams";
import { IObjValidationError } from "../utils";
import { CourseMultiKey } from "../../shared/selectors";
import TermSelect from "./TermSelect";
import { courseMultiKeyQuery } from "./../graphql/courseMultiKey.queries";
import { IErrorWarning } from "../../../models/Errors/ErrorWarning";
import { Header } from "../../../common/components";
import { useGetSharedPackComponents } from "../hooks/useGetSharedPackComponents";
import GroupComponentInputs from "./GroupComponentInputs";
import {
  AdaptedSharedPackComponent,
  AdaptedSharedPackComponentPayload,
} from "./GroupComponentInputs/GroupComponentInputs";
import GroupCodeInput from "./group-code-input";
import useAddGroup from "../hooks/useAddGroup";
import css from "./groups.module.scss";

export interface IAddGroupsProps {
  onClose?: () => void;
  linkId: string;
  bundleId: string | number;
  headerData?: { subTitle: string; title: string };
}

interface IAddGroupReturn {
  idCampus: string;
  idDepartment: string;
  idModality: string;
  idShift: string;
  objLevel: any;
  idProgram: string;
  idCourse: string;
  idTerm: string;
  idCurriculum: string;
}

const emptyObjGroup = {
  idCampus: null,
  idDepartment: null,
  idModality: null,
  idShift: null,
  objLevel: null,
  idProgram: null,
  idCourse: null,
  idTerm: null,
  idCurriculum: null,
};

const AddGroups: React.FC<IAddGroupsProps> = (props: IAddGroupsProps) => {
  const { onClose, linkId, headerData, bundleId } = props;
  const { origin, scenario }: IParams = useParams();
  const [objGroup, setObjGroup] = useState<IAddGroupReturn>(emptyObjGroup);
  const [idTerm, setIdTerm] = useState(null);
  const [valueCode, setValueCode] = useState(null);
  const [valueCapacity, setValueCapacity] = useState(null);
  const [internalValidationArray, setInternalValidationArray] = useState(null);
  const [intervalValidationStandardArray, setInternalValidationStandardArray] = useState<
    IErrorWarning[]
  >([]);
  const [check, setCheck] = useState(false);
  const [componentsPayload, setComponentsPayload] = useState<AdaptedSharedPackComponent[]>([]);
  const [isComponentsFormValid, setIsComponentsFormValid] = useState(false);

  const {
    addGroup,
    isLoading: loading,
    validationArray: groupValidationArray,
    validationStandardArray: groupValidationStandardArray,
  } = useAddGroup({
    scenario,
    origin,
    onSuccess: () => {
      onClose?.();
      window.location.reload();
    },
  });

  const validationArray = useMemo(() => {
    return [...(internalValidationArray ?? []), ...(groupValidationArray ?? [])];
  }, [internalValidationArray, groupValidationArray]);

  const validationStandardArray = useMemo(() => {
    return [...(intervalValidationStandardArray ?? []), ...(groupValidationStandardArray ?? [])];
  }, [intervalValidationStandardArray, groupValidationStandardArray]);

  const { sharedPackComponents, isLoading, getSharedPacks } = useGetSharedPackComponents({
    scenarioId: scenario,
    originId: origin,
  });

  const { data } = useQuery<any, any>(courseMultiKeyQuery, {
    variables: {
      scenarioId: scenario,
      originId: origin,
      id: linkId,
    },
  });
  const defaultCourse: Course = data?.cube?.link?.course || null;
  const defaultTerm: any = data?.cube?.link.bundle.term || null;

  const errorArray = validationArray
    ? validationArray.filter((error: IObjValidationError) => error.type?.toUpperCase() === "ERROR")
    : [];
  const errorStandardArray = validationStandardArray.filter(
    error => error && error.type === "ERROR",
  );
  const warningArray = validationArray
    ? validationArray.filter((validation: IObjValidationError) => validation.type === "warning")
    : [];
  const warningStandardArray = validationStandardArray.filter(
    error => error && error.type === "WARNING",
  );

  const handleAddGroup = (commit: boolean) => {
    const sharedPackComponent = componentsPayload?.length
      ? componentsPayload?.map(({ component, label }) => ({
          label,
          component,
        }))
      : undefined;

    const objMutation: CreateGroupInput = {
      linkId: linkId,
      programId: objGroup.idProgram,
      courseId: objGroup.idCourse,
      termId: idTerm,
      code: valueCode,
      sharedPackComponent,
      capacity: parseInt(valueCapacity),
    };

    addGroup(objMutation, commit);
  };

  const handleCourseMultiKeyCallback = (newObjGroup: IAddGroupReturn) => {
    setObjGroup(newObjGroup || emptyObjGroup);
  };

  useEffect(() => {
    if (objGroup?.idCourse) {
      getSharedPacks(linkId, bundleId as string, objGroup?.idCourse);
    }
  }, [objGroup]);

  const hasCodeOrSharedPacks =
    (!sharedPackComponents?.length && valueCode) ||
    (!!sharedPackComponents?.length && isComponentsFormValid);

  const isSubmitActive =
    objGroup.idCampus &&
    objGroup.idDepartment &&
    objGroup.idModality &&
    objGroup.idShift &&
    objGroup.idProgram &&
    objGroup.idCurriculum &&
    objGroup.idCourse &&
    idTerm &&
    valueCapacity &&
    hasCodeOrSharedPacks &&
    errorArray.length === 0;

  /**
   * set term filter
   * termId {
   *    eq
   * }
   */
  const setTerm = (values: any) => {
    if (values.term) {
      setIdTerm(values.term);
    }
  };

  const handleChangeComponents = (payload: AdaptedSharedPackComponentPayload) => {
    setValueCode(payload?.groupCode);
    setComponentsPayload(payload?.sharedPackComponent);
  };

  const isWarningError = validationArray?.length > 0 || validationStandardArray?.length > 0;
  const isWarning = warningArray?.length > 0 || warningStandardArray?.length > 0;

  return (
    <article className={cx(css.addGroups)}>
      {(loading || isLoading) && <Loading />}
      <Header topTitle={headerData.subTitle} title={`Crear grupo | ${headerData.title} `} />
      <section className={cx(css.contentAddGroup, "container-row")}>
        <div className={cx(css.contentAddGroup_item, "container-row")}>
          <div className="col_4">
            <CourseMultiKey
              label
              className={css.contentAddGroup_item_selectors}
              defaultCourse={defaultCourse}
              onCallback={handleCourseMultiKeyCallback}
            />
          </div>
        </div>
        <div className={cx(css.contentAddGroup_item, "container-row")}>
          <div className="col_4">
            <TermSelect
              {...props}
              defaultTerm={defaultTerm}
              sendValues={(values: any) => setTerm(values)}
            />
          </div>
        </div>
        <div className={cx(css.contentAddGroup_item, "container-row")}>
          <label className={cx(css.itemLabel, "col_12")}>Vacantes</label>
          <Input
            className="col_2"
            placeholder="Número vacantes"
            type="number"
            value={valueCapacity}
            onChange={event => {
              setValueCapacity(parseInt(event.target.value));
              setInternalValidationArray([]);
              setInternalValidationStandardArray([]);
            }}
          />
        </div>

        {!sharedPackComponents?.length ? (
          <div className={cx(css.contentAddGroup_item, "container-row")}>
            <GroupCodeInput
              value={valueCode}
              onChange={setValueCode}
              onError={setInternalValidationStandardArray}
            />
          </div>
        ) : (
          <GroupComponentInputs
            sharedPackComponents={sharedPackComponents}
            onChangeComponents={handleChangeComponents}
            onFormValid={setIsComponentsFormValid}
          />
        )}

        {isWarningError && (
          <>
            <ul className={cx(css.list_validation, "container-row")}>
              {warningArray.map((validation: any, iValidation: number) => (
                <li key={iValidation} className={cx(css.list_validation_item, "col_12")}>
                  {validation.text}
                </li>
              ))}
              {warningStandardArray.map((validation, index) => (
                <li key={index} className={cx(css.list_validation_item, "col_12")}>
                  {validation.message}
                </li>
              ))}
              {errorArray.map((validation: any, iValidation: number) => (
                <li
                  key={iValidation}
                  className={cx(
                    css.list_validation_item,
                    css.list_validation_item__error,
                    "col_12",
                  )}
                >
                  {validation.text}
                </li>
              ))}
              {errorStandardArray.map((validation, index) => (
                <li
                  key={index}
                  className={cx(
                    css.list_validation_item,
                    css.list_validation_item__error,
                    "col_12",
                  )}
                >
                  {validation.message}
                </li>
              ))}
            </ul>
            {isWarning && errorArray?.length === 0 && (
              <Checkbox
                labelRight="Ignorar advertencias"
                checked={check}
                onChange={event => setCheck(event.currentTarget.checked)}
              />
            )}
          </>
        )}
      </section>

      <footer className={cx(css.footer, "container-row")}>
        <Button typeButton="transparent" className={cx(css.footer_btn)} onClick={() => onClose()}>
          Cancelar
        </Button>
        {warningArray.length === 0 && (
          <Button
            className={cx(css.footer_btn)}
            onClick={() => handleAddGroup(false)}
            disabled={!isSubmitActive}
          >
            Agregar
          </Button>
        )}
        {warningArray.length > 0 && (
          <Button
            className={cx(css.footer_btn)}
            onClick={() => handleAddGroup(true)}
            disabled={!isSubmitActive || (warningArray.length > 0 && !check)}
          >
            Confirmar
          </Button>
        )}
      </footer>
    </article>
  );
};

export default AddGroups;
