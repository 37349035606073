import gql from "graphql-tag";

export const classroomsAvailabilities = gql`
  query classroomsAvailabilities($originId: ID!, $scenarioId: ID!, $filter: ClassroomFilterInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      classroomsAvailabilities(filter: $filter) {
        items {
          id
          code
          name
          floor
          capacity
          classroomType {
            id
            name
          }
          assignment {
            usagePercentage
            chairsOccupationPercentage
          }
          building {
            id
            name
            campus {
              id
              name
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;
