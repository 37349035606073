import React, { useContext } from "react";
import { Button, Icon } from "@foris/avocado-ui";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import { IParams } from "@models/IParams";
import { enums } from "@modules/sections/utils";
import { IEvent } from "@modules/sections/ISections";
import { useHistory, useParams } from "react-router-dom";
import icons from "@utils/icons";
import styles from "./bookingModalDetails.module.scss";
import BookingModalDetailsRow from "./BookingModalDetailsRow";

interface BookingModalDetailsProps {
  event: IEvent;
}

const BookingModalDetails = ({ event }: BookingModalDetailsProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const history = useHistory();
  const context: IContextApp = useContext(ContextApp);
  const contextUrl = `${workspace}/${scenario}/${origin}`;
  const accessToBookingAdmin = context.user?.permissions?.create;
  const accessToBookingUser =
    context?.modules?.find(item => item.code === "bookings").accesible || false;

  let classroom = null;
  let building = null;
  let day = "";
  let startDate = "";
  let endDate = "";
  let description = "";
  let responsible = "";
  let status = "";
  let bookingId = null;

  if (event && event.resource) {
    const resource = event.resource;
    if (resource.blockRange) {
      if (resource.blockRange.start) {
        day =
          (resource.blockRange.start.day && enums.DAY_NAMES_ES[resource.blockRange.start.day]) ||
          "";
        startDate = resource.blockRange.start.startingTime.replace(":00", "") || "";
      }
      endDate = resource.blockRange.end
        ? resource.blockRange.end.endingTime.replace(":00", "")
        : "";
    }
  }

  if (event && event.bookings) {
    const bookingsInfo = event.bookings;
    classroom = bookingsInfo.classroom ? bookingsInfo.classroom : { classroomType: {} };
    building = classroom.name ? (classroom.building ? classroom.building.name : "") : "-";
    description = bookingsInfo.description;
    bookingId = bookingsInfo.id;
    responsible = `${bookingsInfo.responsible.name} ${bookingsInfo.responsible.lastName}`;
    status = bookingsInfo.status;
  }

  return (
    <div className={styles.bookingModalDetails}>
      <section className={styles.bookingModalDetails_content}>
        <BookingModalDetailsRow
          icon="clock"
          detailValues={[
            { title: "Horario", value: `${day ? `${day},` : ""} ${startDate} - ${endDate}` },
          ]}
        />

        <BookingModalDetailsRow
          icon="building"
          detailValues={[
            {
              title: "Sala",
              value:
                classroom && classroom.name && classroom.classroomType
                  ? `${classroom.name} - ${classroom.classroomType.name}`
                  : "Sin sala asignada",
            },
            { title: "Edificio", value: building },
          ]}
        />

        <BookingModalDetailsRow
          detailValues={[
            {
              title: "Estado",
              value: (
                <>
                  <Icon
                    icon={icons.statusIcon(status)}
                    size={12}
                    className={styles.iconStatusBooking}
                  />

                  {icons.statusIconText(status)}
                </>
              ),
            },
          ]}
        />

        <BookingModalDetailsRow
          icon="user"
          detailValues={[{ title: "Responsable", value: responsible }]}
        />

        <BookingModalDetailsRow detailValues={[{ title: "Descripción", value: description }]} />
      </section>

      {accessToBookingAdmin && accessToBookingUser && (
        <footer className={styles.bookingModalDetails_footer}>
          <Button
            className={styles.footerAction}
            onClick={() => history.push(`/booking/detail/${contextUrl}/${bookingId}`)}
          >
            Gestionar
          </Button>
        </footer>
      )}
    </div>
  );
};

export default BookingModalDetails;
