import React, { useEffect } from "react";
import { Option } from "react-select/lib/filters";
import AdvanceSelector from "../advance-selector";
import useGetBuildingOptions from "@modules/shared/selectors/hooks/useGetBuildingOptions";

interface AdvancedBuildingSelectorProps {
  scenario: string;
  campusId?: string;
  onChange?: (option: Option) => void;
}

const AdvancedBuildingSelector = ({
  scenario,
  campusId,
  onChange,
}: AdvancedBuildingSelectorProps) => {
  const { getCacheBuildingOptions, optionsBuilding } = useGetBuildingOptions({
    scenario,
    campusId,
  });

  useEffect(() => {
    getCacheBuildingOptions();
  }, []);

  return (
    <AdvanceSelector
      dimension="Edificio"
      selectPlaceholder="Selecciona un edificio"
      options={optionsBuilding}
      onChange={onChange}
    />
  );
};

export default AdvancedBuildingSelector;
