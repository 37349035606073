import gql from "graphql-tag";

export const shiftQuery = gql`
  query shift($scenarioId: ID!, $filter: ShiftFilterInput) {
    data(scenarioId: $scenarioId) {
      shifts(filter: $filter) {
        items {
          id
          code
          name
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;
