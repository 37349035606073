import { ActionMap } from "@models/IContext";
import { FiltersSelectorsType } from "./types";
import {
  setFilterToDisplay,
  setSelectedPackage,
  setUsedFilterToSearch,
} from "./filtersSelectors.actions";
import { FiltersType, SelectableOption } from "../models";
import { Package } from "@models/ISchema";

export enum Types {
  SetFilterToDisplay = "SET_FILTER_TO_DISPLAY",
  SetSelectedPackage = "SET_SELECTED_PACKAGE",
  SetUsedFilterToSearch = "SET_USED_FILTER_TO_SEARCH",
}

type FiltersSelectorsPayload = {
  [Types.SetFilterToDisplay]: FiltersType;
  [Types.SetSelectedPackage]: SelectableOption<Package>;
  [Types.SetUsedFilterToSearch]: FiltersType;
};

export type FiltersSelectorsActions = ActionMap<FiltersSelectorsPayload>[keyof ActionMap<
  FiltersSelectorsPayload
>];

export const filtersSelectorsReducer = (
  state: FiltersSelectorsType,
  action: FiltersSelectorsActions,
) => {
  switch (action?.type) {
    case Types.SetFilterToDisplay:
      return setFilterToDisplay(action?.payload, state);
    case Types.SetSelectedPackage:
      return setSelectedPackage(action?.payload, state);
    case Types.SetUsedFilterToSearch:
      return setUsedFilterToSearch(action?.payload, state);
    default:
      return state;
  }
};
