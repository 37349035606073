import gql from "graphql-tag";

export const instructorSearch = gql`
  query megaSearch($scenarioId: ID!, $filter: InstructorFilterInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      instructorsAdvancedSearch(filter: $filter) {
        items {
          id
          name
          code
          __typename
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
