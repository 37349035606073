import React, { useState, useContext, useEffect, useMemo } from "react";
import {
  Lens,
  and,
  chain,
  curry,
  equals,
  filter,
  is,
  isEmpty,
  isNil,
  join,
  keys,
  length,
  lensPath,
  not,
  or,
  pick,
  pipe,
  values,
  when,
} from "ramda";
import { Button, Icon, Checkbox, Tag, Switch, RadioButton } from "@foris/avocado-ui";
import cx from "classnames";
import CampusSelector from "./CampusSelector";
import CrnSelector from "./CrnSelector";
import SchoolSelector from "./SchoolSelector";
import DepartmentSelector from "./DepartmentSelector";
import SubjectSelector from "./SubjectSelector";
import { Context } from "../../context/GroupsManagerContext";
import { FiltersReducerType } from "../../context/types";
import { Types as FiltersSelectorsTypes } from "../../context/filtersSelectors.reducer";
import { Types } from "../../context/filters.reducer";
import stringifyFilters from "../../utils/stringifyFilters";
import css from "./filters.module.scss";
import CourseTypeSelector from "./CourseTypesSelector";
import PackageSelector from "./PackageSelector";
import { Package } from "@models/ISchema";
import { FiltersType, SelectableOption } from "../../models";
import { useLocation } from "react-router-dom";

type RequestGroupsPayload = {
  page: number;
  avoidEditionsCleaning: boolean;
  usedFilter?: FiltersType;
};

interface IFiltersProps {
  requestGroups: (payload: RequestGroupsPayload) => void;
}

interface LocationState {
  filter?: FiltersType;
  data?: Package;
}

const Filters = (props: IFiltersProps) => {
  const { requestGroups } = props;
  const { state, dispatch } = useContext(Context);
  const [displayAdvancedFilters, setDisplayAdvancedFilters] = useState(false);
  const [checkedAdvancedFilters, setCheckedAdvancedFilters] = useState(0);
  const [filtersConfigured, setFiltersConfigured] = useState(false);
  const [currentStringifiedFilters, setCurrentStringifiedFilters] = useState("");
  const [requestedStringifiedFilters, setRequestedStringifiedFilters] = useState(null);
  const [isDeparmentsLoaded, setIsDepartmentsLoaded] = useState(false);
  const [isSchoolsLoaded, setIsSchoolsLoaded] = useState(false);
  const [shouldLoadFilters, setShouldLoadFilters] = useState(false);

  const location = useLocation();
  const locationState: LocationState = location.state;

  const filtersToDisplay = state?.filtersSelectors?.visibleFilter;
  const setFiltersToDisplay = (value: FiltersType) => {
    dispatch({ type: FiltersSelectorsTypes.SetFilterToDisplay, payload: value });
  };

  /**
   * These keys are used to trigger requests after any selector dependency
   * change. For example, when a `campus` is selected the selectable
   * `schools` have to be re-calculated, ensuring that the selectable `schools`
   * are actually related to the selected `campus`.
   */
  const campusSelectorKey = useMemo(() => state?.filters?.subject?.id ?? "", [
    state?.filters?.subject,
  ]);
  const schoolSelectorKey = useMemo(
    () => join("", [state?.filters?.subject?.id ?? "", state?.filters?.campus?.id ?? ""]),
    [state?.filters?.subject, state?.filters?.campus],
  );
  const departmentSelectorKey = useMemo(
    () =>
      join("", [
        state?.filters?.subject?.id ?? "",
        state?.filters?.campus?.id ?? "",
        state?.filters?.school?.id ?? "",
      ]),
    [state?.filters?.subject, state?.filters?.campus, state?.filters?.school],
  );

  useEffect(() => {
    setIsDepartmentsLoaded(false);
    setIsSchoolsLoaded(false);
  }, [state?.filters?.subject, state?.filters?.campus]);

  const updateFilter = curry(
    (
      lens: Lens<FiltersReducerType, boolean>,
      value: React.ChangeEvent<HTMLInputElement> | boolean,
    ) => {
      dispatch({
        type: Types.SetAdvancedFilter,
        payload: {
          lens,
          value: Boolean((value as React.ChangeEvent<HTMLInputElement>)?.target?.checked),
        },
      });
    },
  );

  const advancedFiltersTitle = () => {
    return !displayAdvancedFilters ? `Mostrar filtros adicionales` : `Ocultar filtros adicionales`;
  };

  const advancedFiltersTitleNumber = () => {
    return checkedAdvancedFilters > 0 ? `(${checkedAdvancedFilters})` : "";
  };

  /**
   * After the any filter change, recalculate the number of selected filters (excluding `owned`).
   */
  useEffect(() => {
    const filtersValues: (filters: any) => (boolean | null)[] = pipe(
      values,
      chain<(null | boolean)[], any>(when(is(Object), v => filtersValues(v))),
    );

    const advancedFilters = pick(
      ["availability", "enrollments", "state", "visibility"],
      state?.filters ?? {},
    );

    const countAdvancedFilters = pipe(filtersValues, filter(equals(true)), length);
    const numberOfSelectedCourseTypes = keys(state?.filters?.courseTypesById)?.length ?? 0;

    setCheckedAdvancedFilters(countAdvancedFilters(advancedFilters) + numberOfSelectedCourseTypes);
    setCurrentStringifiedFilters(stringifyFilters(state?.filters));
  }, [state?.filters]);

  /**
   * Set the `filtersSetted` variable, which determines if the button
   * `Mostrar resultados` should be `enabled` or `disabled`.
   */
  useEffect(() => {
    const { campus, school, department, subject } = state?.filters;
    const some = pipe(isNil, not);

    const subjectSelected = some(subject);
    const campusSelected = some(campus);
    const othersSelected = some(school) || some(department);
    const filtersByKeyConfigured = and(
      filtersToDisplay === FiltersType.KEY,
      or(subjectSelected, campusSelected && othersSelected),
    );
    const filtersByCrnConfigured = and(
      filtersToDisplay === FiltersType.CRN,
      Boolean(state?.filters?.selectedCrn),
    );
    const filtersByPackageConfigured =
      filtersToDisplay === FiltersType.PACKAGE && !!state?.filters?.selectedPackage;

    setFiltersConfigured(
      filtersByCrnConfigured || filtersByKeyConfigured || filtersByPackageConfigured,
    );
  }, [state?.filters]);

  const isDisabledResultsButton = () => {
    if (!filtersConfigured) {
      return true;
    }

    const isKeyType = filtersToDisplay === FiltersType.KEY;

    if (isKeyType && currentStringifiedFilters === requestedStringifiedFilters) {
      return true;
    }

    if (isKeyType && (!isDeparmentsLoaded || !isSchoolsLoaded)) {
      return true;
    }

    return false;
  };

  const handleSelectPackageFilter = (item: Package) => {
    const selectableOption: SelectableOption<Package> = {
      id: item?.id,
      value: item?.id,
      label: item?.code,
      self: item,
    };

    dispatch({ type: FiltersSelectorsTypes.SetSelectedPackage, payload: selectableOption || null });
    dispatch({ type: Types.SelectPackage, payload: item?.id || null });
  };

  const handleSearchRequest = () => {
    requestGroups({
      page: 1,
      avoidEditionsCleaning: false,
      usedFilter: filtersToDisplay,
    });
    setRequestedStringifiedFilters(stringifyFilters(state?.filters));
  };

  const handleCleanFilters = () => {
    dispatch({ type: FiltersSelectorsTypes.SetSelectedPackage, payload: null });
    dispatch({ type: Types.CleanFilters });
  };

  useEffect(() => {
    if (state?.filters?.selectedPackage && state?.filtersSelectors?.selectedPackage?.id) {
      handleSearchRequest();
    }

    if (locationState?.filter && locationState?.data && !shouldLoadFilters) {
      dispatch({ type: Types.CleanFilters });
      dispatch({ type: Types.SelectPackage, payload: locationState?.data?.id });
      dispatch({
        type: FiltersSelectorsTypes.SetSelectedPackage,
        payload: {
          id: locationState?.data?.id,
          value: locationState?.data?.id,
          label: locationState?.data?.code,
          self: locationState?.data,
        },
      });
      dispatch({
        type: FiltersSelectorsTypes.SetFilterToDisplay,
        payload: FiltersType.PACKAGE,
      });

      setShouldLoadFilters(true);
    }
  }, [shouldLoadFilters]);

  return (
    <section className={css.filtersCard}>
      <header className={css.filtersCard_title}>
        <div className={css.filtersCard_title_left}>
          <p className={css.filtersCard_title_text}>Buscar Grupos</p>
          <Switch
            className={css.filtersCard_title_switch}
            labelRight="Mis grupos"
            checked={state?.filters?.owned}
            onChange={updateFilter(lensPath(["owned"]))}
          />
        </div>
        <div className={css.filtersCard_title_right}>
          <p>*con Sede seleccionar como mínimo una opción</p>
        </div>
      </header>
      <div className={css.filtersCard_typeSelector}>
        <p className={css.filtersCard_typeSelector_text}>Buscar por</p>
        <RadioButton
          className={css.filtersCard_typeSelector_switch}
          labelRight="Llave"
          checked={filtersToDisplay === FiltersType.KEY}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFiltersToDisplay(e?.currentTarget?.checked ? FiltersType.KEY : FiltersType.CRN);
            handleCleanFilters();
          }}
        />
        <RadioButton
          className={css.filtersCard_typeSelector_switch}
          labelRight="CRN"
          checked={filtersToDisplay === FiltersType.CRN}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFiltersToDisplay(e?.currentTarget?.checked ? FiltersType.CRN : FiltersType.KEY);
            handleCleanFilters();
          }}
        />
        <RadioButton
          className={css.filtersCard_typeSelector_switch}
          labelRight="Paquete"
          checked={filtersToDisplay === FiltersType.PACKAGE}
          onClick={() => {
            setFiltersToDisplay(FiltersType.PACKAGE);
            handleCleanFilters();
          }}
        />
      </div>
      <ul className={css.filters}>
        {filtersToDisplay === FiltersType.KEY && (
          <>
            <li className={css.filter_pair_container}>
              <ul className={css.filter_pair}>
                <li className={css.filter}>
                  <SubjectSelector />
                </li>
                <li className={css.filter}>
                  <CampusSelector key={campusSelectorKey} />
                </li>
              </ul>
            </li>
            <li className={css.filter_pair_container}>
              <ul className={css.filter_pair}>
                <li className={css.filter}>
                  <SchoolSelector
                    key={schoolSelectorKey}
                    onLoading={isLoading => setIsSchoolsLoaded(!isLoading)}
                  />
                </li>
                <li className={css.filter}>
                  <DepartmentSelector
                    key={departmentSelectorKey}
                    onLoading={isLoading => setIsDepartmentsLoaded(!isLoading)}
                  />
                </li>
                <p className={css.filter_pair__mark}>*</p>
              </ul>
            </li>
          </>
        )}
        {filtersToDisplay === FiltersType.CRN && (
          <li className={css.filter_single}>
            <CrnSelector />
          </li>
        )}
        {filtersToDisplay === FiltersType.PACKAGE && (
          <li className={css.filter_single}>
            <PackageSelector
              initialValue={state?.filtersSelectors?.selectedPackage}
              onSelectPackage={handleSelectPackageFilter}
            />
          </li>
        )}
      </ul>

      {/* Advanced filters */}
      <section className={css.advancedFilters}>
        <div className={css.advancedFilters_separator}>
          <Button
            className={css.advancedFilters_separator_button}
            disabled={false}
            variant={"ghost"}
            onClick={() => setDisplayAdvancedFilters(!displayAdvancedFilters)}
          >
            <Icon icon="filter" size={25} />
            <span className={css.advancedFilters_separator_button_label}>
              {advancedFiltersTitle()}{" "}
              <div className={css.advancedFilters_separator_button_number}>
                {advancedFiltersTitleNumber()}
              </div>
            </span>
          </Button>
          <div className={cx(css.advancedFilters_separator_line)}></div>
        </div>

        <ul className={cx(css.advancedFilters_container, displayAdvancedFilters ? "" : css.hide)}>
          <li className={css.advancedFilter}>
            <h3 className={css.advancedFilter_title}>Estado</h3>
            <Checkbox
              className={css.advancedFilter_checkbox}
              labelRight={"activo"}
              checked={state?.filters?.state?.active}
              onChange={updateFilter(lensPath(["state", "active"]))}
            />
            <Checkbox
              className={css.advancedFilter_checkbox}
              labelRight={"inactivo"}
              checked={state?.filters?.state?.inactive}
              onChange={updateFilter(lensPath(["state", "inactive"]))}
            />
          </li>

          <li className={cx(css.advancedFilter, displayAdvancedFilters ? "" : css.hide)}>
            <h3 className={css.advancedFilter_title}>Visibilidad</h3>
            <Checkbox
              className={css.advancedFilter_checkbox}
              labelRight={"visible"}
              checked={state?.filters?.visibility?.visible}
              onChange={updateFilter(lensPath(["visibility", "visible"]))}
            />
            <Checkbox
              className={css.advancedFilter_checkbox}
              labelRight={"no visible"}
              checked={state?.filters?.visibility?.invisible}
              onChange={updateFilter(lensPath(["visibility", "invisible"]))}
            />
          </li>

          <li className={cx(css.advancedFilter, displayAdvancedFilters ? "" : css.hide)}>
            <h3 className={css.advancedFilter_title}>Disponibilidad</h3>
            <Checkbox
              className={css.advancedFilter_checkbox}
              labelRight={"disponible"}
              checked={state?.filters?.availability?.available}
              onChange={updateFilter(lensPath(["availability", "available"]))}
            />
            <Checkbox
              className={css.advancedFilter_checkbox}
              labelRight={"no disponible"}
              checked={state?.filters?.availability?.unavailable}
              onChange={updateFilter(lensPath(["availability", "unavailable"]))}
            />
          </li>

          <li className={cx(css.advancedFilter, displayAdvancedFilters ? "" : css.hide)}>
            <h3 className={css.advancedFilter_title}>Inscritos</h3>
            <Checkbox
              className={css.advancedFilter_checkbox}
              labelRight={"con inscritos"}
              checked={state?.filters?.enrollments?.with}
              onChange={updateFilter(lensPath(["enrollments", "with"]))}
            />
            <Checkbox
              className={css.advancedFilter_checkbox}
              labelRight={"sin inscritos"}
              checked={state?.filters?.enrollments?.without}
              onChange={updateFilter(lensPath(["enrollments", "without"]))}
            />
          </li>

          <li
            className={cx(
              css.advancedFilter,
              displayAdvancedFilters ? "" : css.hide,
              css.advancedFilter_selector,
            )}
          >
            <h3 className={css.advancedFilter_title}>Tipo de UF</h3>
            <CourseTypeSelector />
          </li>
        </ul>

        <li className={cx(css.advancedFilters_tags_container)}>
          <div className={css.advancedFilters_tags}>
            <Tag
              className={state?.filters?.state?.active ? "" : css.hide}
              onRemove={() => updateFilter(lensPath(["state", "active"]), false)}
            >
              Activo
            </Tag>
            <Tag
              className={state?.filters?.state?.inactive ? "" : css.hide}
              onRemove={() => updateFilter(lensPath(["state", "inactive"]), false)}
            >
              Inactivo
            </Tag>
            <Tag
              className={state?.filters?.visibility?.visible ? "" : css.hide}
              onRemove={() => updateFilter(lensPath(["visibility", "visible"]), false)}
            >
              Visible
            </Tag>
            <Tag
              className={state?.filters?.visibility?.invisible ? "" : css.hide}
              onRemove={() => updateFilter(lensPath(["visibility", "invisible"]), false)}
            >
              No visible
            </Tag>
            <Tag
              className={state?.filters?.availability?.available ? "" : css.hide}
              onRemove={() => updateFilter(lensPath(["availability", "available"]), false)}
            >
              Disponible
            </Tag>
            <Tag
              className={state?.filters?.availability?.unavailable ? "" : css.hide}
              onRemove={() => updateFilter(lensPath(["availability", "unavailable"]), false)}
            >
              No disponible
            </Tag>
            <Tag
              className={state?.filters?.enrollments?.with ? "" : css.hide}
              onRemove={() => updateFilter(lensPath(["enrollments", "with"]), false)}
            >
              Con inscritos
            </Tag>
            <Tag
              className={state?.filters?.enrollments?.without ? "" : css.hide}
              onRemove={() => updateFilter(lensPath(["enrollments", "without"]), false)}
            >
              Sin inscritos
            </Tag>

            {values(state?.filters?.courseTypesById ?? {})?.map(courseType => (
              <Tag
                key={courseType?.id}
                onRemove={() => dispatch({ type: Types.RemoveCourseTypeById, payload: courseType })}
              >
                {courseType?.name}
              </Tag>
            ))}
          </div>
        </li>

        <div
          className={cx(
            css.advancedFilters_bottomLine,
            displayAdvancedFilters || Boolean(checkedAdvancedFilters) ? "" : css.hide,
          )}
        />
      </section>

      {/* Buttons */}
      <section className={css.buttons_section}>
        <Button
          color="primary"
          variant="outline"
          disabled={!filtersConfigured && isEmpty(state?.filters?.courseTypesById)}
          className={cx(css.buttons_section__button)}
          onClick={() => dispatch({ type: Types.CleanFilters })}
        >
          Borrar selección
        </Button>
        <Button
          disabled={isDisabledResultsButton()}
          className={cx(css.buttons_section__button)}
          onClick={handleSearchRequest}
        >
          Mostrar resultados
        </Button>
      </section>
    </section>
  );
};

export default Filters;
