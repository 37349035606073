import React, { useContext, useMemo, useState } from "react";
import { useQuery } from "react-apollo";
import { useParams } from "react-router-dom";
import { Button } from "@foris/avocado-ui";
import cx from "classnames";
import { IParams } from "@models/IParams";
import { InstructorPage, Session } from "@models/ISchema";
import { AppContext } from "../../../context/EditSessionsContext";
import { Types } from "../../../context/formData.reducer";
import { Types as TypeSwitch } from "../../../context/switchPage.reducer";
import AdvanceTable from "../../../components/Instructor/AdvanceTable";
import { instructorsAdvancedSearch } from "../../../graphql/instructorAdvanceSearch.query";
import { daysEn } from "@utils/days";
import css from "./advanceSearch.module.scss";

const AdvanceSearch: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState(state?.form?.editedSessions?.instructors);
  const { scenario, origin }: IParams = useParams();
  const selectedCreate = state?.form?.selectedCreateSession;
  const countSessions = selectedCreate ? 1 : state?.form?.selectedSessions?.length;
  const size = 10;
  const title =
    countSessions > 1
      ? `Buscar docentes - ${countSessions} sesiones`
      : `Buscar docentes - ${countSessions} sesión`;
  let codeSessions = [];
  if (!selectedCreate) {
    codeSessions = state?.form?.selectedSessions.map(value => value.id);
  } else {
    codeSessions = ["Nueva sesión"];
  }

  const intervalsCheck = state?.form?.editedSessions?.intervals?.filter(
    value => value.checked === true,
  );

  const availabilities = state?.form?.selectedSessions
    ?.map(_value => {
      const value = _value as Session;
      const intervals =
        intervalsCheck.length > 0
          ? intervalsCheck.map(value => parseInt(value.id))
          : value?.assignment?.intervals.map(value => parseInt(value.id));
      const dayEdited = state?.form?.editedSessions?.blocks?.day;
      const day = dayEdited ?? value?.assignment?.blockRange?.start?.day;
      if (day) {
        let dayNumber = daysEn.findIndex(value => value === day);
        dayNumber = dayNumber || dayNumber === 0 ? dayNumber + 1 : 0;
        const startEdited = state?.form?.editedSessions?.blocks?.startTime;
        const start = startEdited ?? value?.assignment?.blockRange?.start?.startingTime;
        const endEdited = state?.form?.editedSessions?.blocks?.endTime;
        const end = endEdited ?? value?.assignment?.blockRange?.end?.endingTime;
        return {
          dayId: [dayNumber?.toString()],
          blockRange: {
            start: start,
            end: end,
          },
          intervals,
        };
      }
      return null;
    })
    .filter(value => value !== null);

  const variables = useMemo(() => {
    const shouldFilter = state?.page?.active?.advanceSearchFilterInstructor;
    let fieldsAnd = [];

    if (shouldFilter) {
      fieldsAnd = [
        {
          courseId: {
            eq: parseInt(state?.link?.info?.course?.id),
          },
        },
      ];
    }

    return {
      pagination: {
        page,
        size,
      },
      fields: {
        and: [
          ...fieldsAnd,
          {
            availabilities,
          },
        ],
      },
    };
  }, [state?.page?.active?.advanceSearchFilterInstructor]);

  const variablesQuery = {
    originId: origin,
    scenarioId: scenario,
    filter: variables,
  };

  const { loading, error, data } = useQuery(instructorsAdvancedSearch, {
    variables: variablesQuery,
  });

  if (error) console.error(error);

  const dataQuery: InstructorPage = data?.cube?.instructorsAdvancedSearch;

  return (
    <article className={css.advanceSearch}>
      <header className={css.header}>
        <h1 className={css.header_title}>
          {title} <span className={css.separatorTitle}>|</span>{" "}
          <span className={css.titleSecondary}>
            {state?.page?.active?.advanceSearchFilterInstructor
              ? "Solo docentes configurados para la asignatura"
              : "Todos los docentes"}
          </span>
        </h1>
        <h2 className={css.header_subTitle}>{`(${codeSessions.join(" | ")})`}</h2>
      </header>
      <section className={css.content}>
        <header className={cx(css.resume, "container-row")}>
          <p className={css.resume_count}>
            {dataQuery?.pageInfo?.total ? `${dataQuery?.pageInfo?.total} Docentes` : ""}
            <span className={css.selected}>
              {dataQuery?.pageInfo?.total && selected?.length
                ? ` | ${selected?.length} Docente${selected?.length > 1 ? "s" : ""} seleccionado${
                    selected?.length > 1 ? "s" : ""
                  }`
                : ""}
            </span>
          </p>
        </header>
        <AdvanceTable
          selected={selected}
          setSelected={setSelected}
          loading={loading}
          data={dataQuery?.items}
          size={size}
          total={dataQuery?.pageInfo?.total}
          page={page}
          onPageChange={setPage}
        />
      </section>
      <footer className={cx(css.footer, "container-row")}>
        <Button
          onClick={() => {
            dispatch({ type: TypeSwitch.FormPage, payload: true });
          }}
          typeButton="transparent"
          className={css.footer_btn}
        >
          Cancelar
        </Button>
        <Button
          className={css.footer_btn}
          onClick={() => {
            dispatch({
              type: Types.InstructorEditedSessions,
              payload: {
                instructors: selected,
              },
            });
            dispatch({ type: Types.SavedSessions, payload: [state.form.editedSessions] });
            dispatch({ type: TypeSwitch.FormPage, payload: true });
          }}
        >
          Guardar
        </Button>
      </footer>
    </article>
  );
};

export default AdvanceSearch;
