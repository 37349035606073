import { useCallback, useState } from "react";
import { useApolloClient } from "react-apollo";
import { Building, DataQuery } from "@models/ISchema";
import { buildingQuery } from "../graphql/building.queries";

interface UseGetBuildingOptionsParams {
  scenario: string;
  campusId?: string;
}

const useGetBuildingOptions = ({ scenario, campusId = null }: UseGetBuildingOptionsParams) => {
  const client = useApolloClient();
  const [optionsBuilding, setOptionsBuilding] = useState([]);

  const variablesBuilding = {
    query: buildingQuery,
    variables: {
      scenarioId: scenario,
      filter: campusId
        ? {
            fields: {
              campusId: {
                eq: campusId,
              },
            },
          }
        : undefined,
    },
  };

  const setBuildingOptions = async (data: any) => {
    if (data && data.data) {
      const dataQuery: DataQuery = data.data.data;
      const optBuildings = [];
      if (dataQuery.buildings.items.length > 0) {
        dataQuery.buildings.items.forEach((building: Building) => {
          optBuildings.push({ label: building.name, value: building.id });
        });
      }

      setOptionsBuilding(optBuildings);
    }
  };

  const getBuildingOptions = useCallback(async () => {
    try {
      const data = await client.query(variablesBuilding);
      await setBuildingOptions(data);
    } catch (error) {
      console.log(error);
    }
  }, [client, variablesBuilding]);

  const getCacheBuildingOptions = useCallback(async () => {
    try {
      const data = await client.readQuery(variablesBuilding);
      await setBuildingOptions(data);
    } catch (error) {
      getBuildingOptions();
    }
  }, [client, getBuildingOptions, variablesBuilding]);

  return {
    optionsBuilding,
    getBuildingOptions,
    getCacheBuildingOptions,
  };
};

export default useGetBuildingOptions;
