import gql from "graphql-tag";

export const departmentQuery = gql`
  query department($scenarioId: ID!, $filter: DepartmentFilterInput) {
    data(scenarioId: $scenarioId) {
      departments(filter: $filter) {
        items {
          id
          code
          name
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;
