import { AcademicUnit, Course, Group, GroupIsEditable, Link, Subject } from "@models/ISchema";

export interface SoftGroupEditionErrors {
  CapacityGTMaxEfficiency?: boolean;
  CapacityLT80MaxEfficiency?: boolean;
  CapacityChangeOnCrnWithoutClassrooms?: boolean;
}

export interface HardGroupEditionErrors {
  CapacityAugmentedForInactive?: boolean;
  CapacityGTSmallerClassroom?: boolean;
  CapacityLTEnrollments?: boolean;
}

export type GroupEditionErrors = SoftGroupEditionErrors & HardGroupEditionErrors;

export interface AdaptedCourse extends Omit<Course, "tags"> {
  tags: Record<string, string | boolean | number>;
}

export interface AdaptedSubject extends Subject {
  departments: AcademicUnit[];
  schools: AcademicUnit[];
}

export interface AdaptedGroup extends Omit<Group, "subject" | "course" | "links" | "isEditable"> {
  subject: AdaptedSubject;
  course: AdaptedCourse;
  link: Link;
  isEditable: AdaptedGroupIsEditable;
}

export interface AdaptedGroupIsEditable extends Omit<GroupIsEditable, "reason"> {
  reason: AdaptedValidationErrorReason;
}

// extends ValidationErrorReason. typescript doesn't allow to extend enums
export enum AdaptedValidationErrorReason {
  ClassroomCapacityExceeded = "CLASSROOM_CAPACITY_EXCEEDED",
  DatabaseError = "DATABASE_ERROR",
  EnrollmentsNotCovered = "ENROLLMENTS_NOT_COVERED",
  ExeedsMax = "EXEEDS_MAX",
  GroupCodeAlreadyTaken = "GROUP_CODE_ALREADY_TAKEN",
  GroupHasEnrollments = "GROUP_HAS_ENROLLMENTS",
  IntervalNotFound = "INTERVAL_NOT_FOUND",
  InvalidClassroom = "INVALID_CLASSROOM",
  InvalidCourse = "INVALID_COURSE",
  InvalidCourseComponent = "INVALID_COURSE_COMPONENT",
  InvalidGroup = "INVALID_GROUP",
  InvalidLink = "INVALID_LINK",
  InvalidLinkCourse = "INVALID_LINK_COURSE",
  InvalidLinkPackage = "INVALID_LINK_PACKAGE",
  InvalidPaymentPercentage = "INVALID_PAYMENT_PERCENTAGE",
  InvalidSection = "INVALID_SECTION",
  InvalidTeachingHoursPercentage = "INVALID_TEACHING_HOURS_PERCENTAGE",
  LinkCodeAlreadyInUse = "LINK_CODE_ALREADY_IN_USE",
  LowerThanMin = "LOWER_THAN_MIN",
  MoreThanOnePrimaryInstructor = "MORE_THAN_ONE_PRIMARY_INSTRUCTOR",
  MustSetPrimaryInstructor = "MUST_SET_PRIMARY_INSTRUCTOR",
  RepeatedComponentForLink = "REPEATED_COMPONENT_FOR_LINK",
  SectionCapacityExceedsCourseComponentMaxSize = "SECTION_CAPACITY_EXCEEDS_COURSE_COMPONENT_MAX_SIZE",
  SessionNotFound = "SESSION_NOT_FOUND",
  SubjectNotFound = "SUBJECT_NOT_FOUND",
  WaitingListNotCovered = "WAITING_LIST_NOT_COVERED",
  GroupVacanciesExternalValidation = "GROUP_VACANCIES_EXTERNAL_VALIDATION",
  InvalidAccessCode = "INVALID_ACCESS_CODE",
  PendingChangeRequest = "PENDING_CHANGE_REQUEST",
  IsNotTheOwnerOfTheGroup = "IS_NOT_THE_OWNER_OF_THE_GROUP",
  GroupCapacityChangeError = "GROUP_CAPACITY_CHANGE_ERROR",
  GroupVisibilityChangeError = "GROUP_VISIBILITY_CHANGE_ERROR",
  AccessDeniedToGroupManager = "ACCESS_DENIED_TO_GROUP_MANAGER",
  GroupNotYetMigrated = "GROUP_NOT_YET_MIGRATED",
}

export enum AdaptedGroupCapacityReason {
  LinkCapacity = "LINK_CAPACITY",
  ClassroomCapacity = "CLASSROOM_CAPACITY",
  ZeroCapacity = "ZERO",
}

export interface SelectableOption<T> {
  id: string;
  value: string;
  label: string;
  self: T;
}

export interface Location {
  view: string;
  page: number;
}

export interface GroupEdition {
  capacity?: number;
  visibleForEnrollment?: boolean;
}

export type ErrorsByGroupId = Record<AdaptedGroup["id"], GroupEditionErrors>;

export type GroupsManagerTableColumn =
  | "CRN"
  | "Grupo | Campus"
  | "Asignatura"
  | "Subperiodo"
  | "Tipo"
  | "Docente principal"
  | "Estado"
  | "Cupos"
  | "Utilización"
  | "Cap."
  | "Min - Max";

export type GroupsChangesTableColumn =
  | "Fecha"
  | "Estado"
  | "CRN"
  | "Sede"
  | "Materia"
  | "Tipo de cambio"
  | "Detalle"
  | "Responsable";

export type SubGroupsTableColumn = "Sede" | "Cupos" | "Utilización";

export type EnrollmentsTableColumn = "Estudiante" | "Carrera" | "Campus" | "Estado" | "Fecha";

export enum FiltersType {
  KEY = "KEY",
  CRN = "CRN",
  PACKAGE = "PACKAGE",
}
