import React, { useState } from "react";
import cx from "classnames";
import Input from "../../../../common/components/Input/Input";
import css from "./capacity.module.scss";
import { Checkbox } from "@foris/avocado-ui";

export interface ICapacityValues {
  minCapacity: string;
  maxCapacity: string;
  overflow: boolean;
}

export interface ICapacityReturn {
  onCallback: (value: ICapacityValues, error?: boolean) => void;
  desactivePaddging?: boolean;
}

const CapacityInput: React.FC<ICapacityReturn> = (props: ICapacityReturn) => {
  const { onCallback } = props;
  const [error, setErrorCapacity] = useState(false);
  const [capacityCheck, setCapacityCheck] = useState(false);
  const [minCapacity, setMinCapacity] = useState("");
  const [maxCapacity, setMaxCapacity] = useState("");

  const handleError = (min: string, max: string) => {
    if (parseInt(min) > parseInt(max)) {
      setErrorCapacity(true);
      return true;
    } else {
      setErrorCapacity(false);
      return false;
    }
  };

  return (
    <div className={css.sharedCapacity}>
      <div className={css.sharedCapacity_grid}>
        <div className={css.sharedCapacity_control}>
          <Input
            type="number"
            placeholder="Min"
            error={error}
            value={minCapacity}
            onChange={value => {
              if (value >= 0) {
                setMinCapacity(value);
                const error = handleError(value, maxCapacity);
                onCallback(
                  {
                    minCapacity: value,
                    maxCapacity: maxCapacity,
                    overflow: capacityCheck,
                  },
                  error,
                );
              }
            }}
          />
        </div>

        <span className={css.sharedCapacity_separator}>-</span>

        <div className={css.sharedCapacity_control}>
          <Input
            type="number"
            placeholder="Max"
            error={error}
            value={maxCapacity}
            onChange={value => {
              if (value >= 0) {
                setMaxCapacity(value);
                const error = handleError(minCapacity, value);
                onCallback(
                  {
                    minCapacity: minCapacity,
                    maxCapacity: value,
                    overflow: capacityCheck,
                  },
                  error,
                );
              }
            }}
          />
        </div>

        <div className={cx(css.sharedCapacity_checkbox)}>
          <Checkbox
            className={css.checkboxField}
            labelRight="Considerar sobrecupo"
            checked={capacityCheck}
            onChange={() => {
              setCapacityCheck(!capacityCheck);
              onCallback(
                {
                  minCapacity: minCapacity,
                  maxCapacity: maxCapacity,
                  overflow: !capacityCheck,
                },
                error,
              );
            }}
          />
        </div>
      </div>

      {error && (
        <p className={cx(css.sharedCapacity_error)}>
          La capacidad mínima no puede ser mayor a la capacidad máxima.
        </p>
      )}
    </div>
  );
};

export default CapacityInput;
