import { useCallback, useState } from "react";
import { useApolloClient } from "react-apollo";
import { DataQuery, Shift } from "@models/ISchema";
import { shiftQuery } from "../graphql/shift.queries";

interface UseGetShiftOptionsParams {
  scenario: string;
  campusId?: string;
}

const useGetShiftOptions = ({ scenario }: UseGetShiftOptionsParams) => {
  const client = useApolloClient();
  const [termToSearch, setTermToSearch] = useState("");
  const [shiftPage, setShiftPage] = useState(1);

  const requestItems = useCallback(
    async (searchTerm = "", page = 1) => {
      const optionsPerPage = 10;

      try {
        const variables = {
          query: shiftQuery,
          variables: {
            scenarioId: scenario,
            filter: {
              pagination: {
                page,
                size: optionsPerPage,
                searchTerm,
              },
            },
          },
        };

        const { data } = await client.query(variables);
        const dataQuery: DataQuery = data.data;
        const pageInfo = dataQuery?.shifts?.pageInfo;

        const options =
          dataQuery?.shifts?.items?.map((shift: Shift) => ({
            label: shift.name,
            value: shift.id,
          })) ?? [];

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [client, shiftPage],
  );

  const loadOptions = async (newSearchTerm: string) => {
    let searchPage = 1;

    if (termToSearch === newSearchTerm) {
      searchPage = shiftPage;
    } else {
      setTermToSearch(newSearchTerm);
    }

    const { pageInfo, options } = await requestItems(newSearchTerm, searchPage);
    setShiftPage(shiftPage + 1);

    return {
      options: options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page: shiftPage },
    };
  };

  return {
    loadOptions,
  };
};

export default useGetShiftOptions;
