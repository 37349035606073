import { useApolloClient } from "react-apollo";
import { useState } from "react";
import { CubeQuery, Group } from "@models/ISchema";
import packageGroupsSimpleQuery from "../graphql/packageGroupsSimple.query.graphql";

interface useGetPackageSimpleGroupsProps {
  packageId: string;
  scenarioId: string;
  originId: string;
}

const useGetPackageSimpleGroups = ({
  packageId,
  scenarioId,
  originId,
}: useGetPackageSimpleGroupsProps) => {
  const client = useApolloClient();

  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const getPackageSubgroups = async () => {
    try {
      setIsLoading(true);

      const { data } = await client.query({
        query: packageGroupsSimpleQuery,
        variables: {
          scenarioId,
          originId,
          filter: {
            fields: {
              package: {
                eq: packageId,
              },
            },
          },
        },
      });

      const cube = data?.cube as CubeQuery;
      setGroups(cube?.groupsManagerSearch?.items);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setHasError(true);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    hasError,
    getPackageSubgroups,
    groups,
  };
};

export default useGetPackageSimpleGroups;
