import React, { useContext, useMemo } from "react";
import { CardState } from "@foris/avocado-ui";
import { ContextApp } from "@config/Context/contextApp";

interface AbilitiesMessageProps {
  className?: string;
}

const permissionsLabel = {
  can_assign_instructors: "editar asignación de docentes",
  can_assign_intervals: "editar asignación de semanas",
  can_assign_classrooms: "editar asignación de salas",
  can_assign_blocks: "editar asignación de horarios",
};

const AbilitiesMessage = ({ className = "" }: AbilitiesMessageProps) => {
  const { user } = useContext(ContextApp);
  const sessionAbilitiesDisabled = useMemo(
    () => Object.keys(permissionsLabel)?.filter(key => !user?.abilities?.[key]),
    [user?.abilities],
  );

  const labels = useMemo(
    () => sessionAbilitiesDisabled.map(key => permissionsLabel[key]).join(", "),
    [sessionAbilitiesDisabled],
  );

  if (!user?.abilities || !sessionAbilitiesDisabled?.length) {
    return null;
  }

  return (
    <CardState
      className={className}
      title="Tienes permisos de edición limitados"
      typeCard="informative"
    >
      De acuerdo a tu configuración de permisos no puedes: {labels}.
    </CardState>
  );
};

export default AbilitiesMessage;
