import React, { useContext, useEffect, useMemo, useState } from "react";
import { Header } from "@common/components";
import Layout from "@common/layout/Layout";
import { Button, CardState, Loading, Snackbar, TextField } from "@foris/avocado-ui";
import { useGetHolidays } from "@modules/sections/hooks/useGetHolidays";
import { IParams } from "@models/IParams";
import { useMutation } from "react-apollo";
import { useHistory, useParams } from "react-router-dom";
import Modal from "@modules/sections/GroupsManager/components/Modal/Modal";
import { DELETE_SESSIONS_BY_HOLIDAY } from "@modules/holidays/graphql/deleteSessionsByHolidayMutation";
import { ContextEDH } from "@context/ContextEDH";
import Menu from "@common/components/Header/Menu/Menu";
import css from "./sessionsDelete.module.scss";

const darwinUrl = process.env.REACT_APP_DARWINED_URL;

const SessionsDelete = () => {
  const { state } = useContext(ContextEDH);
  const { scenario, origin, workspace }: IParams = useParams();
  const history = useHistory();
  const [sessionCount, setSessionCount] = useState("7");
  const [deletedSessionsLinksCount, setDeletedSessionsLinksCount] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [deleteSessions, { loading: deletingSessions }] = useMutation(DELETE_SESSIONS_BY_HOLIDAY);

  if (!state?.base?.base?.user?.abilities?.can_delete_sessions_in_holidays) {
    history.replace(`/home/${workspace}/${scenario}/${origin}`);
  }

  const [{ holidays, isLoading }, getHolidays] = useGetHolidays({
    scenario,
  });

  const isFormValid = sessionCount && Number(sessionCount) > 0;

  const handleDeleteSessions = async () => {
    if (!isFormValid) {
      return;
    }

    setShowConfirmModal(false);
    const response = await deleteSessions({
      variables: {
        scenarioId: scenario,
        originId: origin,
        input: {
          dryRun: false,
          skipValidations: false,
          minOccurrencesToDelete: Number(sessionCount),
        },
      },
    });

    if (!!response?.data?.cube?.sessionsByHoliday) {
      setDeletedSessionsLinksCount(response?.data?.cube?.sessionsByHoliday?.link?.length);
      setShowSnackbar(true);
    }
  };

  useEffect(() => {
    if (!isLoading && !Object.keys(holidays).length) {
      getHolidays();
    }
  }, []);

  const holidaysArr = useMemo(
    () =>
      Object.values(holidays)?.map(holiday => {
        const [year, month, day] = holiday?.date.split("-");

        return {
          ...holiday,
          date: `${day}-${month}-${year}`,
        };
      }),
    [holidays],
  );

  const handleBack = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(`/home/${workspace}/${scenario}/${origin}`);
    }
  };

  return (
    <Layout contextSearch>
      {(isLoading || deletingSessions) && <Loading />}

      <Snackbar active={showSnackbar} type="confirm" duration={5} setValueActive={setShowSnackbar}>
        <p className={css.successMessage}>
          Operación concluida exitosamente. {deletedSessionsLinksCount} Ligas eliminaron sesiones.
        </p>
      </Snackbar>

      <Header
        className={css.sessionsDelete_header}
        titleClassName={css.headerTitle}
        title="Eliminar sesiones en festivos"
        renderMenu={() =>
          darwinUrl ? (
            <Menu
              items={[
                {
                  label: "Descargar consolidado de sesiones eliminadas",
                  icon: "download",
                  className: css.HeaderMenuItem,
                  onClick: () => {
                    window.open(
                      `${darwinUrl}resultados/deleted_sessions_by_holiday_report/${origin}?scenario_id=${scenario}`,
                      "_blank",
                    );
                  },
                },
              ]}
            />
          ) : (
            undefined
          )
        }
      />

      <main className={css.sessionsDelete_content}>
        <CardState
          className={css.contentInfo}
          typeCard="informative"
          title="Catálogo vigente de días festivos"
        >
          Para la eliminación de sesiones en festivos se tomará como referencia el catálogo de
          festivos modelados para este escenario.
        </CardState>

        <div className={css.holidaysWrapper}>
          <h3 className={css.holidaysWrapper_title}>Resumen días festivos</h3>

          <ul className={css.holidaysWrapper_list}>
            {holidaysArr.map(holiday => (
              <li key={holiday?.id} className={css.holiday}>
                {holiday?.date} - {holiday?.name}
              </li>
            ))}
          </ul>
        </div>

        <div className={css.contentForm}>
          <h4 className={css.contentForm_title}>Configurar excepciones a la eliminación</h4>

          <div className={css.contentForm_control}>
            No eliminar sesiones si la liga tiene
            <TextField
              type="number"
              min={1}
              value={sessionCount}
              onChange={e => setSessionCount(e?.target?.value)}
            />
            o menos ocurrencias en periodo.
          </div>

          <div className={css.contentForm_actions}>
            <Button className={css.actionButton} variant="outline" onClick={handleBack}>
              Cancelar
            </Button>
            <Button
              className={css.actionButton}
              onClick={() => setShowConfirmModal(true)}
              disabled={!isFormValid}
            >
              Eliminar sesiones
            </Button>
          </div>
        </div>

        <Modal
          typeState="confirm"
          show={showConfirmModal}
          title="Confirma que deseas eliminar las sesiones en festivos"
          subtitle="Esta acción es irreversible."
          onClose={() => setShowConfirmModal(false)}
          textButtonPrincipal="Eliminar sesiones"
          textButtonSecondary="Cancelar"
          colorButtonPrincipal="danger"
          onClickPrincipal={handleDeleteSessions}
          onClickSecondary={() => setShowConfirmModal(false)}
        >
          Concluida la operación verás una confirmación en pantalla.
        </Modal>
      </main>
    </Layout>
  );
};

export default SessionsDelete;
