import gql from "graphql-tag";

export const courseMultiKeyQuery = gql`
  query courseMultiKey($scenarioId: ID!, $originId: ID!, $id: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      link(id: $id) {
        label
        bundle {
          id
          name
          code
          term {
            id
            code
          }
        }
        course {
          id
          name
          code
          level
          courseComponents {
            id
          }
          curriculum {
            id
            code
            name
            program {
              id
              code
              name
              shift {
                id
                code
                name
              }
              modality {
                id
                code
                name
              }
              campus {
                id
                code
                name
              }
              department {
                id
                code
                name
              }
            }
          }
        }
      }
    }
  }
`;
