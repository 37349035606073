import React, { useMemo, useState } from "react";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { Loading } from "@foris/avocado-ui";
import { IParams } from "../../../../models/IParams";
import { ClassRoom } from "../../../shared/selectors";
import { Capacity, TimeRange } from "../../../shared/inputs";
import { Days } from "../../../shared/checks";
import { EmptyResult } from "../../../shared/modals";
import WeeksRange from "../../../shared/weeksRange/WeeksRange";
import Button from "../../../../common/components/Button/Button";
import { validations } from "./utils";
import * as utilsAdvance from "../../../advance-search/utils";
import {
  IAvailability,
  ITimeRangeDependency,
  IWeeksRangeDependency,
} from "../../../advance-search/IAdvanceSearch";
import { objectEmpty } from "../../../../utils/validations";
import { classRoomSearchMutation } from "../graphql/advanceSearch.queries";
import AdvanceCampusSelector from "@modules/advance-search/components/advance-campus-selector";
import AdvancedBuildingSelector from "@modules/advance-search/components/advanced-building-selector";
import { Option } from "react-select/lib/filters";
import { getAdvancedSelectorsFilters } from "@dimensions/utils/searchDimension";
import css from "../../../advance-search/advance-search.module.scss";

const AdvanceSearchClassRoom: React.FC<any> = (props: any) => {
  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const [advanceSearchMutation] = useMutation(classRoomSearchMutation, {});
  const [error, setError] = useState(false);
  const [daysError, setDaysError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [timeRangeError, setTimeRangeError] = useState(false);
  const [objCapacity, setObjCapacity] = useState({});
  const [objAvailability, setObjAvailability] = useState<IAvailability>({});
  const [loading, setLoading] = useState(false);

  const [selectedCampus, setSelectedCampus] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedClassrronType, setSelectedClassrronType] = useState(null);

  const hasSomeFilter = useMemo(() => {
    return (
      !objectEmpty(objCapacity) ||
      !objectEmpty(objAvailability) ||
      selectedBuilding ||
      selectedCampus ||
      selectedClassrronType
    );
  }, [objCapacity, objAvailability, selectedBuilding, selectedCampus, selectedClassrronType]);

  const hasSomeError = error || daysError || timeRangeError;

  const handleSelectCampus = (option: Option) => {
    setSelectedCampus(option?.value ?? null);
    setSelectedBuilding(null);
  };

  const setCapacity = (values: any, error: any) => {
    if (error) {
      setError(true);
      return;
    }

    const validation = validations.capacity(values, objCapacity);
    setObjCapacity(validation.objCapacity);
    setError(validation.error);
  };

  const setDays = (value: number[]) => {
    const validation = utilsAdvance.validations.days(value, objAvailability);
    setObjAvailability(validation.availability);
    setError(validation.error);
    setTimeRangeError(validation.timeRangeError);
    setDaysError(validation.daysError);
  };
  const setTimeRange = (value: ITimeRangeDependency, error: boolean) => {
    if (error) {
      setError(true);
      return;
    }
    const validation = utilsAdvance.validations.timeRange(value, objAvailability);
    setObjAvailability(validation.availability);
    setError(validation.error);
    setTimeRangeError(validation.timeRangeError);
    setDaysError(validation.daysError);
  };

  const setWeeksRange = (values: IWeeksRangeDependency) => {
    const validation = utilsAdvance.validations.weeksRange(values, objAvailability);
    setObjAvailability(validation.availability);
  };

  const advanceRowContent = (title: string, component: React.ReactNode) => (
    <div className={cx(css.advance_item, "container-row")}>
      <h5 className={cx(css.advance_item_title, "col_12")}>{title}</h5>
      <div className={cx(css.advance_row, "container-row")}>{component}</div>
    </div>
  );

  let weeksDisabled = true;
  if (objAvailability.availability) {
    const daysExist = objAvailability.availability.hasOwnProperty("dayId");
    const rangeExist = objAvailability.availability.hasOwnProperty("blockRange");
    if (rangeExist) {
      const start = objAvailability.availability.blockRange.hasOwnProperty("start");
      const end = objAvailability.availability.blockRange.hasOwnProperty("end");
      if (start && end && daysExist) weeksDisabled = false;
    }
  }

  const createObjectMutation = () => {
    const filterArray = [];

    const selectorsFilterArray = getAdvancedSelectorsFilters({
      campusId: selectedCampus,
      buildingId: selectedBuilding,
    });

    !objectEmpty(objCapacity) && filterArray.push(objCapacity);
    !objectEmpty(objAvailability) && filterArray.push(objAvailability);

    const mutationFilter = {
      originId: parseInt(origin),
      scenarioId: parseInt(scenario),
      filter: {
        fields: {
          and: [
            // Set default filter 'cause now we bring only the ones with assignment
            { hasEvents: true },
            ...selectorsFilterArray,
            ...filterArray,
          ],
        },
      },
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return mutationFilter;
  };

  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createClassroomFilter;
      if (dataItems.classrooms.items.length > 0) {
        const idSearch = dataItems.id;
        const contextUrl = `${workspace}/${scenario}/${origin}`;
        history.push(`/scheduler/editor/searchBy/classroom/${contextUrl}/?advance=${idSearch}`);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <EmptyResult show={isEmpty} onClose={() => setIsEmpty(false)} />

      <div className={cx(css.advanceSearchContent_selectorWrapper, css.hasBottomSeparation)}>
        <AdvanceCampusSelector scenario={scenario} onChange={handleSelectCampus} />
        <AdvancedBuildingSelector
          key={`building-${selectedCampus}`}
          scenario={scenario}
          campusId={selectedCampus}
          onChange={option => setSelectedBuilding(option?.value ?? null)}
        />
      </div>

      <div className={cx(css.advanceSearchContent_selectorWrapper, css.hasBottomSeparation)}>
        <ClassRoom
          label
          labelValue="Tipo de sala"
          placeholder="Seleccionar tipo de sala"
          onCallback={values => setSelectedClassrronType(values ? values.value : null)}
        />
      </div>

      {advanceRowContent(
        "Capacidad",
        <Capacity onCallback={(values, error) => setCapacity(values, error)} />,
      )}
      {advanceRowContent(
        "Días",
        <Days required={daysError} onCallback={values => setDays(values)} />,
      )}
      {advanceRowContent(
        "Rango de horario",
        <TimeRange
          required={timeRangeError}
          onCallback={(values, error) => setTimeRange(values, error)}
        />,
      )}
      <WeeksRange
        {...props}
        disabled={weeksDisabled}
        onCallback={values => setWeeksRange(values)}
      />
      {loading ? (
        <Loading />
      ) : (
        <Button
          className={css.actionButton}
          disabled={hasSomeError || !hasSomeFilter}
          onClick={() => callUpdateHook()}
        >
          Buscar
        </Button>
      )}
    </>
  );
};

export default AdvanceSearchClassRoom;
