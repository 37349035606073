import { useCallback, useState } from "react";
import { useApolloClient } from "react-apollo";
import { DataQuery, Department } from "@models/ISchema";
import { departmentQuery } from "../graphql/department.queries";

interface UseGetDepartmentOptionsParams {
  scenario: string;
  campusId?: string;
}

const useGetDepartmentOptions = ({ scenario }: UseGetDepartmentOptionsParams) => {
  const client = useApolloClient();
  const [termToSearch, setTermToSearch] = useState("");
  const [departmentPage, setDepartmentPage] = useState(1);

  const requestItems = useCallback(
    async (searchTerm = "", page = 1) => {
      const optionsPerPage = 10;

      try {
        const variables = {
          query: departmentQuery,
          variables: {
            scenarioId: scenario,
            filter: {
              pagination: {
                page,
                size: optionsPerPage,
                searchTerm,
              },
            },
          },
        };

        const { data } = await client.query(variables);
        const dataQuery: DataQuery = data.data;
        const pageInfo = dataQuery?.departments?.pageInfo;

        const options =
          dataQuery?.departments?.items?.map((department: Department) => ({
            label: `${department.code} - ${department.name.replace(/-/g, "")}`,
            value: department.id,
          })) ?? [];

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [client, departmentPage],
  );

  const loadOptions = async (newSearchTerm: string) => {
    let searchPage = 1;

    if (termToSearch === newSearchTerm) {
      searchPage = departmentPage;
    } else {
      setTermToSearch(newSearchTerm);
    }

    const { pageInfo, options } = await requestItems(newSearchTerm, searchPage);
    setDepartmentPage(departmentPage + 1);

    return {
      options: options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page: departmentPage },
    };
  };

  return {
    loadOptions,
  };
};

export default useGetDepartmentOptions;
