import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Icon, Select } from "@foris/avocado-ui";
import cx from "classnames";
import { IParams } from "@models/IParams";
import Layout from "../../common/layout/Layout";
import ClassRoom from "@dimensions/classroom/AdvanceSearch/AdvanceSearchClassRoom";
import Instructor from "@dimensions/instructor/AdvanceSearch/AdvanceSearchInstructor";
import Population from "@dimensions/population/AdvanceSearch/AdvanceSearchPopulation";
import Course from "../dimensions/course/AdvanceSearch/AdvanceSearchCourse";
import Section from "../dimensions/section/AdvanceSearch/AdvanceSearchSection";
import Group from "@dimensions/group/AdvanceSearch/AdvanceSearchGroup";
import Link from "@dimensions/link/pages/AdvanceSearch/AdvanceSearchLink";
import Package from "@dimensions/package/AdvanceSearch/AdvanceSearch";
import css from "./advance-search.module.scss";

const options = [
  {
    label: "Sala",
    value: "classroom",
  },
  {
    label: "Docente",
    value: "instructor",
  },
  {
    label: "Sección",
    value: "section",
  },
  {
    label: "Liga",
    value: "link",
  },
  {
    label: "Asignatura",
    value: "course",
  },
  {
    label: "Paquete",
    value: "package",
  },
  {
    label: "Poblacion",
    value: "population",
  },
  {
    label: "Grupo",
    value: "group",
  },
];

const Search: React.FC<any> = (props: any) => {
  const { dimension }: IParams = useParams();
  const [dimensionValue, setDimensionValue] = useState(null);

  useEffect(() => {
    if (dimension) {
      const valueSelect = dimension && options.find(option => option.value === dimension);
      setDimensionValue(valueSelect);
    }
  }, [dimension]);

  return (
    <Layout contextSearch={false}>
      <div className={css.container_advc}>
        <section className={cx(css.cnt_advance, "container-row")}>
          <h3 className={cx(css.cnt_advance_title, "col_12")}>
            <span className={css.back} onClick={() => props.history.goBack()}>
              <Icon icon="chevron-left" size={16} />
            </span>
            Búsqueda avanzada
          </h3>

          <section className={cx(css.advanceSearchContent, "col_8", "col_md_12")}>
            <div className={cx(css.advance_item, "container-row")}>
              <div className={cx(css.advance_item_select)}>
                <Select
                  label="Dimensión"
                  value={dimensionValue}
                  options={options}
                  placeholder="Seleccionar"
                  onChange={(value: any) => {
                    setDimensionValue(value);
                  }}
                />
              </div>
            </div>
            {dimensionValue && dimensionValue.value === "classroom" && <ClassRoom />}
            {dimensionValue && dimensionValue.value === "instructor" && <Instructor />}
            {dimensionValue && dimensionValue.value === "population" && <Population />}
            {dimensionValue && dimensionValue.value === "course" && <Course />}
            {dimensionValue && dimensionValue.value === "section" && <Section />}
            {dimensionValue && dimensionValue.value === "group" && <Group />}
            {dimensionValue && dimensionValue.value === "link" && <Link />}
            {dimensionValue && dimensionValue.value === "package" && <Package />}
          </section>
        </section>
      </div>
    </Layout>
  );
};

export default Search;
