import React from "react";
import { Option } from "react-select/lib/filters";
import AdvanceSelector from "../advance-selector";
import useGetCurriculumOptions from "@modules/shared/selectors/hooks/useGetCurriculumOptions";

interface AdvancedCurriculumSelectorProps {
  scenario: string;
  campusId?: string;
  departmentId?: string;
  modalityId?: string;
  shiftId?: string;
  programId?: string;
  activeCode?: boolean;
  onChange?: (option: Option) => void;
}

const AdvancedCurriculumSelector = ({
  scenario,
  onChange,
  ...filters
}: AdvancedCurriculumSelectorProps) => {
  const { loadCurriculumOptions } = useGetCurriculumOptions({
    scenario,
    ...filters,
  });

  return (
    <AdvanceSelector
      isPaginated
      dimension="Currículum"
      selectPlaceholder="Selecciona un currículum"
      loadOptions={loadCurriculumOptions}
      onChange={onChange}
    />
  );
};

export default AdvancedCurriculumSelector;
