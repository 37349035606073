import { createContext, useCallback, useState } from "react";
import { Course, GroupSection, InstructorLink, InvalidInput, Term } from "@models/ISchema";
import { IErrorWarning } from "@models/Errors/ErrorWarning";

export type errorInstructorType = "responsability" | "payment";
export interface IGroup {
  id: string;
  vacancies: number;
  label: string;
  enrollment: any;
  waitList: any;
  curriculum: string;
  program: string;
  course: Course;
  campus?: string;
  openGroup?: boolean;
  clientCode?: string;
  isActive: boolean;
  groupSections?: Array<GroupSection>;
}

export type IBundleTerm = Pick<Term, "id" | "code">;

export interface ILink {
  code: string;
  codeName?: string;
  color?: string;
  idColor?: number;
  vacancies: number;
}

export interface ISection {
  typeComponent: string;
  sectionId: string;
  componentId: string;
  vacancies: number;
  link: Array<ILink>;
}

export interface ILeague {
  code: string;
  codeName: string;
  clientCode?: string | number;
  color: string;
  groups?: Array<IGroup>;
  course?: Course;
  instructors?: Array<InstructorLink>;
  blocksWithoutInstructorAssigment?: number;
  id: string;
  idColor: number;
  label: string;
  sections: Array<ISection>;
  linkId: string;
  totalBlocks: number;
}

export interface IGroupValue {
  linkId: string;
  group?: IGroup;
  instructor?: InstructorLink;
  blockPercent?: number;
  value?: number;
  teachingHoursPercentage?: number;
  paymentPercentage?: number;
  isPrimary?: boolean;
}
export interface IErrorInstructorBundle {
  error: IErrorWarning;
  link: ILeague;
  item: InvalidInput;
}

export interface IContextVacancies {
  currentLeague: Array<ILeague>;
  setCurrentLeague: (value: Array<ILeague>) => void;
  changeValues: Array<Array<IGroupValue>>;
  setChangeValues: (value: Array<any>) => void;
  instructorValues: Array<Array<IGroupValue>>;
  setInstructorValues: (value: Array<Array<IGroupValue>>) => void;
  instructorOrigin: Array<Array<IGroupValue>>;
  setInstructorOrigin: (value: Array<Array<IGroupValue>>) => void;
  errorsInstructors: Array<IErrorInstructorBundle>;
  setErrorsInstructors: (value: Array<IErrorInstructorBundle>) => void;
}

const defaultContext: IContextVacancies = {
  currentLeague: null,
  setCurrentLeague: null,
  changeValues: null,
  setChangeValues: null,
  instructorValues: [],
  setInstructorValues: null,
  instructorOrigin: [],
  setInstructorOrigin: null,
  errorsInstructors: [],
  setErrorsInstructors: null,
};

export const ContextVacancies = createContext<IContextVacancies>(defaultContext);

export const useContextVacancies = (): IContextVacancies => {
  const [currentLeague, setCurrentLeagueState] = useState(defaultContext.currentLeague);
  const [changeValues, setChangeValuesState] = useState(defaultContext.changeValues);
  const [instructorValues, setInstructorValuesState] = useState<Array<Array<IGroupValue>>>(
    defaultContext.instructorValues,
  );
  const [instructorOrigin, setInstructorOriginState] = useState<Array<Array<IGroupValue>>>(
    defaultContext.instructorOrigin,
  );
  const [errorsInstructors, setErrorsInstructorsState] = useState<Array<IErrorInstructorBundle>>(
    defaultContext.errorsInstructors,
  );

  const setCurrentLeague = useCallback((value: Array<ILeague>) => {
    if (value) setCurrentLeagueState(value);
  }, []);

  const setChangeValues = useCallback((value: Array<any>) => {
    if (value) setChangeValuesState(value);
  }, []);

  const setInstructorValues = useCallback((value: Array<Array<IGroupValue>>) => {
    if (value) setInstructorValuesState(value);
  }, []);
  const setInstructorOrigin = useCallback((value: Array<Array<IGroupValue>>) => {
    if (value) setInstructorOriginState(value);
  }, []);

  const setErrorsInstructors = useCallback((value: Array<IErrorInstructorBundle>) => {
    if (value) setErrorsInstructorsState(value);
  }, []);

  return {
    currentLeague,
    setCurrentLeague,
    changeValues,
    setChangeValues,
    instructorValues,
    setInstructorValues,
    instructorOrigin,
    setInstructorOrigin,
    errorsInstructors,
    setErrorsInstructors,
  };
};
