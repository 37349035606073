import React from "react";
import { CardState } from "@foris/avocado-ui";
import { Link } from "@models/ISchema";
import { Link as RouterLink } from "react-router-dom";
import css from "../PackageLinkManagement/packageLinkManagement.module.scss";

interface LinkAssignmentErrorsProps {
  origin: string;
  scenario: string;
  workspace: string;
  errorMessagesByType: {
    type: string;
    userCanSkipValidations: boolean;
    links: {
      [key: string]: Link;
    };
  }[];
}

type ErrorMessageProps = {
  origin: string;
  scenario: string;
  workspace: string;
  type: string;
  links: { [key: string]: Link };
  userCanSkipValidations: boolean;
};

const getErrorTitle = (type: string, userCanSkipValidations: boolean) => {
  if (type === "ClashesBetweenPackages") {
    return !userCanSkipValidations
      ? "No puedes guardar cambios que generan choques de paquete."
      : "Choque de horario en el paquete";
  }

  return "No se puede vincular Paquete a Liga";
};

const getErrorLinkPage = (origin: string, scenario: string, workspace: string, links: Link[]) => {
  const baseRoute = `/scheduler/editor/link/${workspace}/${scenario}/${origin}`;

  return links.map((link, index) => {
    return (
      <>
        <RouterLink key={link.id} to={`${baseRoute}/${link?.id}`} className={css.packageLink}>
          [{link?.code}]
        </RouterLink>
        {index + 1 < links.length ? ", " : ""}
      </>
    );
  });
};

export const isErrorMessage = (type: string, userCanSkipValidations: boolean) => {
  if (type === "ClashesBetweenPackages" && userCanSkipValidations) {
    return false;
  }

  return true;
};

const getErrorMessage = ({
  links,
  type,
  userCanSkipValidations,
  origin,
  scenario,
  workspace,
}: ErrorMessageProps) => {
  const linksArr = Object.values(links);
  const linkCodes = getErrorLinkPage(origin, scenario, workspace, linksArr);

  if (type === "ClashesBetweenPackages") {
    const isPlural = linksArr?.length > 1;
    const isError = isErrorMessage(type, userCanSkipValidations);
    const pluralLetter = isPlural ? "s" : "";

    return (
      <p>
        Al agregar {`esta${pluralLetter}`} {`liga${pluralLetter}`} al Paquete {linkCodes} se generan
        choques horarios dentro del paquete.{" "}
        {isError ? "No tienes permisos para guardar cambios que generan Choques de Paquete" : ""}
      </p>
    );
  }

  if (type === "InvalidLinksCourse") {
    const singular = `El paquete agregado`;
    const plural = `Los paquetes agregados`;

    const isSingular = linksArr.length <= 1;

    return (
      <p>
        {isSingular ? singular : plural} {linkCodes} ya contiene{!isSingular && "n"} una Liga de la
        misma llave de asignatura. No es posible generar la vinculación.
      </p>
    );
  }

  return "";
};

const LinkAssignmentErrors = ({
  errorMessagesByType,
  origin,
  scenario,
  workspace,
}: LinkAssignmentErrorsProps) => {
  return (
    <>
      {errorMessagesByType?.map(({ links, type, userCanSkipValidations }) => (
        <CardState
          key={type}
          className={css.errorCard}
          typeCard={!isErrorMessage(type, userCanSkipValidations) ? "warning" : "error"}
          title={getErrorTitle(type, userCanSkipValidations)}
        >
          {getErrorMessage({ type, links, userCanSkipValidations, origin, scenario, workspace })}
        </CardState>
      ))}
    </>
  );
};

export default LinkAssignmentErrors;
