import React from "react";
import css from "./advanceSelector.module.scss";
import { Select, SelectPagination, Text } from "@foris/avocado-ui";
import { Option } from "react-select/lib/filters";

interface AdvanceSelectorProps {
  dimension: string;
  condition?: string;
  selectPlaceholder: string;
  isPaginated?: boolean;
  isLoading?: boolean;
  options?: Option[];
  /**
   *
   * @param loadOptions
   * Should be use only if isPaginated is true
   */
  loadOptions?: (
    searchTerm: string,
  ) => Promise<{
    options: any[];
    hasMore: boolean;
    additional: {
      page: number;
    };
  }>;
  onChange?: (option: Option) => void;
}

const AdvanceSelector = ({
  dimension,
  condition = "Es igual a",
  selectPlaceholder,
  isPaginated = false,
  options = [],
  isLoading = false,
  loadOptions,
  onChange,
}: AdvanceSelectorProps) => {
  return (
    <div className={css.advanceSelector}>
      <div className={css.advanceSelector_customField}>
        <Text type="sm" className={css.fieldLabel}>
          Propiedad
        </Text>
        <span className={css.fieldValue}>{dimension}</span>
      </div>
      <div className={css.advanceSelector_customField}>
        <Text type="sm" className={css.fieldLabel}>
          Condición
        </Text>
        <span className={css.fieldValue}>{condition}</span>
      </div>
      <div className={css.advanceSelector_field}>
        {isPaginated ? (
          <SelectPagination
            label="Valor"
            placeholder={selectPlaceholder}
            noOptionsMessage={() => <>Sin opciones</>}
            loadOptions={loadOptions}
            onChange={onChange}
            isClearable
          />
        ) : (
          <Select
            label="Valor"
            placeholder={selectPlaceholder}
            noOptionsMessage={() => <>Sin opciones</>}
            options={options}
            onChange={onChange}
            isClearable
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default AdvanceSelector;
