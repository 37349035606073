import React from "react";
import { Option } from "react-select/lib/filters";
import AdvanceSelector from "../advance-selector";
import useGetDepartmentOptions from "@modules/shared/selectors/hooks/useGetDepartmentOptions";

interface AdvancedDepartmentSelectorProps {
  scenario: string;
  onChange?: (option: Option) => void;
}

const AdvancedDepartmentSelector = ({ scenario, onChange }: AdvancedDepartmentSelectorProps) => {
  const { loadOptions } = useGetDepartmentOptions({
    scenario,
  });

  return (
    <AdvanceSelector
      isPaginated
      dimension="Escuela"
      selectPlaceholder="Selecciona una escuela"
      loadOptions={loadOptions}
      onChange={onChange}
    />
  );
};

export default AdvancedDepartmentSelector;
