import gql from "graphql-tag";

export const courseQuery = gql`
  query course($scenarioId: ID!, $filter: CourseFilterInput) {
    data(scenarioId: $scenarioId) {
      courses(filter: $filter) {
        items {
          id
          name
          code
          level
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
