import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { Select } from "@foris/avocado-ui";
import { classroomQuery } from "../graphql/classroom.queries";
import { ClassroomType, DataQuery } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { ISelectorReturn, IOption } from "./ISelectors";
import css from "./selectors.module.scss";

const ClassRoomSelect: React.FC<ISelectorReturn> = (props: ISelectorReturn) => {
  const client = useApolloClient();
  const { onCallback, label, className, placeholder, labelValue = "Sala" } = props;
  const { scenario }: IParams = useParams();
  const [optionsClassroom, setOptionsClassroom] = useState([]);
  const [valueClassroom, setValueClassroom] = useState(null);

  const variablesClassRoom = {
    query: classroomQuery,
    variables: {
      scenarioId: scenario,
    },
  };

  const setClassroomOptions = async (data: any) => {
    if (data && data.data) {
      const dataQuery: DataQuery = data.data.data;
      const optCompuses = [];
      if (dataQuery.classroomTypes.items.length > 0) {
        dataQuery.classroomTypes.items.forEach((classroom: ClassroomType) => {
          optCompuses.push({ label: classroom.name, value: classroom.id });
        });
      }
      setOptionsClassroom(optCompuses);
    }
  };

  const getClassroomOptions = async () => {
    try {
      const data = await client.query(variablesClassRoom);
      await setClassroomOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCacheClassroomOptions = async () => {
    try {
      const data = await client.readQuery(variablesClassRoom);
      await setClassroomOptions(data);
    } catch (error) {
      getClassroomOptions();
    }
  };

  useEffect(() => {
    if (optionsClassroom.length === 0) {
      getCacheClassroomOptions();
    }
  });
  return (
    <div className={cx(css.selectors, className)}>
      {label && <label className={css.selectors_labelItem}>{labelValue}</label>}
      <Select
        options={optionsClassroom}
        placeholder={placeholder ? placeholder : label ? "Seleccionar" : "Sala"}
        isClearable
        value={valueClassroom}
        onChange={(value: IOption) => {
          if (value) {
            setValueClassroom(value);
            onCallback(value);
          } else {
            setValueClassroom("");
            onCallback(value);
          }
        }}
      />
    </div>
  );
};

export default ClassRoomSelect;
