import gql from "graphql-tag";

export const SHARED_PACKS_QUERY = gql`
  query($scenarioId: ID!, $originId: ID!, $input: SharedPackComponentsInput!) {
    data(scenarioId: $scenarioId, originId: $originId) {
      sharedPackComponents(input: $input) {
        component
        order
        prefix
        code
        existingLabels
      }
    }
  }
`;
