import React, { useContext, useState, useEffect, useMemo } from "react";
import { filter, lensProp, map, pipe, prop, set } from "ramda";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Icon, Card, Select, CardState } from "@foris/avocado-ui";
import { BookingContext } from "../../../context/BookingContext";
import { ContextApp } from "@config/Context/contextApp";
import { Types } from "../../../context/search.reducer";
import { Types as RequestTypes } from "../../../context/request.reducer";
import { Types as SavedRequestTypes } from "../../../context/savedRequest.reducer";
import { IParams } from "@models/IParams";
import { DayRowData } from "@modules/booking/context/types";
import { ClassroomBooking, Holiday } from "@models/ISchema";
import cx from "classnames";
import dayjs from "dayjs";
import SingleEvent from "./SingleEvent/SingleEvent";
import RecurrentEvent from "./RecurrentEvent/RecurrentEvent";
import {
  getSingleEventSessions,
  getUniqueRecurrentSessions,
} from "@modules/booking/utils/classroomEvents";
import { useGetHolidays } from "@modules/sections/hooks/useGetHolidays";
import css from "./search.module.scss";

const RECURRENCE_DEFAULT = {
  index: 1,
  recurrence: false,
  day: {
    dateValue: null,
    value: null,
    error: false,
  },
  start: {
    value: null,
    error: false,
  },
  end: {
    value: null,
    error: false,
  },
};

const SearchClassRoom = () => {
  const history = useHistory();
  const {
    origin: originId,
    scenario: scenarioId,
    workspace: workspaceId,
    id: bookingId,
  }: IParams = useParams();

  const editing = Boolean(bookingId);

  const [{ holidays, isLoading: isLoadingHolidays }, getHolidays] = useGetHolidays({
    scenario: scenarioId,
  });
  const { state, dispatch } = useContext(BookingContext);
  const savedRecurrence = state?.savedRequest?.editedBooking?.isRecurrent;
  const { user } = useContext(ContextApp);
  const [optionValue, setOptionValue] = useState(
    editing || ![null, undefined].includes(savedRecurrence)
      ? savedRecurrence ?? state?.search?.currentBooking?.isRecurrent
        ? { label: "Evento recurrente", value: "recurrent_event" }
        : { label: "Evento único", value: "single_event" }
      : null,
  );
  const [hasEventCollissions, setHasEventCollisions] = useState(false);
  const [shouldShowSelectors, setShouldShowSelectors] = useState(false);

  const booking = state?.search?.currentBooking;
  const contextUrl = `${workspaceId}/${scenarioId}/${originId}`;
  const listUrl = `/booking/list/${contextUrl}`;
  const editionUrl = `/booking/detail/${contextUrl}/${bookingId}`;
  const options = [
    { label: "Evento único", value: "single_event" },
    { label: "Evento recurrente", value: "recurrent_event" },
  ];

  if (!booking && editing) {
    history.push(editionUrl);
  }

  const dataValidator = () => {
    let peopleValidator = true;
    let dateTimeValidator = true;
    let programValidator = true;

    if (!state?.search?.requirements?.people?.count) {
      const cloneRequirements = { ...state?.search?.requirements };
      cloneRequirements.people.error = true;
      dispatch({ type: Types.SetRequirements, payload: cloneRequirements });
      peopleValidator = false;
    }

    const cloneDateTime = [];
    let setDate = false;
    state?.search?.dateTime?.forEach(value => {
      const { day, start, end } = value;
      const cloneItem = { ...value };
      if (!day.value) cloneItem.day.error = true;
      if (!start.value) cloneItem.start.error = true;
      if (!end.value) cloneItem.end.error = true;
      if ((!day.value || !start.value || !end.value) && !setDate) {
        setDate = true;
      }
      cloneDateTime.push(cloneItem);
    });

    if (setDate) {
      dispatch({ type: Types.SetDateTime, payload: cloneDateTime });
      dateTimeValidator = false;
    }

    const program = state?.search?.preferences?.program?.value;
    if (!user?.abilities?.can_skip_program_selection && !program) {
      const clonePreferences = { ...state?.search?.preferences };
      clonePreferences.program = {
        label: null,
        value: null,
        error: true,
      };

      dispatch({ type: Types.SetPreferences, payload: clonePreferences });
      programValidator = false;
    }

    return peopleValidator && dateTimeValidator && programValidator;
  };

  const calculateSessions = (newEditedBooking: ClassroomBooking) => {
    // sometimes the state?.search?.dateTimes' `value` are an actual date.
    // We have to consider it to build the recurrent sessions correctly
    const getDayId = (dayValue: any) => {
      const isNumeric = (value: string) => /^-?\d+$/.test(value);
      if (isNumeric(dayValue)) return dayValue;
      const dayId = dayjs(dayValue).day();
      return dayId === 0 ? "7" : dayId.toString();
    };
    const formatDate = (date: Date, time: string) => {
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      return `${year}-${month}-${day} ${time}:00`;
    };
    const isSameDay = (date: Date, dayId: string) => {
      const dateDayId = date.getDay().toString();
      return dateDayId === "0" ? dayId === "7" : dateDayId === dayId;
    };

    const updatedDates = [];

    if (newEditedBooking?.isRecurrent) {
      const selectedWeeks = filter(prop("selected"), state?.search?.intervals ?? []);
      const events = (state?.request?.editedBooking?.sessions ?? [])[0]?.events;
      const dates = state?.search?.dateTime;

      const sessions = [];
      selectedWeeks?.forEach(week => {
        const start = new Date(week?.startingDate);
        const end = new Date(week?.endingDate);

        dates.forEach(date => {
          const dayId = getDayId(date?.day?.value);
          const currDate = new Date(start?.getTime());

          if (!dayId || !currDate) return;

          // move forward one day at a time until we reach the target day
          while (!isSameDay(currDate, dayId)) {
            if (isNaN(dayId)) break;
            currDate.setDate(currDate.getDate() + 1);
          }

          // keep adding one session per week for the defined day/start/end
          // until the `end` date is reached
          while (currDate <= end) {
            const start = formatDate(currDate, date?.start?.value?.value);
            const end = formatDate(currDate, date?.end?.value?.value);
            const formattedStart = start?.split(" ")[0];

            updatedDates.push({
              ...date,
              day: {
                ...date?.day,
                dateValue: formattedStart,
                isHoliday: formattedStart in holidays,
              },
            });

            sessions.push({
              events,
              scheduleEvent: [
                {
                  start,
                  end,
                },
              ],
            });
            // move a week forward
            currDate?.setDate(currDate?.getDate() + 7);
          }
        });
      });

      if (updatedDates?.length) {
        dispatch({ type: Types.SetDateTimeFormatted, payload: updatedDates });
      }

      return sessions;
    } else {
      const dates = state?.search?.dateTime ?? [];
      const events = (state?.search?.currentBooking?.sessions ?? [])[0]?.events;
      const sessions = map((date: any) => {
        updatedDates.push({
          ...date,
          day: {
            ...date?.day,
            dateValue: date?.day?.value,
            isHoliday: date?.day?.value in holidays,
          },
        });

        return {
          events,
          scheduleEvent: [
            {
              start: `${date?.day?.value} ${date?.start?.value?.value}:00`,
              end: `${date?.day?.value} ${date?.end?.value?.value}:00`,
            },
          ],
        };
      }, dates);

      if (updatedDates?.length) {
        dispatch({ type: Types.SetDateTimeFormatted, payload: updatedDates });
      }

      return sessions;
    }
  };

  const handleUpdateRecurrence = (option: { label: string; value: string }) => {
    const defaultObj = { ...RECURRENCE_DEFAULT };

    setOptionValue(option);
    dispatch({
      type: Types.SetRecurrence,
      payload: {
        recurrence: option?.value === "recurrent_event",
        shouldResetDateTime: !editing,
      },
    });

    if (optionValue?.value === "single_event" && state?.search?.recurrence) {
      defaultObj.day.value = dayjs(new Date())
        .format("YYYY-MM-DD")
        .toString();
      dispatch({ type: Types.SetDateTime, payload: [defaultObj] });
    }
    if (optionValue?.value === "recurrent_event" && !state?.search?.recurrence) {
      defaultObj.day.value = "1";
      defaultObj.recurrence = true;
      dispatch({ type: Types.SetDateTime, payload: [defaultObj] });
    }
  };

  useEffect(() => {
    if (!Object.keys(holidays).length && !isLoadingHolidays) {
      getHolidays();
    }
  }, []);

  useEffect(() => {
    const booking = state?.savedRequest?.editedBooking ?? state?.search?.currentBooking;

    if (booking) {
      const sessions = [...(booking?.sessions || [])];
      const parser =
        optionValue?.value === "single_event" ? getSingleEventSessions : getUniqueRecurrentSessions;
      const datetime = parser(sessions);

      dispatch({ type: Types.SetDateTime, payload: datetime as DayRowData[] });
    }

    setShouldShowSelectors(true);
  }, [state?.search?.currentBooking]);

  useEffect(() => {
    const newEditedBooking = pipe(
      set(lensProp<ClassroomBooking>("isRecurrent"), Boolean(state?.search?.recurrence)),
      set(lensProp<ClassroomBooking>("sessions"), calculateSessions(state?.request?.editedBooking)),
      set(lensProp<ClassroomBooking>("capacity"), state?.search?.requirements?.people?.count),
    )(state?.request?.editedBooking);
    dispatch({ type: RequestTypes.SetEditedBooking, payload: newEditedBooking });
  }, [
    state?.search?.recurrence,
    state?.search?.dateTime,
    state?.search?.requirements?.people?.count,
    state?.search?.intervals,
  ]);

  const handleSearchClassroom = () => {
    if (!dataValidator()) return;

    dispatch({
      type: SavedRequestTypes.setSaveEditedBooking,
      payload: state?.request?.editedBooking,
    });
    dispatch({
      type: SavedRequestTypes.setSavedBookingCapacity,
      payload: state?.search?.requirements?.people?.count,
    });
    dispatch({
      type: SavedRequestTypes.setSavedBookingPreferences,
      payload: state?.search?.preferences,
    });
    dispatch({
      type: SavedRequestTypes.setSavedRecurrence,
      payload: state?.search?.recurrence,
    });
    dispatch({ type: Types.SetView, payload: "result" });
  };

  const handleClearState = () => {
    dispatch({ type: Types.ClearSearch, payload: null });
    dispatch({ type: RequestTypes.ClearRequest, payload: null });
    dispatch({ type: SavedRequestTypes.clearSavedData, payload: null });
  };

  const selectedHolidays = useMemo(() => {
    const sessions = [...(state?.request?.editedBooking?.sessions || [])];
    const filteredHolidaysSessions = {};

    sessions?.forEach(session => {
      session?.scheduleEvent?.forEach(({ start, end }) => {
        const date = (start || "").split(" ")[0];

        if (date in holidays && !end.includes("undefined")) {
          filteredHolidaysSessions[date] = holidays[date];
        }
      });
    });

    return Object.values(filteredHolidaysSessions);
  }, [state?.request?.editedBooking?.sessions]);

  return (
    <section className={cx(css.cntSearchClassRoom, "container-row")}>
      <Link
        to={editing ? editionUrl : listUrl}
        className={cx(css.cntSearchClassRoom_link, "col_12")}
        onClick={handleClearState}
      >
        <Icon icon="chevron-left" size={24} className={css.iconBack} />
        Buscar sala para tu evento
      </Link>
      <Card.Simple className={cx(css.cardSearchClassRoom, "container-row", "col_8", "col_sm_12")}>
        <Card.Content className={cx(css.cardContent, "col_12")}>
          <section className={cx(css.cardBlock)}>
            <p className={cx(css.cardBlock_text, "col_12")}>¿Cuándo quieres reservar? *</p>
            <Select
              label="Recurrencia"
              className="col_4 col_sm_12"
              placeholder="Selecciona una opción"
              options={options}
              value={optionValue}
              onChange={handleUpdateRecurrence}
            />
          </section>
          {optionValue?.value === "single_event" && shouldShowSelectors && (
            <SingleEvent
              onCollission={setHasEventCollisions}
              selectedHolidays={selectedHolidays as Holiday[]}
            />
          )}
          {optionValue?.value === "recurrent_event" && shouldShowSelectors && (
            <RecurrentEvent
              isEditing={editing}
              onCollission={setHasEventCollisions}
              selectedHolidays={selectedHolidays as Holiday[]}
            />
          )}
          {optionValue?.value && (
            <p className={cx(css.cardContent_required, "col_12")}>* Secciones obligatorias</p>
          )}
        </Card.Content>
      </Card.Simple>

      {hasEventCollissions && (
        <CardState
          title="Error"
          typeCard="error"
          className={cx(css.cardError, "col_8", "col_md_12")}
        >
          <p className={css.cardError_text}>
            Existen choques entre los eventos que se están configurando para la reserva. Corrija
            este error para poder seguir avanzando en el flujo
          </p>
        </CardState>
      )}

      <footer className={cx(css.footerSearch, "container-row", "row_sm--between")}>
        <Button
          variant={"outline"}
          className={cx(css.footerSearch_btn, "col_sm", "col_xs_12")}
          onClick={() => {
            handleClearState();
            history.push(editing ? editionUrl : listUrl);
          }}
        >
          Cancelar
        </Button>
        <Button
          className={cx(css.footerSearch_btn, "col_sm", "col_xs_12")}
          disabled={hasEventCollissions || !optionValue?.value}
          onClick={handleSearchClassroom}
        >
          Buscar sala
        </Button>
      </footer>
    </section>
  );
};

export default SearchClassRoom;
