import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { Select } from "@foris/avocado-ui";
import { contractTypeQuery } from "../graphql/instructor.queries";
import { InstructorContractType, DataQuery } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { ISelectorReturn, IOption } from "./ISelectors";
import css from "./selectors.module.scss";

const ContractTypeSelect: React.FC<ISelectorReturn> = (props: ISelectorReturn) => {
  const client = useApolloClient();
  const { onCallback, label, className, placeholder } = props;
  const { scenario }: IParams = useParams();
  const [optionsContract, setOptionsContract] = useState([]);
  const [valueContract, setValueContract] = useState(null);

  const variablesContract = {
    query: contractTypeQuery,
    variables: {
      scenarioId: scenario,
    },
  };

  const setContractOptions = async (data: any) => {
    if (data && data.data) {
      const dataQuery: DataQuery = data.data.data;
      const optContractType = [];
      if (dataQuery.instructorContractTypes.items.length > 0) {
        dataQuery.instructorContractTypes.items.forEach((contracType: InstructorContractType) => {
          optContractType.push({
            label: `${contracType.code} - ${contracType.name}`,
            value: contracType.id,
          });
        });
        setOptionsContract(optContractType);
      }
    }
  };

  const getContractOptions = async () => {
    try {
      const data = await client.query(variablesContract);
      await setContractOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCacheContractOptions = async () => {
    try {
      const data = await client.readQuery(variablesContract);
      await setContractOptions(data);
    } catch (error) {
      getContractOptions();
    }
  };

  useEffect(() => {
    if (optionsContract.length === 0) {
      getCacheContractOptions();
    }
  });

  let placeholderLabel = "Seleccionar";
  if (!label) placeholderLabel = "Tipos de contrato";
  if (placeholder) placeholderLabel = placeholder;

  return (
    <div className={cx(css.selectors, className)}>
      {label && <label className={css.selectors_labelItem}>Tipos de contrato</label>}
      <Select
        options={optionsContract}
        placeholder={placeholderLabel}
        isClearable
        value={valueContract}
        onChange={(value: IOption) => {
          if (value) {
            setValueContract(value);
            onCallback(value);
          } else {
            setValueContract("");
            onCallback(value);
          }
        }}
      />
    </div>
  );
};

export default ContractTypeSelect;
