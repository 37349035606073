import { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "react-apollo";
import { Group } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { GroupContext } from "../context/GroupContext";
import { Types } from "../context/groupInfo.reducer";
import updateGroupTextMutationGraphql from "../graphql/updateGroupText.mutation.graphql";

const useUpdateGroupText = (): [
  { data: Group; error: boolean; isLoading: boolean },
  (
    groupId: string,
    texts: string[],
  ) => Promise<{
    data: any;
    errors: any;
  }>,
] => {
  const { scenario, origin }: IParams = useParams();
  const { dispatch } = useContext(GroupContext);
  const [updateGroupTextMutation, { loading }] = useMutation(updateGroupTextMutationGraphql, {});
  const [result, setResult] = useState<Group>(null);
  const [error, setError] = useState(false);

  const submitUpdateText = async (groupId: string, texts: string[]) => {
    try {
      const variables = {
        scenarioId: scenario,
        originId: origin,
        input: { dryRun: false, groupId, texts },
      };
      const { data, errors } = await updateGroupTextMutation({ variables });
      const group = data?.cube?.editGroupTexts?.group;
      setResult(group);

      dispatch({
        type: Types.SetGroupInfo,
        payload: group,
      });
      return { data, errors };
    } catch (e) {
      setError(true);
      console.error("error");
    }
  };

  return [{ data: result, error, isLoading: loading }, submitUpdateText];
};

export default useUpdateGroupText;
