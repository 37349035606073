import React, { useState } from "react";
import cx from "classnames";
import Input from "../../../../common/components/Input/Input";
import css from "./blocks.module.scss";

export interface IBlocksValues {
  minBlock: string;
  maxBlock: string;
}

export interface ICapacityReturn {
  onCallback: (value: IBlocksValues, error?: boolean) => void;
  className?: string;
}

const BlocksInput: React.FC<ICapacityReturn> = (props: ICapacityReturn) => {
  const { onCallback, className } = props;
  const [error, setError] = useState(false);
  const [minBlock, setMinBlock] = useState("");
  const [maxBlock, setMaxBlock] = useState("");

  const handleError = (min: string, max: string) => {
    if (parseInt(min) > parseInt(max)) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  return (
    <div className={css.sharedBlocks}>
      <div className={css.sharedBlocks_grid}>
        <div className={cx(css.sharedBlocks_control, className)}>
          <Input
            type="number"
            placeholder="Min"
            error={error}
            value={minBlock}
            onChange={value => {
              if (value >= 0) {
                setMinBlock(value);
                const error = handleError(value, maxBlock);
                onCallback(
                  {
                    minBlock: value,
                    maxBlock: maxBlock,
                  },
                  error,
                );
              }
            }}
          />
        </div>

        <span className={css.sharedBlocks_separator}>-</span>

        <div className={cx(css.sharedBlocks_control, className)}>
          <Input
            type="number"
            placeholder="Max"
            error={error}
            value={maxBlock}
            onChange={value => {
              if (value >= 0) {
                setMaxBlock(value);
                const error = handleError(minBlock, value);
                onCallback(
                  {
                    minBlock: minBlock,
                    maxBlock: value,
                  },
                  error,
                );
              }
            }}
          />
        </div>
      </div>

      {error && (
        <p className={css.sharedBlocks_error}>
          El bloque mínimo no puede ser mayor al bloque máximo.
        </p>
      )}
    </div>
  );
};

export default BlocksInput;
