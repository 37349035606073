import React from "react";
import css from "./groups.module.scss";

export interface IHeaderGroupProps {
  indexList: number;
}

const HeaderGroup: React.FC<IHeaderGroupProps> = (props: IHeaderGroupProps) => {
  const { indexList } = props;

  return (
    <ul className={css.headerList} key={`header-list-${indexList}`}>
      <li className={css.headerList_item}>Activo</li>
      <li className={css.headerList_item}>Visible</li>
      <li className={css.headerList_item}>Grupo | Cod. cliente</li>
      <li className={css.headerList_item}>Código Sección</li>
      <li className={css.headerList_item}>Periodo</li>
      <li className={css.headerList_item}>Vacantes</li>
      <li className={css.headerList_item}>Inscritos</li>
      <li className={css.headerList_item}>Lista espera</li>
      <li className={css.headerList_item}>Sede</li>
      <li className={css.headerList_item}>Carrera</li>
      <li className={css.headerList_item}>Currículo</li>
      <li className={css.headerList_item}>Asignatura</li>
    </ul>
  );
};

export default HeaderGroup;
