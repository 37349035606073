import React, { useState } from "react";
import Checkbox from "../../../common/components/Checkbox/Checkbox";
import css from "./checks.module.scss";

export interface IFictionalProps {
  onCallback: (value: boolean) => void;
}

const FictionalCheckbox: React.FC<IFictionalProps> = (props: IFictionalProps) => {
  const [fictionalCheck, setFictionalCheck] = useState(false);
  return (
    <div className={css.sharedCheckbox}>
      <Checkbox
        label="No considerar docentes ficticios"
        className={css.sharedCheckbox_field}
        check={fictionalCheck}
        onClick={() => {
          setFictionalCheck(!fictionalCheck);
          props.onCallback(!fictionalCheck);
        }}
      />
    </div>
  );
};

export default FictionalCheckbox;
