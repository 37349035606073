import React from "react";
import { Option } from "react-select/lib/filters";
import AdvanceSelector from "../advance-selector";
import useGetProgramOptions from "@modules/shared/selectors/hooks/useGetProgramOptions";

interface AdvancedProgramSelectorProps {
  scenario: string;
  campusId?: string;
  departmentId?: string;
  modalityId?: string;
  shiftId?: string;
  onChange?: (option: Option) => void;
}

const AdvancedProgramSelector = ({
  scenario,
  onChange,
  ...filters
}: AdvancedProgramSelectorProps) => {
  const { loadProgramOptions } = useGetProgramOptions({
    scenario,
    ...filters,
  });

  return (
    <AdvanceSelector
      isPaginated
      dimension="Carrera"
      selectPlaceholder="Selecciona una carrera"
      loadOptions={loadProgramOptions}
      onChange={onChange}
    />
  );
};

export default AdvancedProgramSelector;
