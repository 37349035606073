import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import cx from "classnames";
import { CourseMultiKey } from "../../../../shared/selectors";
import TermSelect from "../../../../../modules/vacancies/Groups/TermSelect";
import Button from "../../../../../common/components/Button/Button";
import CampusSelect from "@modules/shared/selectors/Selectors/Campus";
import ModalitySelect from "@modules/shared/selectors/Selectors/Modality";
import ProgramSelect from "@modules/shared/selectors/Selectors/Program";
import { RegularOption } from "@foris/avocado-ui/lib/types/models/GenericProps";
import css from "./selectors.module.scss";
import GroupCodeInput from "@modules/vacancies/Groups/group-code-input";
import { Checkbox, Input } from "@foris/avocado-ui";
import { ContextEDH } from "@context/ContextEDH";

interface ISelectorsReturn {
  idCampus: string;
  idDepartment: string;
  idModality: string;
  idShift: string;
  objLevel: any;
  idProgram: string;
  idCourse: string;
  idTerm: string;
  idCurriculum: string;
  groupCode?: string;
  groupVacancies?: string;
}

interface ISelectorsProps {
  onReturn: (vallue: ISelectorsReturn) => void;
}

const Selectors: React.FC<ISelectorsProps> = (props: ISelectorsProps) => {
  const history = useHistory();
  const { onReturn } = props;
  const { state } = useContext(ContextEDH);
  const [idTerm, setIdTerm] = useState(null);
  const [objCourse, setObjCourse] = useState<ISelectorsReturn>(null);
  const [selectedCampus, setSelectedCampus] = useState("");
  const [selectedModality, setSelectedModality] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [groupCode, setGroupCode] = useState("");
  const [groupCodeErrors, setGroupCodeErrors] = useState([]);
  const [groupVacancies, setGroupVacancies] = useState("");
  const [showGroupForm, setShowGroupForm] = useState(false);

  const isRequiredFieldsSelected = !!(selectedCampus || selectedModality || selectedProgram);
  const courseKey = useMemo(() => `${selectedCampus}-${selectedModality}-${selectedProgram}`, [
    selectedCampus,
    selectedModality,
    selectedProgram,
  ]);

  const isGroupFormInvalid =
    (showGroupForm && (!groupCode || !groupVacancies)) || !!groupCodeErrors?.length;

  const groupValidationErrors = useMemo(() => {
    const errors = [];
    const warnings = [];

    groupCodeErrors.forEach(error => {
      if (error.type === "ERROR") {
        errors.push(error);
      } else {
        warnings.push(error);
      }
    });

    return {
      errors,
      warnings,
    };
  }, [groupCodeErrors]);

  const handleSubmit = () => onReturn({ ...objCourse, idTerm, groupCode, groupVacancies });

  const setTerm = (values: any) => {
    if (values.term) {
      setIdTerm(values.term);
    }
  };

  useEffect(() => {
    if (state?.base?.createGroupWithinLinkCreation === "MANDATORY") {
      const abilities = state?.base?.base?.user?.abilities;

      setShowGroupForm(true);
      (!abilities?.can_create_groups_in_bundle_view || !abilities?.can_edit_links_and_sections) &&
        history?.goBack();
    }
  }, [state?.base?.createGroupWithinLinkCreation]);

  return (
    <>
      <section className={css.contentAddSection}>
        <div className={css.contentAddSection_groupFields}>
          <CampusSelect
            className={css.field}
            label
            onCallback={(campus: RegularOption) => setSelectedCampus(campus?.value || "")}
          />
          <ModalitySelect
            className={css.field}
            label
            shouldHasDepartment={false}
            onCallback={(modality: RegularOption) => setSelectedModality(modality?.value || "")}
          />
          <ProgramSelect
            className={css.field}
            label
            campus={selectedCampus}
            modality={selectedModality}
            shouldHaveFilters={false}
            filterBy="modality"
            onCallback={(program: RegularOption) => setSelectedProgram(program?.value || "")}
          />
        </div>

        <div className={css.contentAddSection_columFields}>
          <CourseMultiKey
            key={courseKey}
            label
            onCallback={newSelectorReturn => setObjCourse(newSelectorReturn)}
            className={css.field}
            campusId={selectedCampus}
            modalityId={selectedModality}
            programId={selectedProgram}
            isDisabled={!isRequiredFieldsSelected}
          />
        </div>

        <div className={css.contentAddSection_columFields}>
          <TermSelect sendValues={(values: any) => setTerm(values)} />
        </div>

        {state?.base?.createGroupWithinLinkCreation === "OPTIONAL" &&
          state?.base?.base?.user?.abilities?.can_create_groups_in_bundle_view && (
            <Checkbox
              labelRight="Crear grupo para Liga (opcional)"
              checked={showGroupForm}
              onChange={e => setShowGroupForm(e.target.checked)}
            />
          )}

        {state?.base?.createGroupWithinLinkCreation === "MANDATORY" && (
          <h3 className={css.contentAddSection_sectionTitle}>Crear grupo para Liga</h3>
        )}

        {showGroupForm && (
          <div className={css.contentAddSection_columFields}>
            <GroupCodeInput onChange={setGroupCode} onError={setGroupCodeErrors} />

            <Input
              label="Vacantes"
              placeholder="Número vacantes"
              type="number"
              value={groupVacancies}
              onChange={event => {
                setGroupVacancies(event.target.value);
              }}
            />
          </div>
        )}

        {groupCodeErrors.length > 0 && (
          <ul className={css.contentAddSection_validations}>
            {groupValidationErrors?.warnings?.map((validation, index) => (
              <li key={index} className={css.validationItem}>
                {validation.message}
              </li>
            ))}
            {groupValidationErrors?.errors?.map((validation, index) => (
              <li key={index} className={cx(css.validationItem, css.validationItem__error)}>
                {validation.message}
              </li>
            ))}
          </ul>
        )}
      </section>

      <footer className={cx(css.footer, "container-row")}>
        <Link to={`/home`} className={cx(css.footer_link)}>
          <Button color="transparent" className={cx(css.footer_btn)}>
            Cancelar
          </Button>
        </Link>
        <Button
          className={cx(css.footer_btn)}
          onClick={handleSubmit}
          disabled={!objCourse || !idTerm || !isRequiredFieldsSelected || isGroupFormInvalid}
        >
          Seguir
        </Button>
      </footer>
    </>
  );
};

export default Selectors;
