import React from "react";
import { SelectPagination } from "@foris/avocado-ui";
import useGetLabelsCategoriesOptions from "../../hooks/useGetLabelsCategoriesOptions";

interface LabelCategorySelectorProps {
  scenario: string;
  onChange?: (categoryId: string) => void;
}

const LabelCategorySelector = ({ scenario, onChange }: LabelCategorySelectorProps) => {
  const { loadOptions } = useGetLabelsCategoriesOptions({
    scenario,
  });

  return (
    <SelectPagination
      loadOptions={loadOptions}
      placeholder="Seleccionar tipo de etiqueta"
      noOptionsMessage={() => <>Sin opciones</>}
      onChange={option => onChange?.(option?.value)}
    />
  );
};

export default LabelCategorySelector;
