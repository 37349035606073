import { useState, useContext } from "react";
import * as R from "ramda";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { CubeQuery, Link, Section } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { AppContext } from "../context/EditSessionsContext";
import { Types, Week } from "../context/linkData.reducer";
import { linkInfo } from "../graphql/linkInfo.query";
import sectionAdapter from "../adapters/sections";

export const useGetLink = (): [Link, () => void] => {
  const { id, origin, scenario }: IParams = useParams();
  const { dispatch } = useContext(AppContext);
  const client = useApolloClient();
  const [dataInfo, setDataInfo] = useState<Link>(null);

  const variables = {
    resourceId: id,
    scenarioId: scenario,
    originId: origin,
    resourceType: "LINK",
  };

  const getLinkInfo = async () => {
    try {
      const { data, errors } = await client.query({
        query: linkInfo,
        variables,
        fetchPolicy: "no-cache",
      });
      if (errors) {
        console.debug("[LinkHeader error]", errors);
        return null;
      }
      const cubeQuery: CubeQuery = data.cube;
      const cubeInfo = cubeQuery.editorView.info;
      const info: Link = cubeInfo.__typename === "LinkInfo" ? cubeInfo.link : null;
      const weeks: Week[] = [];
      cubeQuery.editorWeeks.forEach((item, index) => {
        weeks.push({ ...item, value: index + 1 });
      });
      const sections: Section[] = R.map(
        sectionAdapter(weeks),
        cubeQuery?.editorView?.sections ?? [],
      );

      if (info) {
        dispatch({ type: Types.InfoLink, payload: info });
        dispatch({ type: Types.SetAssignedWeeksByComponent, payload: info });
        dispatch({ type: Types.SectionLink, payload: sections });
        dispatch({ type: Types.WeeksLink, payload: weeks });
        setDataInfo(info);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return [dataInfo, getLinkInfo];
};
