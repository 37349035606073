import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { every } from "lodash";
import { Creatable, Tag, Icon, Loading } from "@foris/avocado-ui";
import cx from "classnames";
import { GroupContext } from "../../context/GroupContext";
import { IParams } from "@models/IParams";
import useUpdateGroupText from "../../hooks/useUpdateGroupText";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import css from "./reference.module.scss";

export type ReferenceType = {
  value: string;
  label: string;
};

const Reference: React.FC = () => {
  const { id }: IParams = useParams();
  const { state } = useContext(GroupContext);
  const context: IContextApp = useContext(ContextApp);
  const [{ isLoading }, updateGroupText] = useUpdateGroupText();
  const [references, setReferences] = useState<ReferenceType[]>([]);
  const [hideSelector, setHideSelector] = useState(false);
  const [changeFromSelector, setChangeFromSelector] = useState(false);

  const defaultReferences = state?.info?.info?.text?.map((ref: any) => ref.replaceAll("'", ""));

  const [userPermissions] = [context?.user?.permissions, context?.user?.abilities];
  const userCanEdit = userPermissions?.update;
  const userCanCreate = userPermissions?.create;
  const isDisabled = !(userCanEdit && userCanCreate);
  let isMounted = true;
  const ALLOWED_NUMBER_OF_CHARS = 60;

  const buttonChildren = () => {
    return (
      <label>
        <Icon icon="plus" size={16} />
      </label>
    );
  };

  useEffect(() => {
    const refs = defaultReferences?.map(ref => ({ value: ref, label: ref }));
    setReferences(refs);
  }, []);

  const somethingChanged = (prevs: string[], curr: string[]) => {
    if (!prevs || !curr || prevs.length !== curr.length) return true;
    const prevTable = prevs.reduce((table, ref) => {
      table[ref] = true;
      return table;
    }, {});
    return curr.reduce((prev, ref) => {
      if (prev || !(ref in prevTable)) return true;
      return false;
    }, false);
  };

  useEffect(() => {
    if (isMounted) {
      (async () => {
        const pattern = /(')|(`)|(=)/i;
        const parsedRefs: string[] = references?.map(ref => ref.value);
        const allRefsAreValid = every(parsedRefs?.map(value => !pattern.test(value)));
        const isFirstRender = (defaultReferences?.length || 0) - (parsedRefs?.length || 0) > 1;

        if (
          !isFirstRender &&
          allRefsAreValid &&
          somethingChanged(defaultReferences, parsedRefs) &&
          changeFromSelector
        ) {
          updateGroupText(id, parsedRefs);
          setHideSelector(!hideSelector);
        }
      })();
    }

    return () => (isMounted = false) as any;
  }, [references]);

  return (
    <section className={cx(css.cntReference)}>
      {isLoading && <Loading />}
      <div className={css.cntReference_actions}>
        <span className={css.actionsTitle}>Referencias</span>

        <button className={cx(css.actionsButton)} onClick={() => setHideSelector(!hideSelector)}>
          <label className={cx(css.actionsButton_label)}>
            <Icon icon="file-edit" size={12} className="icon" /> Editar
          </label>
        </button>
      </div>

      {hideSelector ? (
        <Creatable
          buttonChildren={buttonChildren()}
          isMulti={true}
          isDisabled={isDisabled}
          className={cx(css.creatable)}
          charValidation={(_, inputValue: string) => inputValue.length < ALLOWED_NUMBER_OF_CHARS}
          onChange={refs => {
            setChangeFromSelector(true);
            setReferences(refs);
          }}
          placeholder="Ingresa una referencia..."
          defaultValues={defaultReferences}
        />
      ) : (
        <section className={cx(css.tagsSection, "container-row")}>
          {references?.map((ref: ReferenceType) => (
            <Tag key={ref.value} type="outlined">
              <p>{ref.label}</p>
            </Tag>
          ))}
        </section>
      )}
    </section>
  );
};

export default Reference;
