import gql from "graphql-tag";
import { FRAGMENT_SECTIONS } from "../../graphql/section.fragment";

export const instructorSection = gql`
  query instructorSection(
    $scenarioId: ID!
    $originId: ID!
    $resourceType: ResourceType!
    $resourceId: ID!
  ) {
    base {
      customFields(resourceType: $resourceType) {
        id
        code
        name
        label
      }
    }
    cube(scenarioId: $scenarioId, originId: $originId) {
      editorView(resourceType: $resourceType, resourceId: $resourceId) {
        info {
          __typename
          ... on InstructorInfo {
            instructor {
              id
              code
              name
              contract
              priority
              minBlocks
              maxBlocks
              maxSections
              maxCourseComponents
              minCourses
              contractType {
                id
                code
                name
              }
              assignment {
                currentYearBlocks
                nextYearBlocks
                totalAssignedBlocks
                totalAssignedBlocksWithBTR
                totalRemainingBlocks
                totalRemainingBlocksCurrentYear
                totalRemainingBlocksNextYear
              }
              customFields {
                id
                value
                code
              }
            }
            assignedBlocks
            assignedSections
            assignedCourses
          }
        }
        ...CalendarSections
      }
      editorWeeks(resourceType: $resourceType, resourceId: $resourceId) {
        id
        name
        startingDate
        endingDate
        eventCount
        termParts {
          id
          code
          name
          category {
            id
            code
            name
          }
        }
      }
      editorRestrictions(resourceType: $resourceType, resourceId: $resourceId) {
        type
        scheduleEvent {
          id
          dayId
          start
          end
        }
      }
    }
  }
  ${FRAGMENT_SECTIONS}
`;
