import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { Loading } from "@foris/avocado-ui";
import { linkValidation, IObjValidationError } from "./utils";
import {
  IContextVacancies,
  ContextVacancies,
  IGroupValue,
} from "./contextVacancies/ContextVacancies";
import Footer from "./Footer";
import Links from "./Links/Links";
import { BUNDLES_MUTATION } from "./graphql/bundles.queries";
import {
  Bundle,
  CubeMutation,
  BundleValidationError,
  EditBundlePayload,
  Link,
  Group,
} from "../../models/ISchema";
import { IParams } from "../../models/IParams";
import css from "./vacancies.module.scss";
import "react-tabs/style/react-tabs.css";

export enum VacanciesType {
  instructor = "instructor",
  vacancies = "vacancies",
}

interface IVacanciesGroup {
  active: boolean;
  setActive: (value: boolean) => void;
  dataBundle: Bundle;
}

const VacanciesGroup: React.FC<IVacanciesGroup> = (props: IVacanciesGroup) => {
  const { active, setActive, dataBundle } = props;
  const { origin, scenario, id }: IParams = useParams();
  const [vacanciesMutation] = useMutation(BUNDLES_MUTATION, {});
  const [loading, setLoading] = useState(false);
  const context: IContextVacancies = useContext(ContextVacancies);
  const [validationArray, setValidationArray] = useState(null);
  const [disabledConfirm, setDisabledConfirm] = useState(true);

  const warningArray = validationArray
    ? validationArray.filter((validation: IObjValidationError) => validation.type === "warning")
    : [];

  const handleOnSaveVacancies = async (commit: boolean) => {
    try {
      setLoading(true);
      const changeVacancies = [];
      if (context.changeValues) {
        context.changeValues.forEach(value => {
          if (value.length > 0) {
            const objLink = {
              linkId: parseInt(value[0].linkId),
              changeGroupVacancies: [],
            };
            value &&
              value.forEach((group: IGroupValue) => {
                if (group.group.vacancies !== group.value) {
                  const objGroup = {
                    groupId: parseInt(group.group.id),
                    vacancies: group.value,
                  };
                  objLink.changeGroupVacancies.push(objGroup);
                }
              });
            if (objLink.changeGroupVacancies.length > 0) changeVacancies.push(objLink);
          }
        });
      }
      const objMutation = {
        scenarioId: parseInt(scenario),
        originId: parseInt(origin),
        bundleInput: {
          bundleId: parseInt(id),
          dryRun: !commit,
          skipValidations: commit,
          changeset: {
            changeVacancies,
          },
        },
      };
      if (!commit && changeVacancies.length > 0) {
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await callMutationHook(objMutation);
      } else if (changeVacancies.length > 0) {
        setActive(true);
        const data = await vacanciesMutation({ variables: objMutation });
        const dataMutation: EditBundlePayload = data.data.cube.editBundle;
        if (dataMutation.commited) {
          setLoading(false);
          setActive(false);
          window.location.reload();
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const callMutationHook = async (variables: any) => {
    try {
      setLoading(true);
      const data = await vacanciesMutation({ variables });
      const dataMutation: CubeMutation = data.data.cube;
      const validationErrors: Array<BundleValidationError> =
        dataMutation.editBundle.validationErrors;
      let errorsValidations = [];

      if (validationErrors && validationErrors.length > 0) {
        validationErrors.forEach((error: BundleValidationError) => {
          const errorLinks = linkValidation(error);
          errorsValidations = [...errorsValidations, ...errorLinks];
        });

        setValidationArray(errorsValidations);
      } else {
        handleOnSaveVacancies(true);
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const canEditSomeGroup = dataBundle
    ? dataBundle.links.some((link: Link) =>
        link.groups.some((group: Group) => group.course.canEdit),
      )
    : false;

  return (
    <div>
      {loading && <Loading />}
      <section className={cx(css.content, "container-row")}>
        <Links
          nameGroup={`${dataBundle.code} ${dataBundle.name}`}
          bundleId={dataBundle?.id}
          type={VacanciesType.vacancies}
          data={context.currentLeague}
          activeEdit={active}
          bundleTerm={dataBundle.term}
          validationArray={validationArray && validationArray.length > 0 ? validationArray : []}
          setValidationArray={setValidationArray}
          setDisabledConfirm={setDisabledConfirm}
        />
      </section>
      <Footer
        activeEdit={active}
        canEditSomeGroup={canEditSomeGroup}
        setActiveEdit={setActive}
        warningsCount={warningArray.length}
        onSave={handleOnSaveVacancies}
        disabledConfirm={disabledConfirm}
      />
    </div>
  );
};

export default VacanciesGroup;
