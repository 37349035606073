import React, { useCallback, useMemo, useState } from "react";
import { useApolloClient } from "react-apollo";
import { DataQuery, PageInfo, Program } from "@models/ISchema";
import { programsQuery } from "@modules/booking/graphql/programs.query";
import cx from "classnames";
import css from "../Selectors/selectors.module.scss";

interface UseGetProgramOptionsParams {
  scenario: string;
  campusId?: string;
  departmentId?: string;
  modalityId?: string;
  shiftId?: string;
}

const getProgramLabel = (program: Program) => {
  return (
    <section className={css.cntOptions}>
      <p className={css.cntOptions_title}>
        {`${program?.code} `}
        <span className={css.bold}>{program?.name}</span>
      </p>
      <div className={cx(css.detailOption, "container-row")}>
        <p className={css.detailOption_item}>
          SE: <span className={css.bold}>{program?.campus?.code || "-"}</span>
        </p>
        <p className={css.detailOption_item}>
          ES: <span className={css.bold}>{program?.department?.code || "-"}</span>
        </p>

        <p className={css.detailOption_item}>
          MO:
          <span className={css.bold}>{program?.modality?.code || "-"}</span>
        </p>

        <p className={css.detailOption_item}>
          JO: <span className={css.bold}>{program?.shift?.code || "-"}</span>
        </p>
      </div>
    </section>
  );
};

const useGetProgramOptions = ({
  scenario,
  campusId,
  departmentId,
  modalityId,
  shiftId,
}: UseGetProgramOptionsParams) => {
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  const [programPage, setProgramPage] = useState(1);
  const [termToSearch, setTermToSearch] = useState("");
  const [optionsProgram, setOptionsProgram] = useState(null);

  const variablesProgram = useMemo(() => {
    const hasSomeFilter = !!campusId || !!departmentId || !!modalityId || !!shiftId;

    const filters = {
      campusId: campusId ? { campusId: { eq: campusId } } : undefined,
      departmentId: departmentId ? { departmentId: { eq: departmentId } } : undefined,
      modalityId: modalityId ? { modalityId: { eq: modalityId } } : undefined,
      shiftId: shiftId ? { shiftId: { eq: shiftId } } : undefined,
    };

    return hasSomeFilter
      ? {
          fields: {
            and: Object.values(filters).filter(filter => !!filter),
          },
        }
      : {};
  }, [scenario]);

  const getPrograms = useCallback(
    async (searchTerm = "", page = 1) => {
      const optionsPerPage = 10;
      setIsLoading(true);

      try {
        const variables = {
          query: programsQuery,
          variables: {
            scenarioId: scenario,
            filter: {
              ...variablesProgram,
              pagination: {
                page,
                size: optionsPerPage,
                searchTerm,
              },
            },
          },
        };

        const { data } = await client.query(variables);
        const dataQuery: DataQuery = data?.data;
        const pageInfo: PageInfo = dataQuery?.programs?.pageInfo;
        const programs = dataQuery?.programs;

        const options = [];

        if (programs) {
          programs.items.forEach(program => {
            options.push({
              label: getProgramLabel(program),
              value: program.id,
            });
          });
        }

        setOptionsProgram(options);

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        return null;
      }
    },
    [client],
  );

  const loadProgramOptions = async (searchTerm: string) => {
    let searchPage = 1;

    if (termToSearch === searchTerm) {
      searchPage = programPage;
    } else {
      setTermToSearch(searchTerm);
    }
    const { pageInfo, options: programOptions } = await getPrograms(searchTerm, searchPage);
    setProgramPage(programPage + 1);
    return {
      options: programOptions,
      hasMore: pageInfo?.hasNextPage,
      additional: {
        page: programPage,
      },
    };
  };

  return {
    optionsProgram,
    isLoading,
    loadProgramOptions,
  };
};

export default useGetProgramOptions;
