import React, { useState } from "react";
import { days } from "../../../utils/days";
import css from "./checks.module.scss";
import { Checkbox } from "@foris/avocado-ui";

export interface IDaysReturn {
  required: boolean;
  onCallback: (value: number[]) => void;
}

const DaysCheck: React.FC<IDaysReturn> = (props: IDaysReturn) => {
  const { onCallback, required } = props;
  const [daysCheck, setDaysCheck] = useState([]);

  const setDays = (day: any, value: any) => {
    const newDaysCheck = [...daysCheck];
    if (value) {
      newDaysCheck.push(day + 1);
    } else {
      const index = newDaysCheck.indexOf(day + 1);
      if (index > -1) {
        newDaysCheck.splice(index, 1);
      }
    }
    setDaysCheck(newDaysCheck);
    onCallback(newDaysCheck);
  };

  return (
    <div className={css.sharedCheckbox}>
      <div className={css.sharedCheckbox_days}>
        {days.map((day, index) => (
          <Checkbox
            key={index}
            className={css.checkboxDay}
            onChange={value => setDays(index, value?.currentTarget?.checked)}
            labelRight={day}
          />
        ))}
      </div>

      {required && (
        <p className={css.sharedCheckbox_error}>
          Seleccionar días para los que se busca disponibilidad.
        </p>
      )}
    </div>
  );
};

export default DaysCheck;
