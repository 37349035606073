import React from "react";
import { Option } from "react-select/lib/filters";
import AdvanceSelector from "../advance-selector";
import useGetCampusOptions from "@modules/shared/selectors/hooks/useGetCampusOptions";

interface AdvanceCampusSelectorProps {
  scenario: string;
  onChange?: (option: Option) => void;
}

const AdvanceCampusSelector = ({ scenario, onChange }: AdvanceCampusSelectorProps) => {
  const { loadOptions } = useGetCampusOptions({ scenario });

  return (
    <AdvanceSelector
      isPaginated
      dimension="Sede"
      selectPlaceholder="Selecciona una sede"
      loadOptions={loadOptions}
      onChange={onChange}
    />
  );
};

export default AdvanceCampusSelector;
