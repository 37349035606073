import React from "react";
import { Switch, Route } from "react-router-dom";
import ProtectedRoute from "./protectedRoutes";
import ClassRoomContainer from "../../modules/dimensions/classroom/GridSections/ClassRoom";
import SearchClassRooms from "../../modules/dimensions/classroom/Search/SearchClassRooms";
import InstructorContainer from "../../modules/dimensions/instructor/GridSections/Instructor";
import SearchInstructor from "../../modules/dimensions/instructor/Search/SearchInstructor";
import Link from "@dimensions/link/pages/GridSection/GridSesctionLink";
import SearchLeague from "@dimensions/link/pages/SimpleSearch/SearchLink";
import CourseContainer from "../../modules/dimensions/course/GridSections/Course";
import SearchCourse from "../../modules/dimensions/course/Search/SearchCourse";
import Population from "../../modules/dimensions/population/GridSections/Population";
import SearchPopulation from "../../modules/dimensions/population/Search/SearchPopulation";
import Sections from "../../modules/dimensions/section/GridSections/Sections";
import SearchSection from "../../modules/dimensions/section/Search/SearchSection";
import PackageContainer from "../../modules/dimensions/package/GridSections/Package";
import SearchPackage from "../../modules/dimensions/package/Search/SearchPackage";
import SearchGroup from "../../modules/dimensions/group/Search/SearchGroup";
import GroupContainer from "../../modules/dimensions/group/pages/Group";
import EditLabels from "../../modules/dimensions/group/EditLabels/EditLabels";
import EditorHome from "../../modules/home/Home";
import EmptyResults from "../../common/components/EmptyResults/EmptyResults";
import Layout from "../../common/layout/Layout";
import PackageEditLabels from "@dimensions/package/PackageEditLabels/PackageEditLabels";
import PackageLinkManagement from "@dimensions/package/PackageLinkManagement/PackageLinkManagement";

const NotFound = () => (
  <Layout contextSearch={false}>
    <EmptyResults />
  </Layout>
);

export const base = "/scheduler/editor";
const context = ":workspace/:scenario/:origin";

export const moduleRoutes = [
  {
    path: `${base}/instructor/${context}/:id`,
    component: InstructorContainer,
    name: "InstructorContainer",
  },
  {
    path: `${base}/searchBy/instructor/${context}`,
    component: SearchInstructor,
    name: "SearchInstructor",
  },
  {
    path: `${base}/classroom/${context}/:id`,
    component: ClassRoomContainer,
    name: "ClassRoom",
  },
  {
    path: `${base}/searchBy/classroom/${context}`,
    component: SearchClassRooms,
    name: "SearchClassRooms",
  },
  {
    path: `${base}/course/${context}/:id`,
    component: CourseContainer,
    name: "CourseContainer",
  },
  {
    path: `${base}/searchBy/course/${context}`,
    component: SearchCourse,
    name: "SearchCourse",
  },
  {
    path: `${base}/population/${context}/:id`,
    component: Population,
    name: "Population",
  },
  {
    path: `${base}/searchBy/population/${context}`,
    component: SearchPopulation,
    name: "SearchPopulation",
  },
  {
    path: `${base}/section/${context}/:id`,
    component: Sections,
    name: "Sections",
  },
  {
    path: `${base}/searchBy/section/${context}`,
    component: SearchSection,
    name: "SearchSection",
  },
  {
    path: `${base}/link/${context}/:id`,
    component: Link,
    name: "Link",
  },
  {
    path: `${base}/searchBy/link/${context}`,
    component: SearchLeague,
    name: "SearchLink",
  },
  {
    path: `${base}/package/${context}/:id`,
    component: PackageContainer,
    name: "PackageContainer",
  },
  {
    path: `${base}/package/links-management/${context}/:id`,
    component: PackageLinkManagement,
    name: "PackageLinkManagement",
  },
  {
    path: `${base}/searchBy/package/${context}`,
    component: SearchPackage,
    name: "SearchPackage",
  },
  {
    path: `${base}/package/edit-labels/${context}/:id`,
    component: PackageEditLabels,
    name: "EditPackageLabels",
  },
  {
    path: `${base}/group/${context}/:id`,
    component: GroupContainer,
    name: "Group",
  },
  {
    path: `${base}/searchBy/group/${context}`,
    component: SearchGroup,
    name: "SearchGroup",
  },
  {
    path: `${base}/group/edit-labels/${context}/:id`,
    component: EditLabels,
    name: "EditLabels",
  },
  {
    path: "/",
    component: EditorHome,
  },
];

const RouteDimensions: React.FC = (props: any) => (
  <Switch>
    {moduleRoutes.map((route, index) => (
      <ProtectedRoute
        key={index + props.location.key}
        path={route.path}
        component={route.component}
        exact
      />
    ))}
    <Route component={NotFound} />
  </Switch>
);

export default RouteDimensions;
