import React, { useState, useContext } from "react";
import { Input, Checkbox, Button, CardState } from "@foris/avocado-ui";
import cx from "classnames";
import { AppContext } from "../../context/EditSessionsContext";
import { Types as TypeSwitch } from "../../context/switchPage.reducer";
import CampusSelect from "@modules/shared/selectors/Selectors/Campus";
import BuildingSelect from "@modules/shared/selectors/Selectors/Building";
import ClassRoomTypeSelect from "@modules/shared/selectors/Selectors/ClassRoom";
import css from "./advanceTable.module.scss";

interface AdvanceFormProps {
  onCallback: (
    building: string,
    classroomType: string,
    minCapacity: string,
    maxCapacity: string,
    hasEvents: boolean,
    campus?: string,
  ) => void;
  setPage: () => void;
}

const AdvanceForm: React.FC<AdvanceFormProps> = (props: AdvanceFormProps) => {
  const { dispatch } = useContext(AppContext);
  const [campus, setCampus] = useState(null);
  const [building, setBuilding] = useState(null);
  const [classroomType, setClassroomType] = useState(null);
  const [minCapacity, setMinCapacity] = useState(null);
  const [maxCapacity, setMaxCapacity] = useState(null);
  const [hasEvents, setHasEvents] = useState(false);
  const [error, setError] = useState(false);

  const isData = !campus && !building && !classroomType && !minCapacity && !maxCapacity;

  return (
    <section className={css.form}>
      <div className={cx(css.item, "container-row")}>
        <h3 className={cx(css.item_title, "col_12")}>Dependencias</h3>
        <CampusSelect
          label
          className={cx(css.item_option, "col_4")}
          placeholder="Selecciona una opción"
          onCallback={value => {
            setCampus(value);
            setBuilding(null);
          }}
        />
        <BuildingSelect
          label
          campus={campus?.value}
          className={cx(css.item_option, "col_4")}
          placeholder="Selecciona una opción"
          onCallback={value => setBuilding(value)}
        />
      </div>
      <div className={cx(css.item, "container-row")}>
        <h3 className={cx(css.item_title, "col_12")}>Tipo</h3>
        <ClassRoomTypeSelect
          label
          placeholder="Selecciona una opción"
          className={cx(css.item_option, "col_6")}
          onCallback={value => {
            setClassroomType(value);
          }}
        />
      </div>
      <div className={cx(css.item, "container-row")}>
        <h3 className={cx(css.item_title, "col_12")}>Capacidad</h3>
        <Input
          type="number"
          label="Mínima"
          placeholder="1"
          className={cx(css.item_option, "col_3")}
          onChange={value => {
            setMinCapacity(value?.target?.value);
            setError(false);
          }}
        />
        <Input
          type="number"
          label="Máxima"
          placeholder="100"
          className={cx(css.item_option, "col_3")}
          onChange={value => {
            setMaxCapacity(value?.target?.value);
            setError(false);
          }}
        />
        <Checkbox
          checked={hasEvents}
          onChange={() => setHasEvents(!hasEvents)}
          labelRight="Considerar sobrecupo"
          className={cx(css.item_option, "col_4")}
        />
        {error && (
          <CardState
            typeCard="error"
            className={cx(css.errorCard, "col_12")}
            title="Error de validación"
          >
            La capacidad máxima debe ser mayor que la mínima.
          </CardState>
        )}
      </div>
      <div className={cx(css.item, "container-row")}>
        <Button
          onClick={() => {
            dispatch({ type: TypeSwitch.FormPage, payload: true });
          }}
          typeButton="transparent"
          className={css.item_option}
        >
          Cancelar
        </Button>
        <Button
          disabled={isData}
          className={css.item_option}
          onClick={() => {
            if (minCapacity && maxCapacity && parseInt(maxCapacity) < parseInt(minCapacity)) {
              setError(true);
            } else {
              setError(false);
              props?.onCallback(
                building?.value,
                classroomType?.value,
                minCapacity,
                maxCapacity,
                hasEvents,
                campus?.value,
              );
              props.setPage();
            }
          }}
        >
          Buscar
        </Button>
      </div>
    </section>
  );
};

export default AdvanceForm;
