import React, { useEffect, useState } from "react";
import { Input } from "@foris/avocado-ui";
import { IErrorWarning } from "@models/Errors/ErrorWarning";

interface GroupCodeInputProps {
  value?: string;
  onChange?: (value: string) => void;
  onError?: (messages: IErrorWarning[]) => void;
}

const GroupCodeInput = ({ value = "", onChange, onError }: GroupCodeInputProps) => {
  const [valueCode, setValueCode] = useState(value);
  const [errorMessages, setErrorMessages] = useState<IErrorWarning[]>([]);

  const handleOnChange = (value = "") => {
    const pattern = /^[A-Za-z0-9._-]*$/g;
    const parsedVal = value.toLocaleUpperCase().replace(/\s/g, "");
    const errors = [];

    if (parsedVal && !pattern.test(parsedVal)) {
      errors.push({
        type: "ERROR",
        message:
          "La etiqueta del grupo debe contener únicamente caracteres alfanuméricos, puntos, guiones altos y guiones bajos.",
      });
    }

    setValueCode(parsedVal);
    setErrorMessages(errors);
  };

  useEffect(() => {
    onChange?.(valueCode);
    onError?.(errorMessages);
  }, [errorMessages, valueCode]);

  return (
    <Input
      label="Grupo"
      error={!!errorMessages?.length}
      placeholder="Código de Grupo"
      value={valueCode ?? ""}
      onChange={event => handleOnChange(event.target.value)}
    />
  );
};

export default GroupCodeInput;
