import React from "react";
import { Option } from "react-select/lib/filters";
import AdvanceSelector from "../advance-selector";
import useGetShiftOptions from "@modules/shared/selectors/hooks/useGetShiftOptions";

interface AdvancedShiftSelectorProps {
  scenario: string;
  onChange?: (option: Option) => void;
}

const AdvancedShiftSelector = ({ scenario, onChange }: AdvancedShiftSelectorProps) => {
  const { loadOptions } = useGetShiftOptions({
    scenario,
  });

  return (
    <AdvanceSelector
      isPaginated
      dimension="Jornada"
      selectPlaceholder="Selecciona una jornada"
      loadOptions={loadOptions}
      onChange={onChange}
    />
  );
};

export default AdvancedShiftSelector;
