import React from "react";
import { Module } from "@models/ISchema";
import css from "./main-menu.module.scss";

interface Product extends Module {
  title: string;
  icon: any;
  order?: number;
}

interface MainMenuModuleProps {
  title: string;
  products?: Product[];
}

const getModuleUrl = ({ url, code }: Module) => {
  const token = localStorage.getItem("token");
  let urlProduct = url;

  if (code == "demand_editor" && token) {
    urlProduct = `${url}login?token=${token}`;
  }
  if (code == "scheduler_editor" && token) {
    urlProduct = `${url}auth/${token}`;
  }

  return urlProduct;
};

const MainMenuModule = ({ products = [], title = "" }: MainMenuModuleProps) => {
  return (
    <div className={css.module}>
      <p className={css.module_title}>{title}</p>

      <div className={css.module_list}>
        {products?.map((module, key) => (
          <div
            key={key}
            role="button"
            onClick={() => (window.location.href = getModuleUrl(module))}
            className={css.listItem}
          >
            <img
              className={css.listItem_logo}
              src={module.icon}
              width={36}
              height={36}
              loading="lazy"
            />
            <p className={css.listItem_name}>{module.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainMenuModule;
