import React, { useCallback, useMemo, useState } from "react";
import { useApolloClient } from "react-apollo";
import { Curriculum, DataQuery, PageInfo } from "@models/ISchema";
import { curriculumQuery } from "../graphql/curriculum.queries";
import cx from "classnames";
import css from "../Selectors/selectors.module.scss";

interface UseGetCurriculumOptionsParams {
  scenario: string;
  campusId?: string;
  departmentId?: string;
  modalityId?: string;
  shiftId?: string;
  programId?: string;
  activeCode?: boolean;
}

const renderOptionLabel = (curriculum: Curriculum) => {
  return (
    <section className={css.cntOptions}>
      <p className={css.cntOptions_title}>
        {`${curriculum?.code} `}
        <span className={css.bold}>{curriculum?.name}</span>
      </p>
      <div className={cx(css.detailOption, "container-row")}>
        <p className={css.detailOption_item}>
          SE: <span className={css.bold}>{curriculum?.program?.campus?.code || "-"}</span>
        </p>
        <p className={css.detailOption_item}>
          ES: <span className={css.bold}>{curriculum?.program?.department?.code || "-"}</span>
        </p>

        <p className={css.detailOption_item}>
          MO:
          <span className={css.bold}>{curriculum?.program?.modality?.code || "-"}</span>
        </p>

        <p className={css.detailOption_item}>
          JO: <span className={css.bold}>{curriculum?.program?.shift?.code || "-"}</span>
        </p>

        <p className={css.detailOption_item}>
          {`CA: ${curriculum?.program?.code || "-"}`}
          <span className={css.bold}>{curriculum?.program?.name || "-"}</span>
        </p>
      </div>
    </section>
  );
};

const useGetCurriculumOptions = ({
  scenario,
  activeCode,
  campusId,
  departmentId,
  modalityId,
  shiftId,
  programId,
}: UseGetCurriculumOptionsParams) => {
  const client = useApolloClient();
  const [localSearchTerm, setLocalSearchTerm] = useState("");
  const [curriculumPage, setCurriculumPage] = useState(1);

  const variablesCurriculum = useMemo(() => {
    const hasSomeFilter = !!campusId || !!departmentId || !!modalityId || !!shiftId;

    const filters = Object.values({
      campusId: campusId ? { campusId: { eq: campusId } } : undefined,
      departmentId: departmentId ? { departmentId: { eq: departmentId } } : undefined,
      modalityId: modalityId ? { modalityId: { eq: modalityId } } : undefined,
      shiftId: shiftId ? { shiftId: { eq: shiftId } } : undefined,
    }).filter(filter => !!filter);

    return hasSomeFilter || !!programId
      ? {
          fields: {
            and: [
              {
                program: hasSomeFilter
                  ? {
                      ...Object.assign({}, ...filters),
                    }
                  : undefined,
                program_id: programId ? { eq: programId } : undefined,
              },
            ],
          },
        }
      : {};
  }, [scenario]);

  const getCurriculums = useCallback(
    async (searchTerm = "", page = 1) => {
      const optionsPerPage = 10;

      try {
        const variables = {
          query: curriculumQuery,
          variables: {
            scenarioId: scenario,
            filter: {
              ...variablesCurriculum,
              pagination: {
                page,
                size: optionsPerPage,
                searchTerm,
              },
            },
          },
        };

        const { data } = await client.query(variables);
        const dataQuery: DataQuery = data?.data;
        const pageInfo: PageInfo = dataQuery?.curriculums?.pageInfo;

        const options = [];

        if (dataQuery.curriculums.items.length > 0) {
          dataQuery.curriculums.items.forEach((curriculum: Curriculum) => {
            options.push({
              label: renderOptionLabel(curriculum),
              value: activeCode ? curriculum.code : curriculum.id,
            });
          });
        }

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    [client],
  );

  const loadCurriculumOptions = async (searchTerm: string) => {
    let searchPage = 1;

    if (localSearchTerm === searchTerm) {
      searchPage = curriculumPage;
    } else {
      setLocalSearchTerm(searchTerm);
    }
    const { pageInfo, options: programOptions } = await getCurriculums(searchTerm, searchPage);
    setCurriculumPage(curriculumPage + 1);
    return {
      options: programOptions,
      hasMore: pageInfo?.hasNextPage,
      additional: {
        page: curriculumPage,
      },
    };
  };

  return {
    loadCurriculumOptions,
  };
};

export default useGetCurriculumOptions;
