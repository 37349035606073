import gql from "graphql-tag";

export const sessionsCrud = gql`
  mutation($originId: ID!, $scenarioId: ID!, $input: SessionCrudInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      sessionsCrud(input: $input) {
        skippedValidations
        commited
        userCanSkipValidations
        payload {
          updates {
            sessionId
            sessionUuid
            intervalValidationErrors {
              interval {
                id
                startingDate
                endingDate
              }

              validationErrors {
                ... on InvalidBlock {
                  __typename
                  isValidStartTime
                  isValidEndTime
                }
                ... on BookingClassroomClash {
                  __typename
                  interval {
                    name
                    startingDate
                    endingDate
                  }
                  block {
                    startingTime
                    endingTime
                  }
                  sessions {
                    id
                    section {
                      id
                    }
                  }
                  classroom {
                    id
                    code
                  }
                }
                ... on ClassroomClash {
                  __typename
                  interval {
                    name
                    startingDate
                    endingDate
                  }
                  block {
                    startingTime
                    endingTime
                  }
                  sessions {
                    id
                    section {
                      id
                    }
                  }
                  classroom {
                    id
                    code
                  }
                }
                ... on InstructorClash {
                  __typename
                  block {
                    startingTime
                    endingTime
                  }
                  interval {
                    name
                    startingDate
                    endingDate
                  }
                  sessions {
                    id
                    section {
                      id
                    }
                  }
                  instructor {
                    id
                    code
                  }
                  block {
                    startingTime
                    endingTime
                  }
                }
                ... on LinkClash {
                  __typename
                  block {
                    startingTime
                    endingTime
                  }
                  interval {
                    name
                    startingDate
                    endingDate
                  }
                  sessions {
                    id
                    section {
                      id
                    }
                  }
                  link {
                    id
                    vacancies
                  }
                }
                ... on PackageClash {
                  __typename
                  block {
                    startingTime
                    endingTime
                  }
                  interval {
                    startingDate
                    endingDate
                  }
                  sessions {
                    id
                    section {
                      id
                    }
                  }
                  package {
                    id
                    code
                    index
                    complete
                    vacancies
                    hasPackageRules
                    type
                  }
                }
                ... on InvalidInstructorTravel {
                  __typename
                  instructor {
                    id
                    name
                  }
                  withPrevious {
                    from {
                      id
                      vacancies
                    }
                    to {
                      id
                      vacancies
                    }
                    fromBuilding {
                      id
                      name
                      code
                      __typename
                    }
                    toBuilding {
                      id
                      name
                      code
                      __typename
                    }
                    blocksBetween
                    blocksRequired
                  }
                  withFollowing {
                    from {
                      id
                      vacancies
                    }
                    to {
                      id
                      vacancies
                    }
                    fromBuilding {
                      id
                      name
                      code
                      __typename
                    }
                    toBuilding {
                      id
                      name
                      code
                      __typename
                    }
                    blocksBetween
                    blocksRequired
                  }
                }
                ... on InvalidPackageTravel {
                  __typename
                  package {
                    id
                    code
                  }
                  withPrevious {
                    from {
                      id
                      vacancies
                    }
                    to {
                      id
                      vacancies
                    }
                    blocksBetween
                    blocksRequired
                  }
                  withFollowing {
                    from {
                      id
                      vacancies
                    }
                    to {
                      id
                      vacancies
                    }
                    blocksBetween
                    blocksRequired
                  }
                }
                ... on InvalidBlockForInstructor {
                  instructor {
                    code
                  }
                }
                ... on InstructorExceededWeeklyBlocks {
                  instructor {
                    code
                  }
                  assignableBlocks
                  exceededBlocks
                }
              }
            }
            customValidations {
              __typename
              code
              info {
                ... on InstructorInfo {
                  __typename
                  instructor {
                    code
                  }
                }
              }
              items {
                key
                value
              }
            }
            validationErrors {
              ... on InstructorAssignmentRequired {
                allowed
                __typename
              }
              ... on ExternalGroupValidation {
                allowed
                rejected_groups
                __typename
              }
              ... on InstructorAssignmentNotAllowed {
                allowed
                __typename
              }
              ... on ClassroomsAssignmentNotAllowed {
                allowed
                __typename
              }
              ... on IntervalsAssignmentNotAllowed {
                allowed
                __typename
              }
              ... on BlocksAssignmentNotAllowed {
                allowed
                __typename
              }
              ... on InvalidClassroomsCapacityAssignment {
                allowed
                __typename
              }
              ... on InvalidPastSessionEdition {
                allowed
                intervals {
                  id
                  name
                  startingDate
                  endingDate
                  __typename
                }
                __typename
              }
              ... on InvalidSessionInHoliday {
                holiday {
                  id
                  name
                  date
                  __typename
                }
                allowed
                __typename
              }
              ... on StudentClash {
                __typename
                count
                students {
                  id
                  code
                }
              }
            }
          }
          creates {
            sessionId
            sessionUuid
            intervalValidationErrors {
              interval {
                id
                startingDate
                endingDate
              }

              validationErrors {
                ... on InstructorAssignmentRequired {
                  allowed
                  __typename
                }
                ... on InvalidBlock {
                  __typename
                  isValidStartTime
                  isValidEndTime
                }
                ... on BookingClassroomClash {
                  __typename
                  interval {
                    name
                    startingDate
                    endingDate
                  }
                  block {
                    startingTime
                    endingTime
                  }
                  sessions {
                    id
                    section {
                      id
                    }
                  }
                  classroom {
                    id
                    code
                  }
                }
                ... on ClassroomClash {
                  __typename
                  interval {
                    name
                    startingDate
                    endingDate
                  }
                  block {
                    startingTime
                    endingTime
                  }
                  sessions {
                    id
                    section {
                      id
                    }
                  }
                  classroom {
                    id
                    code
                  }
                }
                ... on InstructorClash {
                  __typename
                  block {
                    startingTime
                    endingTime
                  }
                  interval {
                    name
                    startingDate
                    endingDate
                  }
                  sessions {
                    id
                    section {
                      id
                    }
                  }
                  instructor {
                    id
                    code
                  }
                  block {
                    startingTime
                    endingTime
                  }
                }
                ... on LinkClash {
                  __typename
                  block {
                    startingTime
                    endingTime
                  }
                  interval {
                    name
                    startingDate
                    endingDate
                  }
                  sessions {
                    id
                    section {
                      id
                    }
                  }
                  link {
                    id
                    vacancies
                  }
                }
                ... on PackageClash {
                  __typename
                  block {
                    startingTime
                    endingTime
                  }
                  interval {
                    startingDate
                    endingDate
                  }
                  sessions {
                    id
                    section {
                      id
                    }
                  }
                  package {
                    id
                    code
                    index
                    complete
                    vacancies
                    hasPackageRules
                    type
                  }
                }
                ... on InvalidInstructorTravel {
                  __typename
                  instructor {
                    id
                    name
                  }
                  withPrevious {
                    from {
                      id
                      vacancies
                    }
                    to {
                      id
                      vacancies
                    }
                    blocksBetween
                    blocksRequired
                  }
                  withFollowing {
                    from {
                      id
                      vacancies
                    }
                    to {
                      id
                      vacancies
                    }
                    blocksBetween
                    blocksRequired
                  }
                }
                ... on InvalidPackageTravel {
                  __typename
                  package {
                    id
                    code
                  }
                  withPrevious {
                    from {
                      id
                      vacancies
                    }
                    to {
                      id
                      vacancies
                    }
                    blocksBetween
                    blocksRequired
                  }
                  withFollowing {
                    from {
                      id
                      vacancies
                    }
                    to {
                      id
                      vacancies
                    }
                    blocksBetween
                    blocksRequired
                  }
                }
                ... on InvalidBlockForInstructor {
                  instructor {
                    code
                  }
                }
                ... on InstructorExceededWeeklyBlocks {
                  instructor {
                    code
                  }
                  assignableBlocks
                  exceededBlocks
                }
              }
            }
            customValidations {
              __typename
              code
              info {
                ... on InstructorInfo {
                  __typename
                  instructor {
                    code
                  }
                }
              }
              items {
                key
                value
              }
            }
            validationErrors {
              ... on ExternalGroupValidation {
                allowed
                rejected_groups
                __typename
              }
              ... on InstructorAssignmentNotAllowed {
                allowed
                __typename
              }
              ... on ClassroomsAssignmentNotAllowed {
                allowed
                __typename
              }
              ... on IntervalsAssignmentNotAllowed {
                allowed
                __typename
              }
              ... on BlocksAssignmentNotAllowed {
                allowed
                __typename
              }
              ... on InvalidClassroomsCapacityAssignment {
                allowed
                __typename
              }
              ... on InvalidPastSessionEdition {
                allowed
                intervals {
                  id
                  name
                  startingDate
                  endingDate
                  __typename
                }
                __typename
              }
              ... on InvalidSessionInHoliday {
                holiday {
                  id
                  name
                  date
                  __typename
                }
                allowed
                __typename
              }
              ... on StudentClash {
                __typename
                count
                students {
                  id
                  code
                }
              }
            }
          }
          deletes {
            sessionId
            sessionUuid
            validationErrors {
              ... on InvalidPastSessionEdition {
                allowed
                intervals {
                  id
                  name
                  startingDate
                  endingDate
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
        }
      }
    }
  }
`;
