import { useCallback, useMemo, useState } from "react";
import { useApolloClient } from "react-apollo";
import { DataQuery } from "@models/ISchema";
import { courseQuery } from "../graphql/course.queries";

interface UseGetSubjectOptionsParams {
  scenario: string;
  campusId?: string;
  departmentId?: string;
  modalityId?: string;
  shiftId?: string;
  curriculumId?: string;
  levelId?: string;
  programId?: string;
}

const useGetSubjectOptions = ({
  scenario,
  campusId,
  departmentId,
  modalityId,
  shiftId,
  programId,
  curriculumId,
  levelId,
}: UseGetSubjectOptionsParams) => {
  const client = useApolloClient();
  const [subjectPage, setSubjectPage] = useState(0);
  const [termToSearch, setTermToSearch] = useState("");

  const variablesProgram = useMemo(() => {
    const hasSomeFilter = !!campusId || !!departmentId || !!modalityId || !!shiftId || !!programId;

    const filters = Object.values({
      programId: programId ? { id: { eq: programId } } : undefined,
      campusId: campusId ? { campusId: { eq: campusId } } : undefined,
      departmentId: departmentId ? { departmentId: { eq: departmentId } } : undefined,
      modalityId: modalityId ? { modalityId: { eq: modalityId } } : undefined,
      shiftId: shiftId ? { shiftId: { eq: shiftId } } : undefined,
    }).filter(filter => !!filter);

    return hasSomeFilter || !!curriculumId || !!levelId
      ? {
          fields: {
            and: hasSomeFilter
              ? [
                  {
                    program: Object.assign({}, ...filters),
                  },
                ]
              : undefined,
            curriculumId: curriculumId ? { eq: curriculumId } : undefined,
            level: levelId ? { eq: levelId } : undefined,
          },
        }
      : {};
  }, [scenario, campusId, departmentId, modalityId, shiftId, programId, curriculumId, levelId]);

  const requestItems = useCallback(
    async (searchTerm = "", page = 1) => {
      const optionsPerPage = 10;

      try {
        const variables = {
          query: courseQuery,
          variables: {
            scenarioId: scenario,
            filter: {
              ...variablesProgram,
              pagination: {
                page,
                size: optionsPerPage,
                searchTerm,
              },
            },
          },
        };

        const { data } = await client.query(variables);
        const dataQuery = data?.data as DataQuery;
        const pageInfo = dataQuery?.courses?.pageInfo;
        const subjects = dataQuery?.courses?.items;

        const options = (subjects ?? [])?.map(subject => ({
          id: subject?.id,
          value: subject?.id,
          label: `${subject?.code} | ${subject?.name}`,
          self: subject,
        }));

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [client, subjectPage],
  );

  const loadOptions = async (newSearchTerm: string) => {
    const newSearchPage = termToSearch === newSearchTerm ? subjectPage + 1 : 1;

    setTermToSearch(newSearchTerm);
    setSubjectPage(newSearchPage);

    const { pageInfo, options } = await requestItems(newSearchTerm, newSearchPage);

    return {
      options: options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page: subjectPage },
    };
  };

  return {
    loadOptions,
  };
};

export default useGetSubjectOptions;
