import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { ContextEdit, IContextEdit } from "../../ContextEdit/ContextEdit";
import Button from "../../../../../../common/components/Button/Button";
import { BuildingDependency, ClassRoom } from "../../../../../shared/selectors";
import { validations } from "../../../../../dimensions/classroom/AdvanceSearch/utils";
import { Capacity } from "../../../../../shared/inputs";
import { objectEmpty } from "../../../../../../utils/validations";
import { IParams } from "../../../../../../models/IParams";
import { ClassroomFilter } from "../../../../../../models/ISchema";
import { useParams } from "react-router-dom";
import { IAvailability } from "../../../../../advance-search/IAdvanceSearch";
import { daysEn } from "../../../../../../utils/days";
import { classroomSearchMutation } from "../graphql/classroom.queries";
import TableResultsClassRoom from "./TableResultsClassRoom";
import css from "../search.module.scss";

const SearchClassRoom: React.FC<any> = () => {
  const { origin, scenario }: IParams = useParams();
  const context: IContextEdit = useContext(ContextEdit);
  const [searchClassroomMutation] = useMutation(classroomSearchMutation, {});
  const [idSearch, setIdSearch] = useState(null);
  const [objBuilding, setObjBuilding] = useState({});
  const [objCampus, setObjCampus] = useState({});
  const [objClassRoom, setObjClassroom] = useState({});
  const [objCapacity, setObjCapacity] = useState({});
  const [objAvailability, setObjAvailability] = useState<IAvailability>({});
  const [error, setError] = useState(false);
  const [notResult, setNotResult] = useState(false);

  const day = context.currentData.blockRanges.day;
  const [disabledSearch, setDisabledSearch] = useState(false);
  const weeks =
    context.currentData.repeat === "weekSelect"
      ? context.currentData.weeksRepeat
          .filter(weekChecked => weekChecked.checked === true)
          .map(week => parseInt(week.id))
      : context.currentData.weeksRepeat.map(week => parseInt(week.id));

  const advanceRowContent = (title: string, component: React.ReactNode) => (
    <div className={cx(css.searchSelect, "container-row")}>
      <h5 className={cx(css.searchSelect_title, "col_12")}>{title}</h5>
      <div className={cx(css.searchSelect_row, "container-row", "row_align--center")}>
        {component}
      </div>
    </div>
  );

  const setDependencies = (values: any) => {
    const validation = validations.buildingDependency(values, objBuilding, objCampus);
    setObjBuilding(validation.objBuilding);
    setObjCampus(validation.objCampus);
  };

  const setClassroomType = (values: any) => {
    const validation = validations.classRoomType({ classroomType: values }, objClassRoom);
    setObjClassroom(validation.objClassroomType);
  };

  const setCapacity = (values: any, error: any) => {
    if (error) {
      setError(true);
      return;
    }
    const validation = validations.capacity(values, objCapacity);
    setObjCapacity(validation.objCapacity);
    setError(validation.error);
  };

  useEffect(() => {
    const validationDays = (daysEn.findIndex(dayArray => dayArray === day.value) + 1).toString();
    const validationTime = {
      start: context.currentData.blockRanges.startTime.value,
      end: context.currentData.blockRanges.endTime.value,
    };

    let availabilityObj: IAvailability = {};

    if (context.currentData.optionHour === "hourRequiere") {
      availabilityObj = {
        ...availabilityObj,
        availability: {
          ...availabilityObj.availability,
          dayId: [validationDays],
          blockRange: { ...validationTime },
          intervals: [...weeks],
        },
      };
    }
    setObjAvailability(availabilityObj);
  }, []);

  const createObjectMutation = () => {
    const filterArray = [];
    !objectEmpty(objCampus) && filterArray.push(objCampus);
    !objectEmpty(objBuilding) && filterArray.push(objBuilding);
    !objectEmpty(objCapacity) && filterArray.push(objCapacity);
    !objectEmpty(objClassRoom) && filterArray.push(objClassRoom);
    !objectEmpty(objAvailability) && filterArray.push(objAvailability);

    const mutationFilter = {
      originId: parseInt(origin),
      scenarioId: parseInt(scenario),
      filter: {
        fields: {
          and: [{ hasEvents: false }, ...filterArray],
        },
      },
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return mutationFilter;
  };

  const callUpdateHook = async () => {
    setDisabledSearch(true);
    try {
      const variables = createObjectMutation();
      const { data } = await searchClassroomMutation({ variables });
      setDisabledSearch(false);
      const dataItems: ClassroomFilter = data.filter.createClassroomFilter;
      setIdSearch(dataItems.id);
    } catch (error) {
      console.error(error);
      setDisabledSearch(false);
    }
  };

  return (
    <section className={cx(css.cntSearch, css.cntSearch__advance, "container-row")}>
      <h3 className={css.cntSearch_title}>Buscar Sala</h3>
      <div className="container-row">
        {advanceRowContent(
          "Dependencia",
          <BuildingDependency
            onCallback={values => setDependencies(values)}
            className={css.activePadding}
          />,
        )}
        {advanceRowContent(
          "Tipo de sala",
          <ClassRoom onCallback={values => setClassroomType(values ? values.value : null)} />,
        )}
        {advanceRowContent(
          "Capacidad",
          <Capacity
            onCallback={(values, error) => setCapacity(values, error)}
            desactivePaddging={true}
          />,
        )}
      </div>
      <div className={cx(css.cntBtn, "container-row")}>
        <Button
          color="transparent"
          className={cx(css.cntBtn_item, "col_1")}
          onClick={() => context.setCurrentView("form")}
        >
          Cancelar
        </Button>
        <Button
          className={cx(css.cntBtn_item, "col_1")}
          onClick={() => callUpdateHook()}
          disabled={error || disabledSearch}
        >
          Buscar
        </Button>
      </div>
      {notResult && <>Sin Resultados</>}
      {idSearch && (
        <TableResultsClassRoom
          dataId={idSearch}
          onDisabled={(value: boolean, result: boolean) => {
            setNotResult(result);
            setDisabledSearch(value);
          }}
        />
      )}
    </section>
  );
};

export default SearchClassRoom;
