import { CubeMutation, Link, PackagePayload } from "@models/ISchema";
import { packageLinkAssignment } from "@modules/sections/Packages/graphql/packageLinkAssignment.mutation";
import { useMemo } from "react";
import { useMutation } from "react-apollo";

type Payload = {
  linkId: string;
  packageId: string;
};

interface UseAssignLinksProps {
  originId: string;
  scenarioId: string;
  assignments?: Payload[];
  deletions?: Payload[];
  onSavedChanges?: () => void;
}

type ErrorType = "ClashesBetweenPackages" | "InvalidLinksPackage" | "InvalidLinksCourse";

export interface ErrorMessagesByType {
  type: ErrorType;
  userCanSkipValidations: boolean;
  links: {
    [key: string]: Link;
  };
}

const useAssignLinks = ({
  originId,
  scenarioId,
  assignments = [],
  deletions = [],
  onSavedChanges,
}: UseAssignLinksProps) => {
  const [packageLinkAssignmentMutation, { loading, data }] = useMutation(packageLinkAssignment, {
    onCompleted: data => {
      const cubeMutation: CubeMutation = data?.cube;

      if (cubeMutation?.packageLinkAssignment?.commited) {
        onSavedChanges?.();
      }
    },
  });

  /**
   * Returns an object containing error messages for package link assignments.
   *
   * @returns {Object} An object with error messages categorized by error type.
   */
  const errorMessagesByType = useMemo(() => {
    const cubeMutation: CubeMutation = data?.cube;
    const errors: { [key: string]: ErrorMessagesByType } = {};
    const assignmentsIds = assignments?.map(assignment => assignment.linkId);
    const payloadCreates = [
      ...(cubeMutation?.packageLinkAssignment?.payload?.creates || []),
    ]?.filter(payload => assignmentsIds.includes(payload?.link?.id));

    payloadCreates?.forEach(({ link, validationErrors }: PackagePayload) => {
      if (!validationErrors?.length) return;

      validationErrors?.forEach(err => {
        const errorType = err?.__typename as ErrorType;

        if (!errors?.[errorType]) {
          errors[errorType] = {
            type: errorType,
            userCanSkipValidations: cubeMutation?.packageLinkAssignment?.userCanSkipValidations,
            links: {
              [link.id]: link,
            },
          };

          return;
        }

        errors[errorType].links = {
          ...errors[errorType].links,
          [link.id]: link,
        };
      });
    });

    return Object.values(errors) as ErrorMessagesByType[];
  }, [data, assignments]);

  const getVariables = (
    dryRun = false,
    skipValidations = false,
    optionalAssignments: Payload[] = [],
    optionalDeletions: Payload[] = [],
  ) => ({
    originId,
    scenarioId,
    input: {
      dryRun,
      skipValidations,
      changesets: {
        creates: optionalAssignments?.length ? optionalAssignments : assignments,
        deletes: optionalDeletions?.length ? optionalDeletions : deletions,
      },
    },
  });

  const handleLinkAssignment = (
    dryRun = false,
    skipValidations = false,
    optionalAssignments: Payload[] = [],
    optionalDeletions: Payload[] = [],
  ) => {
    return packageLinkAssignmentMutation({
      variables: getVariables(dryRun, skipValidations, optionalAssignments, optionalDeletions),
    });
  };

  return {
    handleLinkAssignment,
    isLoading: loading,
    response: data,
    errorMessagesByType,
  };
};

export default useAssignLinks;
