import React from "react";
import cx from "classnames";
import RadioButton from "../../../../../common/components/RadioButton/RadioButton";
import CreateLink from "./CreateLink";
import css from "./addLinkSection.module.scss";

interface IAddLinkSection {
  courseId: number;
  termId: number;
  groupCode?: string;
  groupVacancies?: string;
  programId?: string | number;
  onReturn: () => void;
}

const AddLinkSection: React.FC<IAddLinkSection> = (props: IAddLinkSection) => {
  return (
    <article className={cx(css.cntAddLink, "container-row")}>
      <h2 className={css.cntAddLink_title}>Agregar liga/secciones</h2>
      <div className={cx(css.cntOption, "container-row")}>
        <RadioButton
          className={css.cntOption_item}
          label="Liga nueva"
          name="newLink"
          check={true}
        />
        <RadioButton
          className={css.cntOption_item}
          label="Liga existente"
          name="newLink"
          disabled={true}
        />
      </div>

      <CreateLink {...props} />
    </article>
  );
};

export default AddLinkSection;
