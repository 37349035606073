import { useApolloClient, useMutation } from "react-apollo";
import { NEW_LINK_CODE, NEW_LINK_SECTION } from "../CreateSection/graphql/links.queries";
import { CreateLinkPayload, CubeMutation } from "@models/ISchema";
import { IReturnComponent } from "../CreateSection/AddLinkSection/ComponentItem";
import { IErrorWarning } from "@models/Errors/ErrorWarning";
import useAddGroup from "@modules/vacancies/hooks/useAddGroup";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { LINK_ERROR_LABELS } from "@models/Errors/LinkErrors/LinkErrors";

interface useCreateLinkProps {
  scenario: string;
  origin: string;
  workspace: string;
}

interface newSectionByLinkProps {
  courseId: string | number;
  dryRun?: boolean;
  termId: string;
  createSections: IReturnComponent[];
  programId?: string;
  groupCode?: string;
  groupVacancies?: string;
}

const useCreateLink = ({ scenario, origin, workspace }: useCreateLinkProps) => {
  const client = useApolloClient();
  const history = useHistory();
  const [codeLinkMutation] = useMutation(NEW_LINK_CODE, {});
  const [newLinkSectionMutation] = useMutation(NEW_LINK_SECTION, {});
  const [linkCreationError, setLinkCreationError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    addGroup,
    validationArray: groupValidationArray,
    validationStandardArray: groupValidationStandardArray,
  } = useAddGroup({ scenario, origin });

  const getCodeLink = async ({
    courseId,
    dryRun = false,
  }: {
    courseId: string | number;
    dryRun?: boolean;
  }) => {
    try {
      const variables = {
        originId: origin,
        scenarioId: scenario,
        courseId: courseId,
        dryRun,
      };
      const { data } = await codeLinkMutation({ variables });
      const code: CubeMutation = data.cube;
      return code.inferLinkCode;
    } catch (e) {
      console.debug(e);
    }
  };

  /**
   * Generate new Section by Link
   */
  const newSectionByLink = async (
    { courseId, termId, dryRun = false, createSections }: newSectionByLinkProps,
    linkCode: string,
  ) => {
    try {
      const assignSections = [];
      const courseComponentId = [];
      const errorCreateLink = [];

      createSections.forEach(value => {
        if (value.typeSection === "OLD_SECTION") {
          assignSections.push(parseInt(value.section.value));
        }
        if (value.typeSection === "NEW_SECTION") {
          courseComponentId.push({
            courseComponentId: parseInt(value.courseComponent.id),
          });
        }
      });
      const variables = {
        scenarioId: scenario,
        originId: origin,
        input: {
          dryRun,
          skipValidations: false,
          courseId: courseId,
          termId: termId,
          code: linkCode,
          assignSections,
          createSections: courseComponentId,
        },
      };

      const { data } = await newLinkSectionMutation({ variables });
      const payload: CreateLinkPayload = data.cube.createLink;

      if (!payload.commited) {
        payload.validationErrors &&
          payload.validationErrors.forEach(error => {
            const errorCode = "code" in error ? error?.code : "";
            const message = errorCode
              ? (LINK_ERROR_LABELS?.[errorCode as keyof typeof LINK_ERROR_LABELS]?.()
                  ?.title as string)
              : null;

            const objValidate: IErrorWarning = {
              type: "ERROR",
              message: message ?? "Ha ocurrido un error, por favor vuelva a cargar la página.",
            };
            errorCreateLink.push(objValidate);
          });

        setLinkCreationError(errorCreateLink);
      }

      return {
        canCreateLink: !payload.validationErrors?.length,
        linkId: payload?.link?.id,
        commited: !!payload?.commited,
        bundle: payload?.link?.bundle,
      };
    } catch (e) {
      console.error(e);
    }
  };

  const handleCreateLink = async (
    {
      courseId,
      termId,
      createSections,
      groupCode,
      groupVacancies,
      programId,
    }: Partial<newSectionByLinkProps>,
    linkCode = "",
  ) => {
    try {
      const { linkId, commited, bundle } = await newSectionByLink(
        { courseId, termId, createSections },
        linkCode,
      );

      if (groupCode && programId && groupVacancies) {
        await addGroup(
          {
            linkId: linkId,
            programId,
            courseId: courseId as string,
            termId,
            code: groupCode,
            capacity: Number(groupVacancies),
          },
          true,
        );
      }

      setIsLoading(false);

      if (commited) {
        client.resetStore();
        const contextUrl = `${workspace}/${scenario}/${origin}`;
        history.push(`/editor/vacancies/${contextUrl}/${bundle?.id}`);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleSimulateMutation = async (
    {
      courseId,
      termId,
      createSections,
      groupCode,
      programId,
      groupVacancies,
    }: newSectionByLinkProps,
    isSimulated = true,
  ) => {
    try {
      setIsLoading(true);
      // get code link
      const newCode = await getCodeLink({ courseId, dryRun: isSimulated });
      // simulate link creation
      const { canCreateLink } = await newSectionByLink(
        { courseId, termId, dryRun: isSimulated, createSections },
        newCode,
      );

      // if no errors happen, call the mutations without dryrun
      if (canCreateLink) {
        await handleCreateLink(
          {
            courseId,
            termId,
            createSections,
            groupCode,
            programId,
            groupVacancies,
          },
          newCode,
        );
      } else {
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return {
    handleSimulateMutation,
    linkCreationError,
    isLoading,
    groupValidationArray,
    groupValidationStandardArray,
  };
};

export default useCreateLink;
