import React, { useContext, useMemo, useRef, useState } from "react";
import { Icon } from "@foris/avocado-ui";
import cx from "classnames";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import catalogos from "./../../../assets/img/menuForisIcons/catalogos.svg";
import proyecciones from "./../../../assets/img/menuForisIcons/proyecciones.svg";
import asignaciones from "./../../../assets/img/menuForisIcons/asignaciones.svg";
import analyticsIcon from "./../../../assets/img/menuForisIcons/analytics.svg";
import edd from "./../../../assets/img/menuForisIcons/edd.svg";
import edh from "./../../../assets/img/menuForisIcons/edh.svg";
import espacios from "./../../../assets/img/menuForisIcons/espacios.svg";
import css from "./main-menu.module.scss";
import useClickOutside from "@common/hooks/useClickOutside";
import MainMenuModule from "./MainMenuModule";

const DATA_MANAGEMENT = {
  catalogs: {
    icon: catalogos,
    title: "Catálogos",
  },
};

const STUDENT_MANAGEMENT = {
  forecast: {
    icon: proyecciones,
    title: "Proyecciones",
  },
  demand_editor: {
    icon: edd,
    title: "Editor de demanda",
  },
};

const SCHEDULE_MANAGEMENT = {
  assignation: {
    icon: asignaciones,
    title: "Asignaciones",
    order: 1,
  },
  scheduler_editor: {
    icon: edh,
    title: "Editor de horarios",
    order: 2,
  },
  bookings: {
    icon: espacios,
    title: "Reservas",
    order: 3,
  },
  schedule: {
    icon: analyticsIcon,
    title: "Analíticas",
    order: 4,
  },
};

const MainMenu = () => {
  const context: IContextApp = useContext(ContextApp);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  useClickOutside({
    ref: menuRef,
    onClickOutside() {
      setShowMenu(false);
    },
  });

  const products = useMemo(() => {
    const dataManagement = {
      isAccesible: false,
      modules: [],
    };
    const studentManagement = {
      isAccesible: false,
      modules: [],
    };
    const scheduleManagement = {
      isAccesible: false,
      modules: [],
    };

    context.modules?.forEach(module => {
      if (DATA_MANAGEMENT?.[module?.code]) {
        if (module.accesible) {
          dataManagement.isAccesible = true;
        }

        dataManagement.modules.push({
          ...module,
          title: DATA_MANAGEMENT?.[module?.code].title,
          icon: DATA_MANAGEMENT?.[module?.code].icon,
        });
      }

      if (STUDENT_MANAGEMENT?.[module?.code]) {
        if (module.accesible) {
          studentManagement.isAccesible = true;
        }

        studentManagement.modules.push({
          ...module,
          title: STUDENT_MANAGEMENT?.[module?.code].title,
          icon: STUDENT_MANAGEMENT?.[module?.code].icon,
        });
      }

      if (SCHEDULE_MANAGEMENT?.[module?.code]) {
        if (module.accesible) {
          scheduleManagement.isAccesible = true;
        }

        scheduleManagement.modules.push({
          ...module,
          title: SCHEDULE_MANAGEMENT?.[module?.code].title,
          icon: SCHEDULE_MANAGEMENT?.[module?.code].icon,
          order: SCHEDULE_MANAGEMENT?.[module?.code].order,
        });
      }
    });

    return {
      dataManagement,
      studentManagement,
      scheduleManagement: {
        ...scheduleManagement,
        modules: scheduleManagement?.modules?.sort((a, b) => a.order - b.order),
      },
    };
  }, [context.modules]);

  const isLargeMenu = () => {
    const dataManagement = products.dataManagement.isAccesible
      ? products.dataManagement.modules?.length
      : 0;
    const studentManagement = products.studentManagement.isAccesible
      ? products.studentManagement.modules?.length
      : 0;
    const scheduleManagement = products.scheduleManagement.isAccesible
      ? products.scheduleManagement.modules?.length
      : 0;

    return dataManagement + studentManagement > 3 || scheduleManagement > 3;
  };

  if (
    products?.dataManagement?.modules?.length ||
    products?.studentManagement?.modules?.length ||
    products?.scheduleManagement?.modules?.length
  )
    return (
      <>
        <div className={cx(css.mainMenu, isLargeMenu() && css.mainMenu__large)} ref={menuRef}>
          <div className={css.mainMenu_trigger} onClick={() => setShowMenu(!showMenu)}>
            <Icon icon="cube" size={20} className={css.mainMenu_icon} />
          </div>

          <div className={cx(css.mainMenu_products, showMenu ? css.mainMenu_products__active : "")}>
            <div className={css.topModules}>
              {products.dataManagement.isAccesible &&
                !!products?.dataManagement?.modules?.length && (
                  <MainMenuModule
                    title="Gestión de datos"
                    products={products?.dataManagement?.modules}
                  />
                )}
              {products.studentManagement.isAccesible &&
                !!products?.studentManagement?.modules?.length && (
                  <MainMenuModule
                    title="Gestión de estudiantes"
                    products={products?.studentManagement?.modules}
                  />
                )}
            </div>

            {products.scheduleManagement.isAccesible &&
              !!products?.scheduleManagement?.modules?.length && (
                <MainMenuModule
                  title="Gestión de programación"
                  products={products?.scheduleManagement?.modules}
                />
              )}
          </div>
        </div>
      </>
    );

  return null;
};

export default MainMenu;
