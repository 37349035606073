import React, { useState } from "react";
import cx from "classnames";
import Cleave from "cleave.js/react";
import css from "./timeRange.module.scss";

export interface ITimeRangeValues {
  minTime: string;
  maxTime: string;
}

export interface ITimeRangeReturn {
  required: boolean;
  onCallback: (value: ITimeRangeValues, error?: boolean) => void;
}

const TimeRangeInput: React.FC<ITimeRangeReturn> = (props: ITimeRangeReturn) => {
  const { required, onCallback } = props;
  const [minTime, setMinTime] = useState("");
  const [maxTime, setmaxTime] = useState("");
  const [error, setErrorCapacity] = useState(false);
  const [errorText, setErrorText] = useState("");

  const validateMin = (min: string, max: string) => {
    min = min.replace(":", "");
    max = max.replace(":", "");
    return parseInt(min) > parseInt(max);
  };

  const formatValue = (value: any) => {
    const re = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return value !== "" && !value.match(re);
  };

  const validateInput = (value: string, min: string, max: string) => {
    const isWrongFormat = formatValue(value);
    const isWrongMin = validateMin(min, max);

    const hasError = isWrongFormat || isWrongMin;
    const errorMessage = isWrongFormat
      ? "Formato incorrecto de hora (HH:MM)"
      : "La hora de inicio no puede ser mayor a la hora final.";

    setErrorCapacity(hasError);
    setErrorText(hasError ? errorMessage : "");

    return hasError;
  };

  const styleError = (required && !error) || error;

  return (
    <div className={css.timeRange}>
      <div className={css.timeRange_fields}>
        <div className={css.fieldControl}>
          <Cleave
            options={{ time: true, timePattern: ["h", "m"], type: "text" }}
            className={cx(css.fieldControl_input, styleError && css.fieldControl_input__error)}
            placeholder="Hora inicio"
            onChange={(value: any) => {
              const newValue = value.target.value;
              setMinTime(newValue);
              const error = validateInput(newValue, newValue, maxTime);
              if (newValue) {
                onCallback(
                  {
                    minTime: newValue,
                    maxTime: maxTime,
                  },
                  error,
                );
              } else {
                onCallback(
                  {
                    minTime: "",
                    maxTime: maxTime,
                  },
                  error,
                );
              }
            }}
          />
        </div>

        <span className={css.fieldSeparator}>-</span>

        <div className={css.fieldControl}>
          <Cleave
            options={{ time: true, timePattern: ["h", "m"], type: "text" }}
            className={cx(css.fieldControl_input, styleError && css.fieldControl_input__error)}
            placeholder="Hora fin"
            onChange={(value: any) => {
              const newValue = value.target.value;
              setmaxTime(newValue);
              const error = validateInput(newValue, minTime, newValue);
              if (newValue) {
                onCallback(
                  {
                    minTime: minTime,
                    maxTime: newValue,
                  },
                  error,
                );
              } else {
                onCallback(
                  {
                    minTime: minTime,
                    maxTime: "",
                  },
                  error,
                );
              }
            }}
          />
        </div>
      </div>

      {error && <p className={css.timeRange_error}>{errorText}</p>}
      {required && !error && (
        <p className={css.timeRange_error}>
          Ingresar rango de horas para el que se busca disponibilidad.
        </p>
      )}
    </div>
  );
};

export default TimeRangeInput;
