import gql from "graphql-tag";

export const campusQuery = gql`
  query campus($scenarioId: ID!, $filter: CampusFilterInput) {
    data(scenarioId: $scenarioId) {
      campuses(filter: $filter) {
        items {
          id
          code
          name
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
