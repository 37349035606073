import gql from "graphql-tag";

export const packageSearchMutation = gql`
  mutation($scenarioId: ID!, $originId: ID!, $filter: PackageFilterInput) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createPackageFilter(filter: $filter) {
        id
        packages {
          items {
            id
            code
            index
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            page
            size
            total
          }
        }
      }
    }
  }
`;

export const packageSearchQuery = gql`
  query filter($scenarioId: ID!, $filterId: ID, $pagination: PaginationInput) {
    filter(scenarioId: $scenarioId) {
      packages(filterId: $filterId, pagination: $pagination) {
        items {
          id
          vacancies
          index
          labels {
            id
            code
            name
            category {
              id
              code
              name
            }
          }
          population {
            id
            code
            level
            curriculum
            campus {
              id
              code
            }
            modality {
              id
              code
            }
            shift {
              id
              code
            }
            program {
              id
              code
            }
            courses {
              id
              curriculum {
                id
                code
                program {
                  id
                  code
                  campus {
                    id
                    code
                  }
                  modality {
                    id
                    code
                  }
                  shift {
                    id
                    code
                  }
                }
              }
            }
          }
          code
          __typename
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
