import gql from "graphql-tag";

export const LABELS_CATEGORIES_QUERY = gql`
  query labelCategories($filter: LabelCategoryFilterInput, $scenarioId: ID!) {
    data(scenarioId: $scenarioId) {
      labelCategories(filter: $filter) {
        items {
          id
          code
          name
          labelSource {
            id
            code
            name
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
