import React, { useEffect, useContext, useState } from "react";
import {
  assoc,
  filter,
  isEmpty,
  join,
  keys,
  lensPath,
  lensProp,
  map,
  pipe,
  prop,
  reduce,
  set,
  T,
  toLower,
  view,
} from "ramda";
import { Link, useParams, useHistory } from "react-router-dom";
import cx from "classnames";
import {
  Calendar,
  DataGrid,
  Icon,
  RadioButton,
  Button,
  Checkbox,
  DonutLegend,
  IconType,
  Pill,
  Anchor,
} from "@foris/avocado-ui";
import { ContextEDH } from "@context/ContextEDH";
import { IParams } from "@models/IParams";
import { Label, OrderByDirection, PageInfo } from "@models/ISchema";
import * as mouseflow from "@utils/mouseflow";
import { Context } from "../../context/GroupsManagerContext";
import { TableFiltersReducerType } from "../../context/types";
import { Types as EditionTypes } from "../../context/editions.reducer";
import { Types as NavigationTypes } from "../../context/navigation.reducer";
import { Types as TableFiltersTypes } from "../../context/tableFilters.reducer";
import CapacityInput from "../CapacityInput/CapacityInput";
import Donut from "../Donut/Donut";
import Modal from "../Modal/Modal";
import buildReport from "../../utils/reports";
import savingAllowed from "../../utils/savingAllowed";
import { DataGridProps } from "@foris/avocado-ui/lib/types/components/DataGrid/DataGrid";
import { PagerProps } from "@foris/avocado-ui/lib/types/components/Pager/Pager";
import { Column, RowAction, SortInfo } from "@foris/avocado-ui/lib/types/components/DataGrid/types";
import TableFilter from "../TableFilter/TableFilter";
import MultiCapacityInput from "../MultiCapacityInput/MultiCapacityInput";
import donut from "../../utils/donut";
import groupsTableHeaderToOrderByObj from "../../utils/groupsTableHeaderToOrderByObj";
import { AdaptedGroup, FiltersType, GroupsManagerTableColumn } from "../../models";
import css from "./groupsManagerTable.module.scss";

interface IGroupsManagerTableProps {
  requestGroups: (
    page: number,
    avoidEditionsCleaning: boolean,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => void;
  pageInfo: PageInfo;
  activeFilterType: FiltersType;
}

enum DownloadMode {
  All = "all",
  ByDate = "by_date",
}

const ROWS_PER_PAGE = 20;
let filterType: FiltersType = null;

const isGroupAllowedToEdit = (group: AdaptedGroup) => {
  const isParentType = !!group?.referentType;

  if (isParentType && filterType !== "PACKAGE") {
    return false;
  }

  const isValidGroupType = isParentType ? group?.referentType !== "CAMPUS" : true;
  const isEditionAllowed = isValidGroupType && group?.isEditable?.allowed && group?.isActive;

  return isEditionAllowed;
};

const GroupsManagerTable = ({
  requestGroups,
  pageInfo,
  activeFilterType,
}: IGroupsManagerTableProps) => {
  const history = useHistory();
  const { state: outerState } = useContext(ContextEDH);
  const { state, dispatch } = useContext(Context);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayReportModal, setDisplayReportModal] = useState(false);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [downloadMode, setDownloadMode] = useState<DownloadMode>(DownloadMode.All);
  const [groupIdsToExludeFromCapacityEditions, setGroupIdsToExludeFromCapacityEditions] = useState(
    new Set<string>(),
  );
  const [onModalClick, setOnModalClick] = useState({
    primary: () => {
      /* pass */
    },
    secondary: () => {
      /* pass */
    },
  });
  const canEdit = outerState?.base?.base?.user?.abilities?.can_edit_groups_in_group_manager;

  const showPackageCalendarLink =
    state?.filters?.selectedPackage != null &&
    state?.filtersSelectors?.visibleFilter === FiltersType.PACKAGE;

  const [columnsToSearchBy, setColumnsToSearchBy] = useState<
    Partial<Record<GroupsManagerTableColumn, boolean>>
  >({
    CRN: true,
    "Grupo | Campus": true,
    Asignatura: true,
    "Docente principal": true,
    Tipo: true,
  });

  const { origin, scenario, workspace }: IParams = useParams();
  const contextUrl = `${workspace}/${scenario}/${origin}`;

  const groupIsVisible = (group: AdaptedGroup): boolean => {
    return state?.editions?.byGroupId[group?.id]?.visibleForEnrollment != undefined
      ? view(lensPath(["byGroupId", group?.id, "visibleForEnrollment"]), state?.editions ?? {})
      : group?.visibleForEnrollment;
  };

  const [leftAction, setLeftAction] = useState<DataGridProps<AdaptedGroup>["leftAction"]>({
    isVisible: T,
    checked: (group: AdaptedGroup) => group?.id in state?.editions?.groupsToEditById,
    disabled: (group: AdaptedGroup) => !isGroupAllowedToEdit(group) || !canEdit,
    onClick: (group: AdaptedGroup) => {
      if (group?.id in state?.editions?.groupsToEditById) {
        dispatch({ type: EditionTypes.RemoveGroupToEdit, payload: group });
      } else {
        dispatch({ type: EditionTypes.AddGroupToEdit, payload: group });
      }
    },
  });
  const [rightActions, setRightActions] = useState<DataGridProps<AdaptedGroup>["rightActions"]>([
    {
      isVisible: T,
      disabled: (group: AdaptedGroup) => !isGroupAllowedToEdit(group) || !canEdit,
      icon: (group: AdaptedGroup): IconType => (groupIsVisible(group) ? "open-eye" : "closed-eye"),
      onClick: (group: AdaptedGroup) => {
        dispatch({
          type: EditionTypes.UpdateGroupVisibility,
          payload: {
            groupId: group?.id,
            value: !groupIsVisible(group),
            originalValue: group?.visibleForEnrollment,
          },
        });
      },
    },
  ]);

  const pagination = pipe(
    set(lensProp<PagerProps>("onChange"), (page: number) =>
      requestGroups(page, false, state?.tableFilters?.orderBy, state?.tableFilters?.searchBy),
    ),
    set(lensProp<PagerProps>("total"), pageInfo?.total),
    set(lensProp<PagerProps>("page"), pageInfo?.page),
    set(lensProp<PagerProps>("size"), ROWS_PER_PAGE),
  )((pageInfo ?? {}) as PagerProps);

  useEffect(() => {
    // update left actions
    setLeftAction(
      pipe(
        set(
          lensProp<RowAction<AdaptedGroup>>("disabled"),
          (group: AdaptedGroup) => !isGroupAllowedToEdit(group),
        ),
        set(
          lensProp<RowAction<AdaptedGroup>>("checked"),
          (group: AdaptedGroup) => group?.id in state?.editions?.groupsToEditById,
        ),
        set(lensProp<RowAction<AdaptedGroup>>("onClick"), (group: AdaptedGroup) => {
          if (group?.id in state?.editions?.groupsToEditById) {
            dispatch({ type: EditionTypes.RemoveGroupToEdit, payload: group });
          } else {
            dispatch({ type: EditionTypes.AddGroupToEdit, payload: group });
          }
        }),
      )(leftAction),
    );
    // update right actions
    const newRightAction = pipe(
      set(
        lensProp<RowAction<AdaptedGroup>>("disabled"),
        (group: AdaptedGroup) => !isGroupAllowedToEdit(group),
      ),
      set(lensProp<RowAction<AdaptedGroup>>("icon"), (group: AdaptedGroup) =>
        groupIsVisible(group) ? "open-eye" : "closed-eye",
      ),
      set(lensProp<RowAction<AdaptedGroup>>("onClick"), (group: AdaptedGroup) => {
        dispatch({
          type: EditionTypes.UpdateGroupVisibility,
          payload: {
            groupId: group?.id,
            value: !groupIsVisible(group),
            originalValue: group?.visibleForEnrollment,
          },
        });
      }),
    )(rightActions[0]);
    setRightActions([newRightAction]);
  }, [state?.editions?.groupsToEditById, state?.data?.groupsById, state?.editions?.byGroupId]);

  useEffect(() => {
    filterType = activeFilterType;
  }, [activeFilterType]);

  const onEditionsCheckboxClick = (event: any) => {
    const checked = event?.target?.checked;
    if (!checked) {
      dispatch({ type: EditionTypes.SetGroupsToEdit, payload: {} });
    } else {
      const groupsById = pipe(
        filter(isGroupAllowedToEdit),
        reduce((acc, group) => assoc(group?.id, group, acc), {}),
      )(state?.data?.groups);
      dispatch({ type: EditionTypes.SetGroupsToEdit, payload: groupsById });
    }
  };

  const onHeaderColumnClick = (column: Column<AdaptedGroup>) => {
    const currentOrderBy = state?.tableFilters?.orderBy;

    if (column?.header === currentOrderBy?.header) {
      const newDirection =
        currentOrderBy?.direction === OrderByDirection.Asc
          ? OrderByDirection.Desc
          : OrderByDirection.Asc;
      const newOrderBy = set(lensProp("direction"), newDirection, state?.tableFilters?.orderBy);

      dispatch({
        type: TableFiltersTypes.SetOrderBy,
        payload: newOrderBy,
      });
      requestGroups(pageInfo?.page, false, newOrderBy, state?.tableFilters?.searchBy);
    } else {
      const newOrderBy = groupsTableHeaderToOrderByObj(column?.header as GroupsManagerTableColumn);
      dispatch({
        type: TableFiltersTypes.SetOrderBy,
        payload: newOrderBy,
      });
      requestGroups(pageInfo?.page, false, newOrderBy, state?.tableFilters?.searchBy);
    }
  };

  /**
   * Given a callback, display the Modal component if any edition has been
   * made and the editions *can be saved*, asking for confirmation.
   *
   * If the user confirms, the given callback it's
   * executed, if the user "cancels" the callback isn't executed and the modal
   * disappears.
   */
  const confirmable = (callback: (...args: any[]) => any) => (...args: any[]): any => {
    const predicate =
      !isEmpty(state?.editions?.byGroupId) && savingAllowed(state?.editions?.errorsByGroupId);
    if (predicate) {
      setDisplayModal(true);
      setOnModalClick({
        primary: () => {
          setDisplayModal(false);
        },
        secondary: () => {
          setDisplayModal(false);
          dispatch({ type: EditionTypes.Clean });
          callback(...args);
        },
      });
    } else {
      callback(...args);
    }
  };

  const span = (label: string) => <span>{label ?? "--"}</span>;
  const columns: DataGridProps<AdaptedGroup>["columns"] = [
    {
      header: "CRN",
      renderer: (group: AdaptedGroup) => {
        const pathname = `/editor/groups-manager/${contextUrl}/${group?.id}`;
        const onClick = () => {
          dispatch({
            type: NavigationTypes.AddToLocationStack,
            payload: {
              view: `/editor/groups-manager/${contextUrl}`,
              page: state?.data?.groupsPageInfo?.page ?? 1,
            },
          });
          mouseflow.actionTag(
            "action_groups_manager_enrollments",
            outerState?.base?.isMouseflowEnabled,
          );
          history.push(pathname);
        };
        return (
          <Link
            className={css.table__crn}
            to={{ pathname }}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              confirmable(onClick)();
            }}
          >
            {group?.code ?? "--"}
          </Link>
        );
      },
      styles: {
        width: "10%",
      },
    },
    {
      header: "Grupo | Campus",
      renderer: (group: AdaptedGroup) => {
        const campus = view(lensPath(["campus", "code"]), group);
        const isParentType = view(lensProp("isReferent"), group);
        const groupLabel = group?.label;

        if (campus === "NAL" || isParentType) {
          const pathname = `/editor/groups-manager/subgroups/${contextUrl}/${group?.id}`;
          return (
            <span>
              {groupLabel ?? "--"} |{" "}
              <Link
                className={css.groupLink}
                to={{ pathname }}
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  dispatch({
                    type: NavigationTypes.AddToLocationStack,
                    payload: {
                      view: `/editor/groups-manager/${contextUrl}`,
                      page: state?.data?.groupsPageInfo?.page ?? 1,
                    },
                  });
                  mouseflow.actionTag(
                    "action_groups_manager_subgroups",
                    outerState?.base?.isMouseflowEnabled,
                  );
                  history.push(pathname);
                }}
              >
                {campus ?? "--"}
              </Link>
            </span>
          );
        }

        return span(`${groupLabel} | ${campus}`);
      },
      styles: {
        width: "10%",
      },
    },
    {
      header: "Asignatura",
      renderer: (group: AdaptedGroup) => {
        const code = group?.course?.code;
        const name = group?.course?.name;
        return Boolean(code) || Boolean(name) ? span(join(" | ", [code, name])) : "-";
      },
      // tooltip: true,
      styles: {
        width: "15%",
      },
    },
    {
      header: "Subperiodo",
      renderer: (group: AdaptedGroup) => {
        const isPmtLabel = (label: Label) => toLower(label?.code ?? "")?.includes("pmt");
        const groupSubterms = pipe(
          filter(isPmtLabel),
          map(prop("code")),
          join(","),
        )(group?.labels ?? []);
        return span(groupSubterms);
      },
      // tooltip: true,
      styles: {
        width: "8%",
      },
    },
    {
      header: "Docente principal",
      renderer: (group: AdaptedGroup) => {
        const code = group?.primaryInstructor?.code;
        const name = group?.primaryInstructor?.name;
        return Boolean(code) || Boolean(name) ? span(join(" | ", [code, name])) : "No asignado";
      },
      // tooltip: true,
      styles: {
        width: "15%",
      },
    },
    {
      header: "Estado",
      renderer: (item: AdaptedGroup) => span(item?.isActive ? "Activo" : "Inactivo"),
      styles: {
        width: "6%",
      },
    },
    {
      header: "Cupos",
      renderer: (group: AdaptedGroup) => {
        return (
          <CapacityInput
            group={group}
            disabled={!isGroupAllowedToEdit(group) || !canEdit}
            isSubgroup={false}
          />
        );
      },
      styles: {
        width: "11%",
      },
    },
    {
      header: "Utilización",
      renderer: (group: AdaptedGroup) => <Donut group={group} padding={"0.75rem 0"} />,
      styles: {
        width: "14%",
      },
    },
    {
      header: "Cap.",
      renderer: pipe(
        view(
          lensPath<AdaptedGroup>(["groupCapacity", "calculatedCapacity"]),
        ),
        span,
      ),
      styles: {
        width: "5%",
      },
    },
    {
      header: "Min - Max",
      renderer: (group: AdaptedGroup) => {
        const min = group?.groupCapacity?.configMinCapacity ?? 0;
        const max = group?.groupCapacity?.configMaxCapacity ?? 0;
        return span(!min || !max ? "N/A" : `${min} - ${max}`);
      },
      styles: {
        width: "7%",
      },
    },
    {
      header: "Tipo",
      renderer: (group: AdaptedGroup) => {
        const typeLabels = {
          CAMPUS: "Nacional",
          PACKAGE: "Paquete",
        };
        const isNAL = view(lensPath(["campus", "code"]), group) === "NAL";
        const isParentGroup = view(lensProp("isReferent"), group);
        const parentType = view(lensProp("referentType"), group);
        const label = isNAL ? typeLabels["CAMPUS"] : typeLabels?.[parentType];

        if (label) {
          return (
            <Pill className={css.groupTypePill}>
              {label}
              {isParentGroup ? <Icon icon="branch" size={12} /> : null}
            </Pill>
          );
        }

        return "";
      },
      styles: {
        width: "80px",
      },
    },
  ];

  /**
   * Clean context's groupsToEdit when the table is initialized
   */
  useEffect(() => {
    dispatch({ type: EditionTypes.SetGroupsToEdit, payload: {} });
  }, []);

  /**
   * Set the `groupIdsToExludeFromCapacityEditions` after groups are requested.
   * Groups are exluded if they're "NAL" or are not editable.
   */
  useEffect(() => {
    const groupIdsToExclude = reduce(
      (acc, group) => {
        if (group?.campus?.code == "NAL" || !group?.isEditable?.allowed) {
          acc?.add(group?.id);
        }
        return acc;
      },
      new Set<string>(),
      state?.data?.groups,
    );
    setGroupIdsToExludeFromCapacityEditions(groupIdsToExclude);
  }, [state?.data?.groups]);

  const leftHeaderComponent = () => {
    return (
      <div className={css.tableCard__header__left}>
        <label className={css.tableCard__title}>{state?.data?.groupsPageInfo?.total} Grupos</label>
        <a
          className={css.tableCard__link}
          href={buildReport("groups", {
            filters: state?.filters,
            context: { origin, scenario },
          })}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            mouseflow.actionTag(
              "action_groups_manager_groups_report",
              outerState?.base?.isMouseflowEnabled,
            );
          }}
          download
        >
          <Icon className={css.tableCard__icon} icon="download" />
          <span>Descargar reporte</span>
        </a>
        <Button
          className={css.tableCard__button}
          onClick={() => setDisplayReportModal(true)}
          color="primary"
          variant="ghost"
        >
          <Icon className={css.tableCard__icon} icon="download" />
          <span>Generar historial</span>
        </Button>
      </div>
    );
  };

  const middleHeaderComponent = () => {
    return (
      <div>
        <Button
          onClick={() =>
            requestGroups(
              state?.data?.groupsPageInfo?.page,
              true,
              state?.tableFilters?.orderBy,
              state?.tableFilters?.searchBy,
            )
          }
          color="primary"
          variant="outline"
        >
          <Icon icon="repeat" />
          <span>Actualizar vista</span>
        </Button>
      </div>
    );
  };

  const leftInfo = () => {
    return (
      <div className={css.tableCard_info_left}>
        <Checkbox
          className={css.tableCard_info_left_checkbox}
          labelRight={`${keys(state?.editions?.groupsToEditById)?.length ?? 0} Filas seleccionadas`}
          onChange={onEditionsCheckboxClick}
        />
        <Button
          variant="ghost"
          disabled={(keys(state?.editions?.groupsToEditById)?.length ?? 0) === 0 || !canEdit}
          className={css.transparentButton}
          onClick={() => {
            dispatch({
              type: EditionTypes.EditGroupsToEditVisibilities,
              payload: state?.data?.groupsById,
            });
          }}
        >
          <Icon
            className={cx(
              css.tableCard__icon,
              (keys(state?.editions?.groupsToEditById)?.length ?? 0) === 0
                ? css.tableCard__icon_disabled
                : "",
            )}
            icon="open-eye"
          />
          Cambiar visibilidad
        </Button>
        <MultiCapacityInput
          disabled={(keys(state?.editions?.groupsToEditById)?.length ?? 0) === 0 || !canEdit}
          getGroupIdsToExclude={groupIdsToExludeFromCapacityEditions}
        />
      </div>
    );
  };

  const rightInfo = () => {
    return <DonutLegend className={css.tableCard_info__donut} data={donut({} as AdaptedGroup)} />;
  };

  return (
    <section className={cx(css.table)}>
      <Modal
        typeState="confirm"
        title="Cambios no guardados"
        show={displayModal}
        textButtonPrincipal={"Seguir editando"}
        textButtonSecondary={"Cerrar sin guardar"}
        onClickPrincipal={onModalClick?.primary}
        onClickSecondary={onModalClick?.secondary}
        onClose={onModalClick?.primary}
      >
        Si desea guardar los cambios, presione el botón &quot;Seguir editando&quot;.
      </Modal>

      <Modal
        typeState="confirm"
        title="Generar historial"
        show={displayReportModal}
        textButtonPrincipal={"Descargar"}
        textButtonSecondary={"Cancelar"}
        onClickPrincipal={() => {
          const reportLink = buildReport("changeHistory", {
            filters: state?.filters,
            dates: { startDate, endDate },
            context: { origin, scenario },
          });
          setDisplayReportModal(false);
          mouseflow.actionTag(
            "action_groups_manager_change_history_report",
            outerState?.base?.isMouseflowEnabled,
          );
          window.open(reportLink, "_blank");
          setDownloadMode(DownloadMode.All);
        }}
        className={css.reportModal}
        onClickSecondary={() => {
          setDownloadMode(DownloadMode.All);
          setDisplayReportModal(false);
        }}
        onClose={() => {
          setDownloadMode(DownloadMode.All);
          setDisplayReportModal(false);
        }}
      >
        <RadioButton
          labelRight="Descargar todo"
          onChange={() => setDownloadMode(DownloadMode.All)}
          checked={downloadMode === DownloadMode.All}
        />
        <RadioButton
          labelRight="Descargar por fecha"
          onChange={() => setDownloadMode(DownloadMode.ByDate)}
          checked={downloadMode === DownloadMode.ByDate}
        />
        <div className={css.dateSelector__container}>
          <Calendar
            placeholder="yyyy-mm-dd"
            label="Inicio"
            disabled={downloadMode == DownloadMode.All}
            className={css.dateSelector__item}
            selectedDate={startDate ? startDate : null}
            onChange={setStartDate}
          />
          <Calendar
            placeholder="yyyy-mm-dd"
            label="Fin"
            disabled={downloadMode == DownloadMode.All}
            className={css.dateSelector__item}
            selectedDate={endDate ? endDate : null}
            minDate={startDate ? startDate : undefined}
            onChange={setEndDate}
          />
        </div>
      </Modal>

      <DataGrid
        className={css.dataGrid}
        nonSortableColumns={new Set(["Min - Max", "Cap.", "Utilización", "Subperiodo"])}
        columns={columns}
        columnsToHide={state?.tableFilters?.columnsToHide}
        batch={state?.data?.groups ?? []}
        onHeaderClick={onHeaderColumnClick}
        sortInfo={{
          header: state?.tableFilters?.orderBy?.header ?? "",
          direction:
            (state?.tableFilters?.orderBy?.direction?.toLowerCase() as SortInfo["direction"]) ??
            "desc",
        }}
        pagination={pagination}
        info={{
          className: css.tableCard_info,
          left: leftInfo(),
          right: rightInfo(),
        }}
        leftAction={leftAction}
        rightActions={rightActions}
        header={{
          className: css.header,
          left: leftHeaderComponent(),
          middle: middleHeaderComponent(),
          right: (
            <div className={css.dataGrid_rightActions}>
              {showPackageCalendarLink && (
                <Anchor
                  className={css.actionLink}
                  iconRight="external-link"
                  href={`/scheduler/editor/package/${contextUrl}/${state?.filters?.selectedPackage}`}
                >
                  Ir a calendario de Paquete
                </Anchor>
              )}

              <TableFilter
                className={css.actionFilters}
                columnsToSearchBy={columnsToSearchBy}
                nonSortableColumns={new Set(["Min - Max", "Cap.", "Utilización"])}
                setColumnsToSearchBy={setColumnsToSearchBy}
                selectableColumns={map(prop("header"), columns) as GroupsManagerTableColumn[]}
                headerToOrderByObjCallback={groupsTableHeaderToOrderByObj}
                hideColumnsToHideConfig={!outerState.base.isGroupManagerFilterEnabled}
                request={(
                  newOrderBy: TableFiltersReducerType["orderBy"],
                  newSearchBy: TableFiltersReducerType["searchBy"],
                ) => {
                  requestGroups(pageInfo?.page, false, newOrderBy, newSearchBy);
                }}
              />
            </div>
          ),
        }}
      />
    </section>
  );
};

export default GroupsManagerTable;
