import React from "react";
import { Option } from "react-select/lib/filters";
import AdvanceSelector from "../advance-selector";
import useGetSubjectOptions from "@modules/shared/selectors/hooks/useGetSubjectOptions";

interface AdvancedSubjectSelectorProps {
  scenario: string;
  campusId?: string;
  departmentId?: string;
  modalityId?: string;
  shiftId?: string;
  curriculumId?: string;
  levelId?: string;
  programId?: string;
  onChange?: (option: Option) => void;
}

const AdvancedSubjectSelector = ({ onChange, ...filters }: AdvancedSubjectSelectorProps) => {
  const { loadOptions } = useGetSubjectOptions({
    ...filters,
  });

  return (
    <AdvanceSelector
      isPaginated
      dimension="Asignatura"
      selectPlaceholder="Selecciona una asignatura"
      loadOptions={loadOptions}
      onChange={onChange}
    />
  );
};

export default AdvancedSubjectSelector;
