import React, { useState } from "react";
import cx from "classnames";
import Layout from "../../../../common/layout/Layout";
import Header from "../../../../common/components/Header/Header";
import AddLinkSection from "./AddLinkSection/AddLinkSection";
import Selectors from "./Selectors/Selectors";
import css from "./createSection.module.scss";

const CreateSection: React.FC<any> = () => {
  const [isInStep2, setIsInStep2] = useState(false);
  const [selectorInfo, setSelectorInfo] = useState(null);

  const handleReturnStep2 = data => {
    setSelectorInfo(data);
    setIsInStep2(true);
  };

  const handleReturnStep1 = () => {
    setSelectorInfo({});
    setIsInStep2(false);
  };

  const courseId = selectorInfo && selectorInfo.idCourse ? selectorInfo.idCourse : null;
  const termId = selectorInfo && selectorInfo.idTerm ? selectorInfo.idTerm : null;
  const programId = selectorInfo && selectorInfo.idProgram ? selectorInfo.idProgram : null;
  const groupCode = selectorInfo && selectorInfo.groupCode ? selectorInfo.groupCode : null;
  const groupVacancies =
    selectorInfo && selectorInfo.groupVacancies ? selectorInfo.groupVacancies : null;

  return (
    <Layout contextSearch>
      <article className={css.cntCreate}>
        <Header title="Crear liga" />
        <section className={cx(css.content, "container-row")}>
          {!isInStep2 && <Selectors onReturn={handleReturnStep2} />}
          {isInStep2 && courseId && termId && (
            <AddLinkSection
              onReturn={handleReturnStep1}
              courseId={parseInt(courseId)}
              termId={parseInt(termId)}
              programId={programId}
              groupCode={groupCode}
              groupVacancies={groupVacancies}
            />
          )}
        </section>
      </article>
    </Layout>
  );
};

export default CreateSection;
