import React, { createContext, useReducer, Dispatch } from "react";
import { DataActions, dataReducer } from "./data.reducer";
import { FiltersActions, filtersReducer } from "./filters.reducer";
import { FiltersSelectorsActions, filtersSelectorsReducer } from "./filtersSelectors.reducer";
import { EditionsActions, editionsReducer } from "./editions.reducer";
import { TableFiltersActions, tableFiltersReducer } from "./tableFilters.reducer";
import {
  DataReducerType,
  FiltersReducerType,
  EditionsReducerType,
  NavigationReducerType,
  TableFiltersReducerType,
  FiltersSelectorsType,
} from "./types";
import { NavigationActions, navigationReducer } from "./navigation.reducer";
import {
  AdaptedGroup,
  ErrorsByGroupId,
  FiltersType,
  GroupsChangesTableColumn,
  GroupsManagerTableColumn,
} from "../models";

export type InitialStateType = {
  filtersSelectors: FiltersSelectorsType;
  filters: FiltersReducerType;
  data: DataReducerType;
  editions: EditionsReducerType;
  navigation: NavigationReducerType;
  tableFilters: TableFiltersReducerType;
};

export const initialState: InitialStateType = {
  filtersSelectors: {
    visibleFilter: FiltersType.KEY,
    selectedPackage: null,
    usedFilterToSearch: null,
  },
  filters: {
    campus: null,
    courseTypesById: {},
    department: null,
    school: null,
    selectedCrn: null,
    selectedPackage: null,
    subject: null,
    state: {
      active: false,
      inactive: false,
    },
    visibility: {
      visible: false,
      invisible: false,
    },
    availability: {
      available: false,
      unavailable: false,
    },
    enrollments: {
      with: false,
      without: false,
    },
    owned: false,
  },
  data: {
    groups: null,
    groupsById: {},
    groupsPageInfo: {
      hasPreviousPage: false,
      hasNextPage: false,
      page: 0,
      size: 0,
      total: 0,
    },
    group: null,
  },
  editions: {
    groupsToEditById: {} as Record<AdaptedGroup["id"], AdaptedGroup>,
    byGroupId: {},
    errorsByGroupId: {} as ErrorsByGroupId,
    pendingCapacitiesByGroupId: {} as Record<AdaptedGroup["id"], number>,
  },
  navigation: {
    forceFetching: false,
    locationStack: [],
  },
  tableFilters: {
    columnsToHide: new Set<GroupsManagerTableColumn | GroupsChangesTableColumn>(),
    orderBy: {
      header: null,
      field: null,
      direction: null,
    },
    searchBy: {
      text: "",
      fields: [],
    },
  },
};

const Context = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<
    | FiltersActions
    | DataActions
    | EditionsActions
    | NavigationActions
    | TableFiltersActions
    | FiltersSelectorsActions
  >;
}>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (
  { data, filters, editions, navigation, tableFilters, filtersSelectors }: InitialStateType,
  action: any,
) => {
  return {
    filtersSelectors: filtersSelectorsReducer(filtersSelectors, action),
    filters: filtersReducer(filters, action),
    data: dataReducer(data, action),
    editions: editionsReducer(editions, action),
    navigation: navigationReducer(navigation, action),
    tableFilters: tableFiltersReducer(tableFilters, action),
  };
};

const ContextProvider: React.FC<{ children?: React.ReactNode | undefined }> = ({ children }) => {
  const [state, dispatch] = useReducer<any>(reducer, initialState);
  return (
    <Context.Provider value={{ state: state as InitialStateType, dispatch }}>
      {children}
    </Context.Provider>
  );
};

export { ContextProvider, Context };
