import React from "react";
import { Icon } from "@foris/avocado-icons";
import Modal from "react-modal";
import cx from "classnames";
import css from "./modal.module.scss";
import { Text } from "@foris/avocado-suite";

interface IPropsModal {
  title?: string;
  show: boolean;
  onClose?: () => void;
  headerColor?: string;
  header?: any;
  className?: string;
  children?: React.ReactNode | undefined;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    border: "none",
    borderRadius: 8,
    overlfow: "auto",
    background: "transparent",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 10,
  },
};

Modal.setAppElement("#root");

const ExportModal: React.FC<IPropsModal> = (props: IPropsModal) => {
  const { children, title, show, onClose, headerColor, header, className } = props;

  return (
    <Modal
      isOpen={show}
      onRequestClose={(): void => null}
      contentLabel={title}
      style={customStyles}
      closeTimeoutMS={300}
    >
      <section className={cx(css.modalContent, className && className)}>
        <header className={css.modalContent_header} style={{ background: headerColor }}>
          {onClose && (
            <button
              className={css.closeIcon}
              onClick={() => {
                onClose();
              }}
            >
              <Icon name="close" size="sm" color="neutral-00" />
              <Text className={css.closeIcon_text} type="sm" darkMode>
                Cerrar
              </Text>
            </button>
          )}
          <div className={css.headerTitle}>{header ? header() : title}</div>
        </header>

        <div className={css.modalContent_detail}>{children}</div>
      </section>
    </Modal>
  );
};

export default ExportModal;
