import { useCallback, useState } from "react";
import { useApolloClient } from "react-apollo";
import { DataQuery, Level, PageInfo } from "@models/ISchema";
import { levelQuery } from "../graphql/level.queries";

interface UseGetLevelOptionsParams {
  scenario: string;
  campusId?: string;
}

const useGetLevelOptions = ({ scenario }: UseGetLevelOptionsParams) => {
  const client = useApolloClient();
  const [levelPage] = useState(1);

  const requestItems = useCallback(async () => {
    try {
      const variables = {
        query: levelQuery,
        variables: {
          scenarioId: scenario,
        },
      };

      const { data } = await client.query(variables);
      const dataQuery: DataQuery = data.data;
      const pageInfo: PageInfo = {
        hasNextPage: false,
        hasPreviousPage: false,
        page: levelPage,
        size: dataQuery?.levels?.length ?? 0,
        total: dataQuery?.levels?.length ?? 0,
      };

      const options =
        dataQuery?.levels?.map((level: Level) => ({ label: level.level, value: level.id })) ?? [];

      return { pageInfo, options };
    } catch (error) {
      console.error(error);
      return {};
    }
  }, [client, levelPage]);

  const loadOptions = async () => {
    const { pageInfo, options } = await requestItems();

    return {
      options: options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page: levelPage },
    };
  };

  return {
    loadOptions,
  };
};

export default useGetLevelOptions;
