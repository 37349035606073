import { colorsLeague } from "../../utils/dataColors";
import { IColor } from "../sections/GridSections/IGridSections";
import { ILink, ILeague, ISection } from "./contextVacancies/ContextVacancies";
import {
  Bundle,
  Link,
  Group,
  Section,
  ValidationErrorReason,
  InvalidLinkChanges,
  InvalidSectionChanges,
  InvalidSessionChanges,
  InvalidGroupChanges,
} from "../../models/ISchema";

type Error = "warning" | "error";

export interface IObjValidationError {
  type: Error;
  text: string;
  leagueCode?: string;
}
export const assigmentColor = (idColor: number) => {
  const color = colorsLeague.find((item: IColor) => item.id === idColor);
  return color;
};

export const linksBundle = (leagues: Array<ILeague>) => {
  const newLeagues: Array<ILeague> = [];
  leagues.forEach(league => {
    const newLeague: ILeague = league;
    const newSections: Array<ISection> = [];
    league.sections.forEach(section => {
      const newSection: ISection = section;
      const newBundle: Array<ILink> = [];
      section.link.forEach((bundle: any) => {
        const filterLeagues = leagues.find(item => item.code === bundle.code);
        const objLinkBundle = {
          code: bundle.code,
          codeName: filterLeagues.codeName,
          color: filterLeagues.color,
          idColor: filterLeagues.idColor,
          vacancies: bundle.vacancies,
        };
        newBundle.push(objLinkBundle);
      });
      newSection.link = newBundle;
      newSections.push(section);
    });
    newLeague.sections = newSections;
    newLeagues.push(newLeague);
  });
  return newLeagues;
};

export const dataLeagues = (base: Bundle) => {
  const leagues = [];
  base.links.forEach((link: Link, indexLeague: number) => {
    const assigmentLeague = assigmentColor(indexLeague);
    const groups = [];
    const sections = [];
    const linkCampus = link?.course?.curriculum?.program?.campus?.code ?? "";

    link.groups.forEach((group: Group) => {
      const objGroup = {
        id: group.id || "-",
        vacancies: group?.capacity || 0,
        isActive: Boolean(group?.isActive),
        label: group.label || "-",
        enrollment: group?.enrollmentStats?.usedCapacity || 0,
        waitList: group?.enrollmentStats?.waitingListsCount || 0,
        curriculum: group.curriculum ? group.curriculum.code : "-",
        course: group.course
          ? {
              code: group.course.code,
              canEdit: group.course.canEdit,
            }
          : {},
        campus: linkCampus,
        program: group.course ? group.course.curriculum.program.code : "-",
        clientCode: group?.clientCode,
        openGroup: group?.visibleForEnrollment,
        groupSections: group?.groupSections || [],
      };
      groups.push(objGroup);
    });

    link.sections.forEach((section: Section) => {
      const sectionBundle = [];
      section.linksByBundles.forEach((bundle: Link) => {
        if (bundle.code !== link.code) {
          sectionBundle.push(bundle);
        }
      });

      const objSection = {
        typeComponent: section.component.code,
        sectionId: section.id,
        componentId: section.component.id,
        vacancies: section.vacancies,
        link: sectionBundle,
      };
      sections.push(objSection);
    });

    const objLeague = {
      code: link.code,
      codeName: assigmentLeague.codeName,
      color: assigmentLeague.color,
      course: link?.course,
      groups: groups,
      id: base.id,
      idColor: indexLeague,
      label: base.label,
      clientCode: link?.label,
      sections: sections,
      linkId: link.id,
      instructors: link?.instructorResponsability,
      totalBlocks: link?.totalBlocks,
      blocksWithoutInstructorAssigment: link?.blocksWithoutInstructorAssigment,
    };

    leagues.push(objLeague);
  });

  const bundleLinks = linksBundle(leagues);
  return bundleLinks;
};

export const getValidationErrorReason = (
  code: ValidationErrorReason,
  section?: string,
  objValue?: any,
) => {
  const sectionMs = section && section !== "" ? `Sección ${section}.` : "";
  const standardMs = objValue && objValue.standard ? `(estándar: ${objValue.standard}).` : "";
  const enrrollmentMs =
    objValue && objValue.enrrollment ? `(inscritos: ${objValue.enrrollment})` : "";
  const capacityMs = objValue && objValue.capacity ? `(Capacidad sala: ${objValue.capacity}).` : "";
  const sessionMs = objValue && objValue.sessionId ? `Sesión ${objValue.sessionId}.` : "";
  const groupMs = objValue && objValue.groupLabel ? `Grupo ${objValue.groupLabel}.` : "";
  const leagueCode = objValue && objValue.leagueCode ? objValue.leagueCode : null;

  let objError: IObjValidationError = {
    type: null,
    text: null,
    leagueCode: leagueCode,
  };

  switch (code) {
    //SECTION -> warning
    case "EXEEDS_MAX":
      objError = {
        ...objError,
        type: "warning",
        text: `Ajuste de vacantes excede estándar académico definido. ${sectionMs} ${standardMs}`,
      };
      break;
    //GROUP -> error
    case "ENROLLMENTS_NOT_COVERED":
      objError = {
        ...objError,
        type: "error",
        text: `Las vacantes definidas son menores a los estudiantes actualmente inscritos. ${sectionMs} ${groupMs} ${enrrollmentMs} `,
      };
      break;
    case "GROUP_VACANCIES_EXTERNAL_VALIDATION":
      objError = {
        ...objError,
        type: "error",
        text: `No se permite hacer cambios de horario debido a que el\
              grupo tiene alumnos inscritos, se encuentra con cupo > 0 en el \
              sistema de inscripciones, o bien, se está intentando cambiar a un salón de menor capacidad.`,
      };
      break;
    //SECTION -> warning
    case "LOWER_THAN_MIN":
      objError = {
        ...objError,
        type: "warning",
        text: `Ajuste de vacantes es menor a mínimo definido. ${sectionMs}`,
      };
      break;
    //SESSION -> warning
    case "CLASSROOM_CAPACITY_EXCEEDED":
      objError = {
        ...objError,
        type: "warning",
        text: `Ajuste de vacantes excede la capacidad de la sala asignada. ${sectionMs} ${sessionMs} ${capacityMs}`,
      };
      break;
  }
  return objError;
};

export const linkValidation = (data: InvalidLinkChanges) => {
  const errorLinks = [];
  // sectionChanges
  data.sectionChanges &&
    data.sectionChanges.forEach((section: InvalidSectionChanges) => {
      section.validationErrors &&
        section.validationErrors.forEach(errorSection => {
          if (errorSection && errorSection.__typename === "InvalidComponentSize") {
            const standardObj = {
              standard:
                errorSection.component && errorSection.component.academicStandard
                  ? errorSection.component.academicStandard
                  : section.section.courseComponent &&
                    section.section.courseComponent.academicStandard
                  ? section.section.courseComponent.academicStandard
                  : "",
              leagueCode: data.link && data.link.code ? data.link.code : null,
            };
            const errorMs = getValidationErrorReason(
              errorSection.reason,
              section.section.id,
              standardObj,
            );
            errorMs && errorLinks.push(errorMs);
          }
        });
    });
  // sessionChanges
  data.sessionChanges &&
    data.sessionChanges.forEach((session: InvalidSessionChanges) => {
      session.validationErrors &&
        session.validationErrors.forEach(errorSession => {
          if (errorSession) {
            const capacityObj = {
              capacity:
                errorSession.classroom && errorSession.classroom.capacity
                  ? errorSession.classroom.capacity
                  : "",
              sessionId: session.session.id,
              leagueCode: data.link && data.link.code ? data.link.code : null,
            };
            const errorMs = getValidationErrorReason(
              errorSession.reason,
              session.session.section.id,
              capacityObj,
            );
            errorMs && errorLinks.push(errorMs);
          }
        });
    });
  // groupChanges
  data.groupChanges &&
    data.groupChanges.forEach((group: InvalidGroupChanges) => {
      group.validationErrors.forEach(errorGroup => {
        if (errorGroup.__typename === "InvalidFieldValidation") {
          const errorMs = getValidationErrorReason(errorGroup.reason);
          errorMs && errorLinks.push(errorMs);
        } else if (errorGroup.__typename === "ExternalGroupValidation") {
          const groupObj = {
            groupLabel: group.group && group.group.code ? group.group.code : "",
            leagueCode: data.link && data.link.code ? data.link.code : null,
          };
          const errorMs = getValidationErrorReason(errorGroup.reason, "", groupObj);
          errorMs && errorLinks.push(errorMs);
        } else if (errorGroup.__typename === "InvalidGroupEnrollment") {
          const groupObj = {
            groupLabel: group.group && group.group.code ? group.group.code : "",
            leagueCode: data.link && data.link.code ? data.link.code : null,
          };
          const errorMs = getValidationErrorReason(errorGroup.reason, "", groupObj);
          errorMs && errorLinks.push(errorMs);
        }
      });
    });

  return errorLinks;
};

export const groupValidation = (data: any, link: Link) => {
  const errorLinks = [];

  data &&
    data.forEach(errorGroup => {
      if (errorGroup.__typename === "ExternalGroupValidation") {
        const groupObj = {
          groupLabel: "",
          leagueCode: link && link.code ? link.code : null,
        };

        const errorMs = getValidationErrorReason(errorGroup.reason, "", groupObj);
        errorMs && errorLinks.push(errorMs);
      }
    });

  return errorLinks;
};

export const getTotalBlocksPercent = (instructorTotalBlocks = 0, generalTotalBlocks = 0) =>
  [instructorTotalBlocks, generalTotalBlocks].includes(0)
    ? 0
    : (instructorTotalBlocks * 100) / generalTotalBlocks;

export const getGroupComponentErrorMessages = (
  component: string,
  code: string,
  existingLabels: string[] = [],
) => {
  const componentErrors = [];

  if (isNaN(+code)) {
    componentErrors.push("Ingresa números enteros de 1 a 999.");
  }

  if (code.startsWith("0")) {
    componentErrors.push("El número no debe ser antecedido por uno o más ceros (01, 001).");
  }

  if (existingLabels.includes(code)) {
    componentErrors.push(
      "No repetir el número de código de sección en una misma asignatura, periodo académico y sede.",
    );
  }

  if (!componentErrors?.length) return null;

  return {
    component,
    errors: componentErrors,
  };
};
