import React from "react";
import { Option } from "react-select/lib/filters";
import AdvanceSelector from "../advance-selector";
import useGetModalityOptions from "@modules/shared/selectors/hooks/useGetModalityOptions";

interface AdvancedModalitySelectorProps {
  scenario: string;
  onChange?: (option: Option) => void;
}

const AdvancedModalitySelector = ({ scenario, onChange }: AdvancedModalitySelectorProps) => {
  const { loadOptions } = useGetModalityOptions({
    scenario,
  });

  return (
    <AdvanceSelector
      isPaginated
      dimension="Modalidad"
      selectPlaceholder="Selecciona una modalidad"
      loadOptions={loadOptions}
      onChange={onChange}
    />
  );
};

export default AdvancedModalitySelector;
