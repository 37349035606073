import React, { useMemo, useState } from "react";
import { useMutation } from "react-apollo";
import { useParams, useHistory } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { EmptyResult } from "../../../shared/modals";
import Button from "../../../../common/components/Button/Button";
import { Loading } from "@foris/avocado-ui";
import { populationSearchMutation } from "../graphql/advanceSearch.queries";
import AdvancedSelectorsWrapper from "@modules/advance-search/components/advanced-selectors-wrapper";
import css from "../../../advance-search/advance-search.module.scss";

const AdvanceSearchPopulation: React.FC<any> = () => {
  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const [advanceSearchMutation] = useMutation(populationSearchMutation, {});
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectorsState, setSelectorsState] = useState({
    campus: null,
    department: null,
    modality: null,
    shift: null,
    program: null,
    curriculum: null,
    level: null,
  });

  const hasSomeFilter = useMemo(() => {
    return Object.values(selectorsState).some(value => !!value);
  }, [selectorsState]);

  const createObjectMutation = () => {
    const filterArray = [];

    selectorsState.level && filterArray.push({ level: { eq: selectorsState.level } });
    selectorsState.curriculum &&
      filterArray.push({ curriculumId: { eq: selectorsState.curriculum } });
    selectorsState.campus && filterArray.push({ campusId: { eq: selectorsState.campus } });
    selectorsState.modality && filterArray.push({ modalityId: { eq: selectorsState.modality } });
    selectorsState.department &&
      filterArray.push({ departmentId: { eq: selectorsState.department } });
    selectorsState.shift && filterArray.push({ shiftId: { eq: selectorsState.shift } });
    selectorsState.program && filterArray.push({ programId: { eq: selectorsState.program } });

    const mutationFilter = {
      originId: origin,
      scenarioId: scenario,
      filter: {
        fields: {
          and: [
            // Set default filter 'cause now we bring only the ones with assignment
            { hasEvents: true },
            ...filterArray,
          ],
        },
      },
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return mutationFilter;
  };
  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createPopulationFilter;
      if (dataItems.populations.items.length > 0) {
        const idSearch = dataItems.id;
        const contextUrl = `${workspace}/${scenario}/${origin}`;
        history.push(`/scheduler/editor/searchBy/population/${contextUrl}/?advance=${idSearch}`);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <EmptyResult show={isEmpty} onClose={() => setIsEmpty(false)} />

      <AdvancedSelectorsWrapper
        className={css.hasBottomSeparation}
        scenario={scenario}
        selectors={["campus", "department", "modality", "shift", "program", "curriculum", "level"]}
        onChangeState={setSelectorsState}
      />

      {loading ? (
        <div className="loading_wrapper">
          <Loading />
        </div>
      ) : (
        <Button
          className={css.actionButton}
          onClick={() => callUpdateHook()}
          disabled={!hasSomeFilter}
        >
          Buscar
        </Button>
      )}
    </>
  );
};

export default AdvanceSearchPopulation;
