import { Link } from "@models/ISchema";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

interface UseLinksStateProps {
  packageId: string;
}

const useLinksState = ({ packageId }: UseLinksStateProps) => {
  const [linksToDelete, setLinksToDelete] = useState<{ [id: string]: Link }>({});
  const [linksToAdd, setLinksToAdd] = useState<{ [id: string]: Link }>({});
  const history = useHistory();
  const {
    workspace,
    scenario,
    origin,
    id,
  }: { workspace: string; scenario: string; origin: string; id: string } = useParams();

  const assignmentsWithMultipleGroups = useMemo(() => {
    return Object.values(linksToAdd)?.filter(link => link?.groups?.length > 1)?.length;
  }, [linksToAdd]);

  const assignmentIds = useMemo(() => {
    return Object.values(linksToAdd)?.map(link => ({
      linkId: link.id,
      packageId,
    }));
  }, [linksToAdd]);

  const deletionsIds = useMemo(() => {
    return Object.values(linksToDelete)?.map(link => ({
      linkId: link.id,
      packageId,
    }));
  }, [linksToDelete]);

  const handleResetChanges = () => {
    const hasLinksToDelete = Object.keys(linksToDelete).length > 0;
    const hasLinksToAdd = Object.keys(linksToAdd).length > 0;

    if (!hasLinksToDelete && !hasLinksToAdd) {
      history.push(`/scheduler/editor/package/${workspace}/${scenario}/${origin}/${id}`);
    }

    setLinksToAdd({});
    setLinksToDelete({});
  };

  return {
    linksToAdd,
    linksToDelete,
    assignmentsWithMultipleGroups,
    assignmentIds,
    deletionsIds,
    setLinksToAdd,
    setLinksToDelete,
    handleResetChanges,
  };
};

export default useLinksState;
