import { useApolloClient } from "react-apollo";
import { SHARED_PACKS_QUERY } from "../graphql/sharedPacks.queries";
import { useState } from "react";
import { DataQuery, SharedPackComponents } from "@models/ISchema";

interface UseGetSharedPackComponentsProps {
  scenarioId: string;
  originId: string;
}

interface UseGetSharedPackComponents {
  sharedPackComponents: SharedPackComponents[];
  isLoading: boolean;
  getSharedPacks: (linkId: string, bundleId: string, courseId: string) => void;
}

export const useGetSharedPackComponents = ({
  scenarioId,
  originId,
}: UseGetSharedPackComponentsProps): UseGetSharedPackComponents => {
  const client = useApolloClient();
  const [sharedPackComponents, setSharedPackComponents] = useState<SharedPackComponents[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getSharedPacks = async (linkId = "", bundleId = "", courseId = "") => {
    try {
      setIsLoading(true);

      const { data } = await client.query({
        query: SHARED_PACKS_QUERY,
        variables: {
          scenarioId,
          originId,
          dryRun: false,
          skipValidations: false,
          input: {
            linkId,
            bundleId,
            courseId,
          },
        },
        fetchPolicy: "no-cache",
      });

      const dataQuery = data?.data as DataQuery;
      const components = dataQuery?.sharedPackComponents?.sort((a, b) => a?.order - b?.order);

      setSharedPackComponents(components);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { sharedPackComponents, isLoading, getSharedPacks };
};
