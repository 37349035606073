import React from "react";
import { Package } from "@models/ISchema";
import { Icon, Pill, Tooltip } from "@foris/avocado-ui";
import css from "./../GridSections/package.module.scss";
import headerCss from "../../../../common/components/Header/header.module.scss";

interface PackageHeaderSubtitleProps {
  packageData?: Package;
}

const PackageHeaderSubtitle = ({ packageData }: PackageHeaderSubtitleProps) => {
  return (
    <div className={headerCss.headerSubtitle}>
      {packageData?.isPublished ? (
        <div className={css.headerPills}>
          <Tooltip
            label="Paquete activo y actualmente en proceso de inscripción"
            placement="topLeft"
          >
            <Pill className={css.headerPills_pill}>
              <Icon icon="check" size={12} /> Paquete publicado
            </Pill>
          </Tooltip>

          <Pill className={css.headerPills_pill}>
            <Icon icon={packageData?.isVisible ? "open-eye" : "closed-eye"} size={12} />
            {packageData?.isVisible ? "Visible" : "No visible"}
          </Pill>
        </div>
      ) : null}

      <div className={css.headerPills}>
        <p className={headerCss.headerSubtitle_label}>
          Período:{" "}
          <span className={headerCss.isRegular}>{packageData?.population?.term?.code}</span>
        </p>
        <p className={headerCss.headerSubtitle_label}>
          Total de créditos:{" "}
          <span className={headerCss.isRegular}>{packageData?.packageTotalCredits}</span>
        </p>
      </div>
    </div>
  );
};

export default PackageHeaderSubtitle;
