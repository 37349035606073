import React from "react";
import { Option } from "react-select/lib/filters";
import AdvanceSelector from "../advance-selector";
import useGetLevelOptions from "@modules/shared/selectors/hooks/useGetLevelOptions";

interface AdvancedLevelSelectorProps {
  scenario: string;
  onChange?: (option: Option) => void;
}

const AdvancedLevelSelector = ({ scenario, onChange }: AdvancedLevelSelectorProps) => {
  const { loadOptions } = useGetLevelOptions({
    scenario,
  });

  return (
    <AdvanceSelector
      isPaginated
      dimension="Nivel"
      selectPlaceholder="Selecciona un nivel"
      loadOptions={loadOptions}
      onChange={onChange}
    />
  );
};

export default AdvancedLevelSelector;
