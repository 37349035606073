import { useCallback, useState } from "react";
import { useApolloClient } from "react-apollo";
import { campusQuery } from "../graphql/campus.queries";
import { Campus, DataQuery } from "@models/ISchema";

interface UseGetCampusOptionsParams {
  scenario: string;
}

const useGetCampusOptions = ({ scenario }: UseGetCampusOptionsParams) => {
  const client = useApolloClient();
  const [termToSearch, setTermToSearch] = useState("");
  const [campusPage, setCampusPage] = useState(0);

  const requestItems = useCallback(
    async (searchTerm = "", page = 1) => {
      const optionsPerPage = 10;

      try {
        const variables = {
          query: campusQuery,
          variables: {
            scenarioId: scenario,
            filter: {
              pagination: {
                page,
                size: optionsPerPage,
                searchTerm,
              },
            },
          },
        };

        const { data } = await client.query(variables);
        const dataQuery: DataQuery = data.data;
        const pageInfo = dataQuery?.campuses?.pageInfo;

        const options = (dataQuery?.campuses?.items ?? []).map((campus: Campus) => {
          return { label: `${campus.code} - ${campus.name}`, value: campus.id };
        });

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [client, campusPage],
  );

  const loadOptions = async (newSearchTerm: string) => {
    const newSearchPage = termToSearch === newSearchTerm ? campusPage + 1 : 1;

    setTermToSearch(newSearchTerm);
    setCampusPage(newSearchPage);

    const { pageInfo, options } = await requestItems(newSearchTerm, newSearchPage);

    return {
      options: options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page: campusPage },
    };
  };

  return {
    loadOptions,
  };
};

export default useGetCampusOptions;
