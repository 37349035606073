import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { IErrorWarning } from "../../../../../models/Errors/ErrorWarning";
import Button from "../../../../../common/components/Button/Button";
import ComponentItem, { IReturnComponent } from "./ComponentItem";
import { COMPONENT_LIST } from "../graphql/components.queries";
import Validations from "../../../../../common/components/Validations/Validations";
import { DataQuery, CourseComponent } from "../../../../../models/ISchema";
import { IParams } from "../../../../../models/IParams";
import useCreateLink from "@dimensions/section/hooks/useCreateLink";
import css from "./addLinkSection.module.scss";
import { Loading } from "@foris/avocado-ui";

interface IAddLinkSection {
  courseId: number;
  termId: number;
  groupCode?: string;
  groupVacancies?: number | string;
  programId?: string | number;
  onReturn: () => void;
}

const CreateLink: React.FC<IAddLinkSection> = ({
  courseId,
  termId,
  groupCode,
  groupVacancies,
  programId,
  onReturn,
}: IAddLinkSection) => {
  const client = useApolloClient();
  const { scenario, origin, workspace }: IParams = useParams();
  const [componentList, setComponentList] = useState<CourseComponent[]>(null);
  const [createSections, setCreateSections] = useState<IReturnComponent[]>(null);

  const {
    handleSimulateMutation,
    isLoading,
    groupValidationArray,
    groupValidationStandardArray,
    linkCreationError,
  } = useCreateLink({ scenario, origin, workspace });

  /**
   * Array Validations Error || Warning
   */
  const validationErrorWarning: IErrorWarning[] = linkCreationError ? [...linkCreationError] : [];

  const errorArray = useMemo(() => {
    const warnings = [];
    const errors = [];

    groupValidationArray.forEach(validation => {
      if (validation.type?.toUpperCase() === "WARNING") {
        warnings.push(validation);
      } else {
        errors.push(validation);
      }
    });

    return {
      warnings,
      errors,
    };
  }, [groupValidationArray]);

  const errorStandardArray = useMemo(() => {
    const warnings = [];
    const errors = [];

    groupValidationStandardArray.forEach(validation => {
      if (validation.type?.toUpperCase() === "WARNING") {
        warnings.push(validation);
      } else {
        errors.push(validation);
      }
    });

    return {
      warnings,
      errors,
    };
  }, [groupValidationStandardArray]);

  const hasErrorsOrWarnings =
    !!errorArray?.errors?.length ||
    !!errorStandardArray?.errors?.length ||
    !!errorArray?.warnings?.length ||
    !!errorStandardArray?.warnings?.length;
  const isOnlyWarningGroupErrors =
    !errorArray?.errors?.length && !errorStandardArray?.errors?.length;

  /**
   * Validations create section (example)
   */
  const validations = () => {
    let returnDisabled = true;
    let countOldSection = 0;
    let countNotSelect = 0;
    let countNewSection = 0;
    const componentCount = componentList ? componentList.length : null;
    let objValidation: IErrorWarning = {
      type: "ERROR",
      message: "Error genérico",
    };

    createSections.forEach(value => {
      if (value.typeSection === "OLD_SECTION") {
        countOldSection++;
        if (!value.section) countNotSelect++;
      }
      if (value.typeSection === "NEW_SECTION") countNewSection++;
    });
    if (componentCount && createSections.length === componentCount) {
      // validate one new_section
      if (countNewSection === 0) {
        objValidation = {
          type: "ERROR",
          message: "Se requiere al menos una sección nueva.",
        };
        validationErrorWarning.push(objValidation);
        return true;
      } else {
        returnDisabled = false;
      }
      // validate old_section and select a section
      if (countOldSection > 0 && countNotSelect > 0) {
        objValidation = {
          type: "ERROR",
          message: "Se requiere seleccionar alguna sección.",
        };
        validationErrorWarning.push(objValidation);
        return true;
      } else {
        returnDisabled = false;
      }
    }
    return returnDisabled || (hasErrorsOrWarnings && !isOnlyWarningGroupErrors);
  };

  const handleCreateSectionByLink = () => {
    if (isLoading) return;

    handleSimulateMutation({
      courseId,
      termId: String(termId),
      createSections,
      groupCode,
      programId: programId as string,
      groupVacancies: groupVacancies as string,
    });
  };

  /**
   * List of components by courseId
   */
  const getComponentList = useCallback(async () => {
    try {
      const variables = {
        scenarioId: scenario,
        filter: {
          fields: {
            courseId: {
              eq: courseId,
            },
          },
        },
      };
      const { data } = await client.query({ query: COMPONENT_LIST, variables });
      const components: DataQuery = data.data;
      setComponentList(components.courseComponents.items);
    } catch (e) {
      console.error(e);
    }
  }, [scenario, client, courseId]);

  useEffect(() => {
    if (componentList === null) getComponentList();
  }, [getComponentList, componentList]);

  useEffect(() => {
    if (!createSections && componentList) {
      const sections = [];
      componentList.forEach(value => {
        const component: IReturnComponent = {
          courseComponent: value,
          typeSection: "NEW_SECTION",
          section: null,
        };
        sections.push(component);
      });
      setCreateSections(sections);
    }
  }, [createSections, componentList]);

  return (
    <>
      {isLoading && <Loading />}

      {componentList && createSections && (
        <section className={cx(css.cntCreateLink, "container-row")}>
          {componentList.length === 0 ? (
            <h2 className={cx(css.cntEmpty)}>
              La asignatura seleccionada no tiene componentes configurados.
            </h2>
          ) : (
            <>
              <h3 className={cx(css.cntCreateLink_title, "col_12")}>Componentes:</h3>
              <ul className={cx(css.listComponent, "container-row")}>
                {componentList &&
                  componentList.map(value => {
                    return (
                      <ComponentItem
                        key={value.id}
                        courseComponent={value}
                        courseId={courseId}
                        onCallback={value => {
                          const sections = [...createSections];
                          const findComponent = sections.findIndex(
                            cmp => cmp.courseComponent.id === value.courseComponent.id,
                          );
                          if (findComponent >= 0) sections.splice(findComponent, 1);
                          sections.push(value);
                          setCreateSections(sections);
                        }}
                      />
                    );
                  })}
              </ul>
              <Validations
                validations={validationErrorWarning}
                onCallback={(valueCheck: boolean) => console.debug(valueCheck)}
                className={css.validationItem}
              />

              {hasErrorsOrWarnings && (
                <>
                  <ul className={cx(css.list_validation, "container-row")}>
                    {errorArray?.warnings?.map((validation: any, iValidation: number) => (
                      <li key={iValidation} className={cx(css.list_validation_item, "col_12")}>
                        {validation.text}
                      </li>
                    ))}
                    {errorStandardArray?.warnings?.map((validation, index) => (
                      <li key={index} className={cx(css.list_validation_item, "col_12")}>
                        {validation.message}
                      </li>
                    ))}
                    {errorArray?.errors?.map((validation: any, iValidation: number) => (
                      <li
                        key={iValidation}
                        className={cx(
                          css.list_validation_item,
                          css.list_validation_item__error,
                          "col_12",
                        )}
                      >
                        {validation.text}
                      </li>
                    ))}
                    {errorStandardArray?.errors?.map((validation, index) => (
                      <li
                        key={index}
                        className={cx(
                          css.list_validation_item,
                          css.list_validation_item__error,
                          "col_12",
                        )}
                      >
                        {validation.message}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </>
          )}
          <footer className={cx(css.footer, "container-row")}>
            <Button className={css.footer_btn} color="transparent" onClick={() => onReturn()}>
              Cancelar
            </Button>
            {componentList.length > 0 && (
              <Button
                className={css.footer_btn}
                disabled={validations()}
                onClick={() => handleCreateSectionByLink()}
              >
                Crear
              </Button>
            )}
          </footer>
        </section>
      )}
    </>
  );
};

export default CreateLink;
